import React, { Component } from 'react';
import Logo from '../images/bmac_logo.svg'

class FooterComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <footer className="footer" style={{ backgroundColor: "transparent", height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "17px" }}>
                    <a href="https://buymeacoffee.com/bg3builder" target="_blank" rel="noopener noreferrer">
                        <img src={Logo} alt="Logo" style={{ width: '100px', height: 'auto', opacity: 0.9 }} />
                    </a>
                </footer>
            </div>
        );
    }
}

export default FooterComponent;

