import elfImage from './elf.png';
import tieflingImage from './tiefling.png';
import drowImage from './drow.png';
import humanImage from './human.png';
import githyankiImage from './githyanki.png';
import dwarfImage from './dwarf.png';
import halfElfImage from './halfelf.png';
import halflingImage from './halfling.png';
import gnomeImage from './gnome.png';
import dragonbornImage from './dragonborn.png';
import halfOrcImage from './halforc.png';
import strength from './strength.png'
import dexterity from './dexterity.png'
import constitution from './constitution.png'
import wisdom from './wisdom.png'
import charisma from './charisma.png'
import intelligence from './intelligence.png'
import barbarian from './barbarian.png'
import bard from './bard.png'
import cleric from './cleric.png'
import druid from './druid.png'
import fighter from './fighter.png'
import monk from './monk.png'
import paladin from './paladin.png'
import ranger from './ranger.png'
import rogue from './rogue.png'
import sorcerer from './sorcerer.png'
import warlock from './warlock.png'
import wizard from './wizard.png'

import plus_button from './add-button.png'
import minus_button from './minus-button.png'

import acid_splash from './acid_splash.png'
import blade_ward from './blade_ward.png'
import chill_touch from './bone_chill.png'
import dancing_lights from './dancing_lights.png'
import fire_bolt from './fire_bolt.png'
import friends from './friends.png'
import light from './light.png'
import mage_hand from './mage_hand.png'
import minor_illusion from './minor_illusion.png'
import poison_spray from './poison_spray.png'
import ray_of_frost from './ray_of_frost.png'
import shocking_grasp from './shocking_grasp.png'
import true_strike from './true_strike.png'
import acid_splash_tooltip from './acid_splash_tooltip.png'
import blade_ward_tooltip from './blade_ward_tooltip.png'
import chill_touch_tooltip from './bone_chill_tooltip.png'
import dancing_lights_tooltip from './dancing_lights_tooltip.png'
import fire_bolt_tooltip from './fire_bolt_tooltip.png'
import friends_tooltip from './friends_tooltip.png'
import light_tooltip from './light_tooltip.png'
import mage_hand_tooltip from './mage_hand_tooltip.png'
import minor_illusion_tooltip from './minor_illusion_tooltip.png'
import poison_spray_tooltip from './poison_spray_tooltip.png'
import ray_of_frost_tooltip from './ray_of_frost_tooltip.png'
import shocking_grasp_tooltip from './shocking_grasp_tooltip.png'
import true_strike_tooltip from './true_strike_tooltip.png'
import thaumaturgy from './thaumaturgy.png'
import thaumaturgy_tooltip from './thaumaturgy_tooltip.png'
import produce_flame from './produce_flame.png'
import produce_flame_tooltip from './produce_flame_tooltip.png'
import mage_hand_tooltip3 from './mage_hand_tooltip3.png'
import dancing_lights_tooltip3 from './dancing_lights_tooltip3.png'
import mage_hand_tooltip4 from './mage_hand_tooltip4.png'
import speak_with_animals_tooltip2 from './speak_with_animals_tooltip2.png'
import faerie_fire_tooltip2 from './faerie_fire_tooltip2.png'
import darkness from './darkness.png'
import darkness_tooltip from './darkness_tooltip.png'
import enlarge from './enlarge.png'
import enlarge_tooltip from './enlarge_tooltip.png'
import invisilibity from './invisivility.png'
import invisilibity_tooltip from './invisivility_tooltip.png'
import flame_blade from './flame_blade.png'
import tiefling_flame_blade_tooltip from './tiefling_flame_blade_tooltip.png'
import tiefling_hellish_rebuke_tooltip from './tiefling_hellish_rebuke_tooltip.png'
import tiefling_burning_hands_tooltip from './tiefling_burning_hands_tooltip.png'
import tiefling_searing_smite_tooltip from './searing_smite_tooltip.png'
import tiefling_branding_smite_tooltip from './branding_smite_tooltip.png'
import searing_smite from './searing_smite.png'
import branding_smite from './branding_smite.png'
import fire_breath from './fire_breath.png'
import fire_breath_tooltip from './fire_breath_tooltip.png'
import frost_breath from './frost_breath.png'
import frost_breath_tooltip from './frost_breath_tooltip.png'
import acid_breath from './acid_breath.png'
import acid_breath_tooltip from './acid_breath_tooltip.png'
import lightning_breath from './lightning_breath.png'
import lightning_breath_tooltip from './lightning_breath_tooltip.png'
import poison_breath from './poison_breath.png'
import poison_breath_tooltip from './poison_breath_tooltip.png'
import misty_step from './misty_step.png'
import githyanki_misty_step_tooltip from './githyanki_misty_step_tooltip.png'
import githyanki_enhance_leap_tooltip from './githyanki_enhance_leap_tooltip.png'


import rage from './rage.png'
import rage_tooltip from './rage_tooltip.png'
import viciousmockery from './viciousmockery.png'
import viciousmockery_tooltip from './viciousmockery_tooltip.png'
import combat_inspiration from './combat_inspiration.png'
import combat_inspiration_tooltip from './combat_inspiration_tooltip.png'
import blade_ward_tooltip2 from './blade_ward_tooltip2.png'
import friends_tooltip2 from './friends_tooltip2.png'
import dancing_lights_tooltip2 from './dancing_lights_tooltip2.png'
import light_tooltip2 from './light_tooltip2.png'
import mage_hand_tooltip2 from './mage_hand_tooltip2.png'
import minor_illusion_tooltip2 from './minor_illusion_tooltip2.png'
import true_strike_tooltip2 from './true_strike_tooltip2.png'
import animal_friendship from './animal_friendship.png'
import animal_friendship_tooltip from './animal_friendship_tooltip.png'
import bane from './bane.png'
import bane_tooltip from './bane_tooltip.png'
import charm_person from './charm_person.png'
import charm_person_tooltip from './charm_person_tooltip.png'
import cure_wounds from './cure_wounds.png'
import cure_wounds_tooltip from './cure_wounds_tooltip.png'
import disguise_self from './disguise_self.png'
import disguise_self_tooltip from './disguise_self_tooltip.png'
import dissonant_whispers from './dissonant_whispers.png'
import dissonant_whispers_tooltip from './dissonant_whispers_tooltip.png'
import feather_fall from './feather_fall.png'
import feather_fall_tooltip from './feather_fall_tooltip.png'
import healing_word from './healing_word.png'
import healing_word_tooltip from './healing_word_tooltip.png'
import heroism from './heroism.png'
import heroism_tooltip from './heroism_tooltip.png'
import tashas_hideous_laughter from './tashas_hideous_laughter.png'
import tashas_hideous_laughter_tooltip from './tashas_hideous_laughter_tooltip.png'
import longstrider from './longstrider.png'
import longstrider_tooltip from './longstrider_tooltip.png'
import sleep from './sleep.png'
import sleep_tooltip from './sleep_tooltip.png'
import faerie_fire from './faerie_fire.png'
import faerie_fire_tooltip from './faerie_fire_tooltip.png'
import speak_with_animals from './speak_with_animals.png'
import speak_with_animals_tooltip from './speak_with_animals_tooltip.png'
import thunderwave from './thunderwave.png'
import thunderwave_tooltip from './thunderwave_tooltip.png'
import thaumaturgy_tooltip2 from './thaumaturgy_tooltip2.png'
import guidance from './guidance.png'
import guidance_tooltip from './guidance_tooltip.png'
import resistance from './resistance.png'
import resistance_tooltip from './resistance_tooltip.png'
import sacred_flame from './sacred_flame.png'
import sacred_flame_tooltip from './sacred_flame_tooltip.png'
import bless from './bless.png'
import bless_tooltip from './bless_tooltip.png'
import command from './command.png'
import command_tooltip from './command_tooltip.png'
import create_water from './create_water.png'
import create_water_tooltip from './create_water_tooltip.png'
import guiding_bolt from './guiding_bolt.png'
import guiding_bolt_tooltip from './guiding_bolt_tooltip.png'
import inflict_wounds from './inflict_wounds.png'
import inflict_wounds_tooltip from './inflict_wounds_tooltip.png'
import protection_from_good_and_evil from './protection_from_good_and_evil.png'
import protection_from_good_and_evil_tooltip from './protection_from_good_and_evil_tooltip.png'
import shield_of_faith from './shield_of_faith.png'
import shield_of_faith_tooltip from './shield_of_faith_tooltip.png'
import burning_hands from './burning_hands.png'
import burning_hands_tooltip from './burning_hands_tooltip.png'
import blessing_of_the_trickster from './blessing_of_the_trickster.png'
import blessing_of_the_trickster_tooltip from './blessing_of_the_trickster_tooltip.png'
import entangle from './entangle.png'
import entangle_tooltip from './entangle_tooltip.png'
import fog_cloud from './fog_cloud.png'
import fog_cloud_tooltip from './fog_cloud_tooltip.png'
import goodberry from './goodberry.png'
import goodberry_tooltip from './goodberry_tooltip.png'
import jump from './jump.png'
import jump_tooltip from './jump_tooltip.png'
import thorn_whip from './thorn_whip.png'
import thorn_whip_tooltip from './thorn_whip_tooltip.png'
import poison_spray_tooltip2 from './poison_spray_tooltip2.png'
import shillelagh from './shillelagh.png'
import shillelagh_tooltip from './shillelagh_tooltip.png'
import second_wind from './second_wind.png'
import second_wind_tooltip from './second_wind_tooltip.png'
import lay_on_hands from './lay_on_hands.png'
import lay_on_hands_tooltip from './lay_on_hands_tooltip.png'
import divine_sense from './divine_sense.png'
import divine_sense_tooltip from './divine_sense_tooltip.png'
import healing_radiance from './healing_radiance.png'
import healing_radiance_tooltip from './healing_radiance_tooltip.png'
import holy_rebuke from './holy_rebuke.png'
import holy_rebuke_tooltip from './holy_rebuke_tooltip.png'
import protection_from_good_and_evil_tooltip2 from './protection_from_good_and_evil_tooltip2.png'
import find_familiar from './find_familiar.png'
import find_familiar_tooltip from './find_familiar_tooltip.png'
import sneak_attack_melee from './sneak_attack_melee.png'
import sneak_attack_melee_tooltip from './sneak_attack_melee_tooltip.png'
import sneak_attack_range from './sneak_attack_range.png'
import sneak_attack_range_tooltip from './sneak_attack_range_tooltip.png'
import acid_splash_tooltip2 from './acid_splash_tooltip2.png'
import shocking_grasp_tooltip2 from './shocking_grasp_tooltip2.png'
import fire_bolt_tooltip2 from './fire_bolt_tooltip2.png'
import ray_of_frost_tooltip2 from './ray_of_frost_tooltip2.png'
import chill_touch_tooltip2 from './bone_chill_tooltip2.png'
import chromatic_orb from './chromatic_orb.png'
import chromatic_orb_tooltip from './chromatic_orb_tooltip.png'
import magic_missile from './magic_missile.png'
import magic_missile_tooltip from './magic_missile_tooltip.png'
import mage_armour from './mage_armour.png'
import mage_armour_tooltip from './mage_armour_tooltip.png'
import witch_bolt from './witch_bolt.png'
import witch_bolt_tooltip from './witch_bolt_tooltip.png'
import colour_spray from './colour_spray.png'
import colour_spray_tooltip from './colour_spray_tooltip.png'
import false_life from './false_life.png'
import false_life_tooltip from './false_life_tooltip.png'
import ray_of_sickness from './ray_of_sickness.png'
import ray_of_sickness_tooltip from './ray_of_sickness_tooltip.png'
import expeditious_retreat from './expeditious_retreat.png'
import expeditious_retreat_tooltip from './expeditious_retreat_tooltip.png'
import grease from './grease.png'
import grease_tooltip from './grease_tooltip.png'
import armor_of_agathys from './armor_of_agathys.png'
import armor_of_agathys_tooltip from './armor_of_agathys_tooltip.png'
import eldritch_blast from './eldritch_blast.png'
import eldritch_blast_tooltip from './eldritch_blast_tooltip.png'
import armor_of_agathys_tooltipw from './armor_of_agathys_tooltipw.png'
import arms_of_hadar from './arms_of_hadar.png';
import arms_of_hadar_tooltipw from './arms_of_hadar_tooltipw.png'
import burning_hands_tooltipw from './burning_hands_tooltipw.png'
import charm_person_tooltipw from './charm_person_tooltipw.png'
import command_tooltipw from './command_tooltipw.png'
import expeditious_retreat_tooltipw from './expeditious_retreat_tooltipw.png'
import hellish_rebuke from './hellish_rebuke.png'
import hellish_rebuke_tooltipw from './hellish_rebuke_tooltipw.png'
import protection_from_good_and_evil_tooltipw from './protection_from_good_and_evil_tooltipw.png'
import witch_bolt_tooltipw from './witch_bolt_tooltipw.png'
import hex from './hex.png'
import hex_tooltipw from './hex_tooltipw.png'
import dissonant_whispers_tooltipw from './dissonant_whispers_tooltipw.png'
import tashas_hideous_laughter_tooltipw from './tashas_hideous_laughter_tooltipw.png'
import find_familiar_tooltip2 from './find_familiar_tooltip2.png'
import arcane_recovery from './arcane_recovery.png'
import arcane_recovery_tooltip from './arcane_recovery_tooltip.png'
import spiteful_suffering from './spiteful_suffering.png'
import spiteful_suffering_tooltip from './spiteful_suffering_tooltip.png'
import sanctuary from './sanctuary.png'
import sanctuary_tooltip from './sanctuary_tooltip.png'
import divine_favour from './divine_favour.png'
import divine_favour_tooltip from './divine_favour_tooltip.png'
import ice_knife from './ice_knife.png'
import ice_knife_tooltip from './ice_knife_tooltip.png'
import flurry_of_blows from './flurry_of_blows.png'
import flurry_of_blows_tooltip from './flurry_of_blows_tooltip.png'
import inquisitor_might from './inquisitor_might.png'
import inquisitor_might_tooltip from './inquisitor_might_tooltip.png'
import shield from './shield.png'
import shield_tooltip from './shield_tooltip.png'
import faerie_fire_tooltipw from './faerie_fire_tooltipw.png'
import sleep_tooltipw from './sleep_tooltipw.png'
import fey_presence from './fey_presence.png'
import fey_presence_tooltip from './fey_presence_tooltip.png'


export const raceImages = {
  elf: elfImage,
  tiefling: tieflingImage,
  drow: drowImage,
  human: humanImage,
  githyanki: githyankiImage,
  dwarf: dwarfImage,
  halfelf: halfElfImage,
  halfling: halflingImage,
  gnome: gnomeImage,
  dragonborn: dragonbornImage,
  halforc: halfOrcImage,
};

export const abilityScores = {
  strength: strength,
  dexterity: dexterity,
  constitution: constitution,
  wisdom: wisdom,
  charisma: charisma,
  intelligence: intelligence
};

export const classLogo = {
  barbarian: barbarian,
  bard: bard,
  cleric: cleric,
  druid: druid,
  fighter: fighter,
  monk: monk,
  paladin: paladin,
  ranger: ranger,
  rogue: rogue,
  sorcerer: sorcerer,
  warlock: warlock,
  wizard: wizard
}

export const miscButtons = {
  plus_button: plus_button,
  minus_button: minus_button
}

export const cantripImages = {
  acid_splash: acid_splash,
  blade_ward: blade_ward,
  chill_touch: chill_touch,
  dancing_lights: dancing_lights,
  fire_bolt: fire_bolt,
  friends: friends,
  light: light,
  mage_hand: mage_hand,
  minor_illusion: minor_illusion,
  poison_spray: poison_spray,
  ray_of_frost: ray_of_frost,
  shocking_grasp: shocking_grasp,
  true_strike: true_strike,
  acid_splash_tooltip: acid_splash_tooltip,
  blade_ward_tooltip: blade_ward_tooltip,
  chill_touch_tooltip: chill_touch_tooltip,
  dancing_lights_tooltip: dancing_lights_tooltip,
  fire_bolt_tooltip: fire_bolt_tooltip,
  friends_tooltip: friends_tooltip,
  light_tooltip: light_tooltip,
  mage_hand_tooltip: mage_hand_tooltip,
  minor_illusion_tooltip: minor_illusion_tooltip,
  poison_spray_tooltip: poison_spray_tooltip,
  ray_of_frost_tooltip: ray_of_frost_tooltip,
  shocking_grasp_tooltip: shocking_grasp_tooltip,
  true_strike_tooltip: true_strike_tooltip,
  thaumaturgy: thaumaturgy,
  thaumaturgy_tooltip: thaumaturgy_tooltip,
  produce_flame: produce_flame,
  produce_flame_tooltip: produce_flame_tooltip,
  mage_hand_tooltip3: mage_hand_tooltip3,
  dancing_lights_tooltip3: dancing_lights_tooltip3,
  mage_hand_tooltip4: mage_hand_tooltip4,
  speak_with_animals_tooltip2: speak_with_animals_tooltip2,
  faerie_fire_tooltip2: faerie_fire_tooltip2,
  darkness: darkness,
  darkness_tooltip: darkness_tooltip,
  invisilibity: invisilibity,
  invisilibity_tooltip: invisilibity_tooltip,
  enlarge: enlarge,
  enlarge_tooltip: enlarge_tooltip,
  tiefling_branding_smite_tooltip: tiefling_branding_smite_tooltip,
  tiefling_burning_hands_tooltip: tiefling_burning_hands_tooltip,
  tiefling_flame_blade_tooltip: tiefling_flame_blade_tooltip,
  tiefling_hellish_rebuke_tooltip: tiefling_hellish_rebuke_tooltip,
  tiefling_searing_smite_tooltip: tiefling_searing_smite_tooltip,
  flame_blade: flame_blade,
  searing_smite: searing_smite,
  branding_smite: branding_smite,
  fire_breath: fire_breath,
  fire_breath_tooltip: fire_breath_tooltip,
  frost_breath: frost_breath,
  frost_breath_tooltip: frost_breath_tooltip,
  acid_breath: acid_breath,
  acid_breath_tooltip: acid_breath_tooltip,
  lightning_breath: lightning_breath,
  lightning_breath_tooltip: lightning_breath_tooltip,
  poison_breath: poison_breath,
  poison_breath_tooltip: poison_breath_tooltip,
  misty_step: misty_step,
  githyanki_misty_step_tooltip: githyanki_misty_step_tooltip,
  githyanki_enhance_leap_tooltip: githyanki_enhance_leap_tooltip
}

export const classImages = {
  rage: rage,
  rage_tooltip: rage_tooltip,
  viciousmockery: viciousmockery,
  viciousmockery_tooltip: viciousmockery_tooltip,
  combat_inspiration: combat_inspiration,
  combat_inspiration_tooltip: combat_inspiration_tooltip,
  blade_ward_tooltip2: blade_ward_tooltip2,
  friends_tooltip2: friends_tooltip2,
  light_tooltip2: light_tooltip2,
  mage_hand_tooltip2: mage_hand_tooltip2,
  minor_illusion_tooltip2: minor_illusion_tooltip2,
  true_strike_tooltip2: true_strike_tooltip2,
  dancing_lights_tooltip2: dancing_lights_tooltip2,
  animal_friendship: animal_friendship,
  animal_friendship_tooltip: animal_friendship_tooltip,
  bane: bane,
  bane_tooltip: bane_tooltip,
  charm_person: charm_person,
  charm_person_tooltip: charm_person_tooltip,
  cure_wounds: cure_wounds,
  cure_wounds_tooltip: cure_wounds_tooltip,
  disguise_self: disguise_self,
  disguise_self_tooltip: disguise_self_tooltip,
  dissonant_whispers: dissonant_whispers,
  dissonant_whispers_tooltip: dissonant_whispers_tooltip,
  faerie_fire: faerie_fire,
  faerie_fire_tooltip: faerie_fire_tooltip,
  feather_fall: feather_fall,
  feather_fall_tooltip: feather_fall_tooltip,
  healing_word: healing_word,
  healing_word_tooltip: healing_word_tooltip,
  heroism: heroism,
  heroism_tooltip: heroism_tooltip,
  tashas_hideous_laughter: tashas_hideous_laughter,
  tashas_hideous_laughter_tooltip: tashas_hideous_laughter_tooltip,
  longstrider: longstrider,
  longstrider_tooltip: longstrider_tooltip,
  sleep: sleep,
  sleep_tooltip: sleep_tooltip,
  speak_with_animals: speak_with_animals,
  speak_with_animals_tooltip: speak_with_animals_tooltip,
  thunderwave: thunderwave,
  thunderwave_tooltip: thunderwave_tooltip,
  thaumaturgy_tooltip2: thaumaturgy_tooltip2,
  guidance: guidance,
  guidance_tooltip: guidance_tooltip,
  resistance: resistance,
  resistance_tooltip: resistance_tooltip,
  sacred_flame: sacred_flame,
  sacred_flame_tooltip: sacred_flame_tooltip,
  bless: bless,
  bless_tooltip: bless_tooltip,
  command: command,
  command_tooltip: command_tooltip,
  create_water: create_water,
  create_water_tooltip: create_water_tooltip,
  guiding_bolt: guiding_bolt,
  guiding_bolt_tooltip: guiding_bolt_tooltip,
  inflict_wounds: inflict_wounds,
  inflict_wounds_tooltip: inflict_wounds_tooltip,
  protection_from_good_and_evil: protection_from_good_and_evil,
  protection_from_good_and_evil_tooltip: protection_from_good_and_evil_tooltip,
  shield_of_faith: shield_of_faith,
  shield_of_faith_tooltip: shield_of_faith_tooltip,
  burning_hands: burning_hands,
  burning_hands_tooltip: burning_hands_tooltip,
  blessing_of_the_trickster: blessing_of_the_trickster,
  blessing_of_the_trickster_tooltip: blessing_of_the_trickster_tooltip,
  entangle: entangle,
  entangle_tooltip: entangle_tooltip,
  fog_cloud: fog_cloud,
  fog_cloud_tooltip: fog_cloud_tooltip,
  goodberry: goodberry,
  goodberry_tooltip: goodberry_tooltip,
  jump: jump,
  jump_tooltip: jump_tooltip,
  thorn_whip: thorn_whip,
  thorn_whip_tooltip: thorn_whip_tooltip,
  shillelagh: shillelagh,
  shillelagh_tooltip: shillelagh_tooltip,
  poison_spray_tooltip2: poison_spray_tooltip2,
  second_wind: second_wind,
  second_wind_tooltip: second_wind_tooltip,
  lay_on_hands: lay_on_hands,
  lay_on_hands_tooltip: lay_on_hands_tooltip,
  divine_sense: divine_sense,
  divine_sense_tooltip: divine_sense_tooltip,
  healing_radiance: healing_radiance,
  healing_radiance_tooltip: healing_radiance_tooltip,
  holy_rebuke: holy_rebuke,
  holy_rebuke_tooltip: holy_rebuke_tooltip,
  protection_from_good_and_evil_tooltip2: protection_from_good_and_evil_tooltip2,
  find_familiar: find_familiar,
  find_familiar_tooltip: find_familiar_tooltip,
  sneak_attack_melee: sneak_attack_melee,
  sneak_attack_melee_tooltip: sneak_attack_melee_tooltip,
  sneak_attack_range: sneak_attack_range,
  sneak_attack_range_tooltip: sneak_attack_range_tooltip,
  acid_splash_tooltip2: acid_splash_tooltip2,
  fire_bolt_tooltip2: fire_bolt_tooltip2,
  ray_of_frost_tooltip2: ray_of_frost_tooltip2,
  shocking_grasp_tooltip2: shocking_grasp_tooltip2,
  chill_touch_tooltip2: chill_touch_tooltip2,
  chromatic_orb: chromatic_orb,
  chromatic_orb_tooltip: chromatic_orb_tooltip,
  magic_missile: magic_missile,
  magic_missile_tooltip: magic_missile_tooltip,
  mage_armour: mage_armour,
  mage_armour_tooltip: mage_armour_tooltip,
  witch_bolt: witch_bolt,
  witch_bolt_tooltip: witch_bolt_tooltip,
  colour_spray: colour_spray,
  colour_spray_tooltip: colour_spray_tooltip,
  false_life: false_life,
  false_life_tooltip: false_life_tooltip,
  ray_of_sickness: ray_of_sickness,
  ray_of_sickness_tooltip: ray_of_sickness_tooltip,
  expeditious_retreat: expeditious_retreat,
  expeditious_retreat_tooltip: expeditious_retreat_tooltip,
  grease: grease,
  grease_tooltip: grease_tooltip,
  armor_of_agathys: armor_of_agathys,
  armor_of_agathys_tooltip: armor_of_agathys_tooltip,
  eldritch_blast: eldritch_blast,
  eldritch_blast_tooltip: eldritch_blast_tooltip,
  armor_of_agathys_tooltipw: armor_of_agathys_tooltipw,
  arms_of_hadar: arms_of_hadar,
  arms_of_hadar_tooltipw: arms_of_hadar_tooltipw,
  burning_hands_tooltipw: burning_hands_tooltipw,
  charm_person_tooltipw: charm_person_tooltipw,
  command_tooltipw: command_tooltipw,
  expeditious_retreat_tooltipw: expeditious_retreat_tooltipw,
  hellish_rebuke_tooltipw: hellish_rebuke_tooltipw,
  hellish_rebuke: hellish_rebuke,
  protection_from_good_and_evil_tooltipw: protection_from_good_and_evil_tooltipw,
  witch_bolt_tooltipw: witch_bolt_tooltipw,
  hex: hex,
  hex_tooltipw: hex_tooltipw,
  dissonant_whispers_tooltipw: dissonant_whispers_tooltipw,
  tashas_hideous_laughter_tooltipw: tashas_hideous_laughter_tooltipw,
  find_familiar_tooltip2: find_familiar_tooltip2,
  arcane_recovery: arcane_recovery,
  arcane_recovery_tooltip: arcane_recovery_tooltip,
  spiteful_suffering: spiteful_suffering,
  spiteful_suffering_tooltip: spiteful_suffering_tooltip,
  sanctuary: sanctuary,
  sanctuary_tooltip: sanctuary_tooltip,
  divine_favour: divine_favour,
  divine_favour_tooltip: divine_favour_tooltip,
  ice_knife: ice_knife,
  ice_knife_tooltip: ice_knife_tooltip,
  flurry_of_blows: flurry_of_blows,
  flurry_of_blows_tooltip: flurry_of_blows_tooltip,
  inquisitor_might: inquisitor_might,
  inquisitor_might_tooltip: inquisitor_might_tooltip,
  shield: shield,
  shield_tooltip: shield_tooltip,
  faerie_fire_tooltipw: faerie_fire_tooltipw,
  sleep_tooltipw: sleep_tooltipw,
  fey_presence: fey_presence,
  fey_presence_tooltip: fey_presence_tooltip
}
