import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import { raceImages, cantripImages, classImages, abilityScores, miscButtons, classLogo } from '../images'; // Import the images object from the images folder
import backgroundImage from '../images/pl7841h8ntr51.png';
import Modal from 'react-modal';
import raceData from './race_bonuses.json';
import backgroundData from './background_info.json';
import classData from './class_info.json';


Modal.setAppElement('#root');

const generalFont = 'Georgia, serif'

const myStyle = {
  background: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100vw',
  height: '100vh',
  fontFamily: generalFont,
  overflow: 'auto', // Enable scrolling when content overflows the container
  paddingTop: "10px"
};

const containerStyle = {
  position: 'relative',
  top: '50px',
  left: '5%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '20px',
  maxWidth: '96vw', // Set the maximum width to the viewport width
  paddingBottom: "70px"
};


const frameStyle = {
  border: '2px solid white',
  padding: '10px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row', // Vertical layout // Center contents horizontally and vertically
  backdropFilter: 'blur(5px)', // Apply the blur effect to the background
  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Add a translucent background color,
  marginTop: "20px",
  marginRight: "120px"
};

const frameStyle2 = {
  border: '2px solid white',
  padding: '10px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row', // Vertical layout
  alignItems: 'center', // Center contents horizontally and vertically
  backdropFilter: 'blur(5px)', // Apply the blur effect to the background
  backgroundColor: 'rgba(0, 0, 0, 0.3)', // Add a translucent background color,
  marginTop: "20px",
  marginRight: "120px"
};

const dropdownStyle = {
  marginBottom: '10px',
  borderRadius: '8px',
  border: '2px solid #6b5844',
  background: '#d3c4a5',
  color: 'black',
  padding: '5px',
  fontWeight: 'bold',
  fontFamily: generalFont,
  fontSize: '18px',
};

function Home() {
  const navigate = useNavigate();
  const location = useLocation();

  const [checkURL, setCheckURL] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const race_query = queryParams.get('r');
    const existsInRaceButtons = raceButtons.some(
      (race) => race.name === race_query || (race.secondaryModal && race.secondaryModal.includes(race_query))
    );
    if (existsInRaceButtons === false) {
      navigate('/');
      setCheckURL(false)
    }
    else {
      setCheckURL(true)
    }

    const name_query = queryParams.get('n');
  }, [location.search]);


  /* RACE BUTTON START */
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [secondaryModalIsOpen, setSecondaryModalIsOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedSecondaryButton, setSelectedSecondaryButton] = useState('');

  const openRaceModal = () => {
    setModalIsOpen(true);
  };

  const closeRaceModal = () => {
    setModalIsOpen(false);
  };

  const openSecondaryModal = () => {
    setSecondaryModalIsOpen(true);
    navigate('/');
  };

  const closeSecondaryModal = () => {
    setSecondaryModalIsOpen(false);
    setIsRaceSelected(false);
  };

  const handleRaceButtonClick = (buttonName, buttonImage) => {
    setSelectedButton(buttonName);
    setSelectedImage(buttonImage);
    closeRaceModal();
    if (['Elf', 'Tiefling', 'Drow', 'Dwarf', 'Half-Elf', 'Halfling', 'Gnome', 'Dragonborn'].includes(buttonName)) {
      openSecondaryModal();
    }
    else {
      navigate(`?r=${encodeURIComponent(buttonName)}`);
      setSelectedRaceData(buttonName);
    }
  };

  const handleSecondaryButtonClick = (buttonName) => {
    closeSecondaryModal();
    navigate(`?r=${encodeURIComponent(buttonName)}`);
    setSelectedSecondaryButton(buttonName);
    setSelectedRaceData(buttonName)
  };

  const secondaryModalButtons = {
    Dwarf: [{ id: 1, name: 'Gold Dwarf' }, { id: 2, name: 'Shield Dwarf' }, { id: 3, name: 'Duergar' },], Elf: [{ id: 1, name: 'High Elf' }, { id: 2, name: 'Wood Elf' },],
    Drow: [{ id: 1, name: 'Seldarine Drow' }, { id: 2, name: 'Lolth Drow' },], Tiefling: [{ id: 1, name: 'Asmodeus Tiefling' }, { id: 2, name: 'Mephistopheles Tiefling' }, { id: 3, name: 'Zariel Tiefling' },],
    Halfling: [{ id: 1, name: 'Lightfoot Halfling' }, { id: 2, name: 'Strongheart Halfling' },], 'Half-Elf': [{ id: 1, name: 'High Half-Elf' }, { id: 2, name: 'Wood Half-Elf' }, { id: 3, name: 'Drow Half-Elf' },],
    Gnome: [{ id: 1, name: 'Forest Gnome' }, { id: 2, name: 'Deep Gnome' }, { id: 3, name: 'Rock Gnome' },], Dragonborn: [{ id: 1, name: 'Black Dragonborn' }, { id: 2, name: 'Blue Dragonborn' }, { id: 3, name: 'Brass Dragonborn' }, { id: 4, name: 'Bronze Dragonborn' }, { id: 5, name: 'Copper Dragonborn' }, { id: 6, name: 'Gold Dragonborn' }, { id: 7, name: 'Green Dragonborn' }, { id: 8, name: 'Red Dragonborn' }, { id: 9, name: 'Silver Dragonborn' }, { id: 10, name: 'White Dragonborn' },],
  };

  const raceButtons = [
    { id: 1, name: 'Elf', image: raceImages.elf, secondaryModal: ['High Elf', 'Wood Elf'] },
    { id: 2, name: 'Tiefling', image: raceImages.tiefling, secondaryModal: ['Asmodeus Tiefling', 'Mephistopheles Tiefling', 'Zariel Tiefling'] },
    { id: 3, name: 'Drow', image: raceImages.drow, secondaryModal: ['Seldarine Drow', 'Lolth Drow'] },
    { id: 4, name: 'Human', image: raceImages.human },
    { id: 5, name: 'Githyanki', image: raceImages.githyanki },
    { id: 6, name: 'Dwarf', image: raceImages.dwarf, secondaryModal: ['Gold Dwarf', 'Shield Dwarf', 'Duergar'] },
    { id: 7, name: 'Half-Elf', image: raceImages.halfelf, secondaryModal: ['High Half-Elf', 'Wood Half-Elf', 'Drow Half-Elf'] },
    { id: 8, name: 'Halfling', image: raceImages.halfling, secondaryModal: ['Lightfoot Halfling', 'Strongheart Halfling'] },
    { id: 9, name: 'Gnome', image: raceImages.gnome, secondaryModal: ['Forest Gnome', 'Deep Gnome', 'Rock Gnome'] },
    { id: 10, name: 'Dragonborn', image: raceImages.dragonborn, secondaryModal: ['Black Dragonborn', 'Blue Dragonborn', 'Brass Dragonborn', 'Bronze Dragonborn', 'Copper Dragonborn', 'Gold Dragonborn', 'Green Dragonborn', 'Red Dragonborn', 'Silver Dragonborn', 'White Dragonborn'] },
    { id: 11, name: 'Half-Orc', image: raceImages.halforc },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('r');

    if (selectedButtonName) {
      const selectedRace = raceButtons.find((button) => button.name === selectedButtonName);
      if (selectedRace) {
        setSelectedButton(selectedRace.name);
        setSelectedImage(selectedRace.image);
        setSelectedSecondaryButton('');

        const selectedSecondaryModalButton = raceButtons.find(
          (button) => button.secondaryModal && button.secondaryModal.includes(selectedButtonName)
        );
        if (selectedSecondaryModalButton) {
          setSelectedSecondaryButton(selectedButtonName);
        }
      } else {
        const matchingRace = raceButtons.find((button) => button.secondaryModal && button.secondaryModal.includes(selectedButtonName));
        if (matchingRace) {
          setSelectedButton(matchingRace.name);
          setSelectedImage(matchingRace.image);
          setSelectedSecondaryButton(selectedButtonName);
        } else {
          setSelectedButton('');
          setSelectedImage('');
        }
      }
    }
  }, [location.search]);

  const raceButtonStyle = {
    background: '#8B4513',
    color: '#FDF5E6', // Light beige color that works well on the dark background
    padding: '20px 40px',
    border: '2px solid #CD853F',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: '24px',
    cursor: 'pointer',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
    fontFamily: generalFont, // Medieval-like font
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  };

  const raceModalStyle = {
    overlay: {
      backgroundColor: 'rgba(40, 30, 20, 0.7)', // Darker color between gray and brown with transparency
    },
    content: {
      width: '90%',
      maxWidth: '100vw',
      margin: 'auto',
      borderRadius: '8px',
      top: '65%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#48382A', // Dark brown color for the modal content
      fontFamily: generalFont,
      height: "65%",
    },
  };

  const buttonContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    marginLeft: "22%",
    marginRight: "22%"
  };
  /* RACE BUTTON END */

  /* RACE BONUSES START */
  // Update the state variable name
  const [selectedRaceData, setSelectedRaceData] = useState(null); // State variable to store the race data
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('r');
    setSelectedRaceData(selectedButtonName)
  }, [location.search]);

  /* RACE BONUSES END */

  /* MODAL COMPONENTS START */
  const RaceModal = () => (
    <Modal isOpen={modalIsOpen} onRequestClose={closeRaceModal} style={raceModalStyle}>
      <h2 style={{ color: 'white' }}>Select a race:</h2>
      <div className="button-container" style={buttonContainerStyle}>
        {raceButtons.map((button) => (
          <button
            key={button.id}
            onClick={() => handleRaceButtonClick(button.name, button.image)}
            className={selectedButton === button.name ? 'active' : ''}
            style={{
              background: 'transparent',
              border: 'none',
              padding: 0,
              width: '150px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
            }}
          >
            <img
              src={button.image}
              alt={button.name}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
          </button>
        ))}
      </div>
      <div className="close-race-button-container">
        <button onClick={closeRaceModal} style={raceButtonStyle}>
          Close race selection
        </button>
      </div>
    </Modal>
  );

  const SecondaryModal = () => (
    <Modal isOpen={secondaryModalIsOpen} onRequestClose={closeSecondaryModal} style={raceModalStyle} shouldCloseOnOverlayClick={false}>
      <h2 style={{ color: 'white' }}>Select Subrace</h2>
      {selectedButton && secondaryModalButtons[selectedButton] && (
        <div className="button-container">
          {secondaryModalButtons[selectedButton].map((button) => (
            <button
              key={button.id}
              onClick={() => handleSecondaryButtonClick(button.name)}
              style={{
                background: '#8B4513',
                color: '#FDF5E6',
                padding: '35px 55px',
                border: '2px solid #CD853F',
                borderRadius: '10px',
                fontWeight: 'bold',
                fontSize: '20px',
                cursor: 'pointer',
                margin: '10px',
              }}
            >
              {button.name}
            </button>
          ))}
        </div>
      )}
      <div className="close-race-button-container">
        <button
          onClick={() => {
            closeSecondaryModal();
            setSelectedButton('');
            setSelectedImage('');
          }}
          style={raceButtonStyle}
        >
          Close and Reset
        </button>
      </div>
    </Modal>
  );
  /* MODAL COMPONENTS END */

  /* RIGHT SIDE RACE START */
  const [isCantripModalOpen, setIsCantripModalOpen] = useState(false);
  const [isCantripButtonClicked, setIsCantripButtonClicked] = useState(false);
  const [pressedCantripButton, setPressedCantripButton] = useState(null);
  const [cantripImage, setCantripImage] = useState(null);
  const [cantripImageTooltip, setCantripImageTooltip] = useState(null);

  const handleOpenCantripModal = () => {
    setIsCantripModalOpen(true);
  };

  const handleCloseCantripModal = () => {
    setIsCantripModalOpen(false);
  };

  const handleCantripButtonClick = (buttonName, buttonImage, buttonTooltip) => {
    setPressedCantripButton(buttonName);
    setCantripImage(buttonImage);
    setIsCantripButtonClicked(true);
    setCantripImageTooltip(buttonTooltip);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('rc', buttonName);

    const baseUrl = window.location.href.split('?')[0];
    const newUrl = `${baseUrl}?${searchParams.toString()}`;
    window.history.pushState(null, '', newUrl);
    handleCloseCantripModal();
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('r');

    const existsInRaceButtons = raceButtons.some(
      (race) => race.name === selectedButtonName || (race.secondaryModal && race.secondaryModal.includes(selectedButtonName))
    );
    // Check if the "r" query parameter is present in the URL
    if (selectedButtonName && existsInRaceButtons === true) {
      setPressedCantripButton("");
      setIsRaceSelected(true);
    }

    const cantripParam = queryParams.get('rc');
    const selectedCantrip = raceCantrips.find((button) => button.name === cantripParam);
    const existsInCantripButtons = raceCantrips.some(
      (cantrip) => cantrip.name === cantripParam
    );

    // If the 'rc' parameter exists in the URL
    if (cantripParam && existsInRaceButtons === true && existsInCantripButtons === true) {
      setPressedCantripButton(cantripParam);
      setIsCantripButtonClicked(true);
      setCantripImage(selectedCantrip ? selectedCantrip.image : null);
      setCantripImageTooltip(selectedCantrip ? selectedCantrip.tooltip : null);
    }
    else {
      setIsCantripButtonClicked(false);
      setPressedCantripButton("");
      setCantripImage(null);
      setCantripImageTooltip(null);
    }

  }, [location.search]);

  const raceCantrips = [
    { id: 1, name: 'Acid Splash', image: cantripImages.acid_splash, tooltip: cantripImages.acid_splash_tooltip },
    { id: 2, name: 'Blade Ward', image: cantripImages.blade_ward, tooltip: cantripImages.blade_ward_tooltip },
    { id: 3, name: 'Chill Touch', image: cantripImages.chill_touch, tooltip: cantripImages.chill_touch_tooltip },
    { id: 4, name: 'Dancing Lights', image: cantripImages.dancing_lights, tooltip: cantripImages.dancing_lights_tooltip },
    { id: 5, name: 'Fire Bolt', image: cantripImages.fire_bolt, tooltip: cantripImages.fire_bolt_tooltip },
    { id: 6, name: 'Friends', image: cantripImages.friends, tooltip: cantripImages.friends_tooltip },
    { id: 7, name: 'Light', image: cantripImages.light, tooltip: cantripImages.light_tooltip },
    { id: 8, name: 'Mage Hand', image: cantripImages.mage_hand, tooltip: cantripImages.mage_hand_tooltip },
    { id: 9, name: 'Minor Illusion', image: cantripImages.minor_illusion, tooltip: cantripImages.minor_illusion_tooltip },
    { id: 10, name: 'Poison Spray', image: cantripImages.poison_spray, tooltip: cantripImages.poison_spray_tooltip },
    { id: 11, name: 'Ray of Frost', image: cantripImages.ray_of_frost, tooltip: cantripImages.ray_of_frost_tooltip },
    { id: 12, name: 'Shocking Grasp', image: cantripImages.shocking_grasp, tooltip: cantripImages.shocking_grasp_tooltip },
    { id: 13, name: 'True Strike', image: cantripImages.true_strike, tooltip: cantripImages.true_strike_tooltip }
  ];

  const raceCantrips2 = {
    'Acid Splash': [cantripImages.acid_splash, cantripImages.acid_splash_tooltip],
    'Blade Ward': [cantripImages.blade_ward, cantripImages.blade_ward_tooltip],
    'Chill Touch': [cantripImages.chill_touch, cantripImages.chill_touch_tooltip],
    'Dancing Lights': [cantripImages.dancing_lights, cantripImages.dancing_lights_tooltip],
    'Fire Bolt': [cantripImages.fire_bolt, cantripImages.fire_bolt_tooltip],
    'Friends': [cantripImages.friends, cantripImages.friends_tooltip],
    'Light': [cantripImages.light, cantripImages.light_tooltip],
    'Mage Hand': [cantripImages.mage_hand, cantripImages.mage_hand_tooltip],
    'Minor Illusion': [cantripImages.minor_illusion, cantripImages.minor_illusion_tooltip],
    'Poison Spray': [cantripImages.poison_spray, cantripImages.poison_spray_tooltip],
    'Ray of Frost': [cantripImages.ray_of_frost, cantripImages.ray_of_frost_tooltip],
    'Shocking Grasp': [cantripImages.shocking_grasp, cantripImages.shocking_grasp_tooltip],
    'True Strike': [cantripImages.true_strike, cantripImages.true_strike_tooltip],
    Thaumaturgy: [cantripImages.thaumaturgy, classImages.thaumaturgy_tooltip2],
    'Produce Flame': [cantripImages.produce_flame, cantripImages.produce_flame_tooltip],
    'Mage Hand2': [cantripImages.mage_hand, cantripImages.mage_hand_tooltip3],
    'Dancing Lights drow': [cantripImages.dancing_lights, cantripImages.dancing_lights_tooltip3],
    'Mage Hand gith': [cantripImages.mage_hand, cantripImages.mage_hand_tooltip4],
    'Speak with Animals': [classImages.speak_with_animals, cantripImages.speak_with_animals_tooltip2]
  };


  const raceCantripsSpecific = {
    Thaumaturgy: [cantripImages.thaumaturgy, classImages.thaumaturgy_tooltip2],
    'Produce Flame': [cantripImages.produce_flame, cantripImages.produce_flame_tooltip],
    'Mage Hand': [cantripImages.mage_hand, cantripImages.mage_hand_tooltip3],
    'Dancing Lights': [cantripImages.dancing_lights, cantripImages.dancing_lights_tooltip3],
    'Mage Hand gith': [cantripImages.mage_hand, cantripImages.mage_hand_tooltip4],
    'Speak with Animals': [classImages.speak_with_animals, cantripImages.speak_with_animals_tooltip2],
    'Faerie Fire': [classImages.faerie_fire, cantripImages.faerie_fire_tooltip2],
    'Darkness': [cantripImages.darkness, cantripImages.darkness_tooltip],
    'Invisibility': [cantripImages.invisilibity, cantripImages.invisilibity_tooltip],
    'Enlarge': [cantripImages.enlarge, cantripImages.enlarge_tooltip],
    'Hellish Rebuke': [classImages.hellish_rebuke, cantripImages.tiefling_hellish_rebuke_tooltip],
    "Burning Hands": [classImages.burning_hands, cantripImages.tiefling_burning_hands_tooltip],
    'Flame Blade': [cantripImages.flame_blade, cantripImages.tiefling_flame_blade_tooltip],
    'Searing Smite': [cantripImages.searing_smite, cantripImages.tiefling_searing_smite_tooltip],
    'Branding Smite': [cantripImages.branding_smite, cantripImages.tiefling_branding_smite_tooltip],
    'Acid Breath': [cantripImages.acid_breath, cantripImages.acid_breath_tooltip],
    'Frost Breath': [cantripImages.frost_breath, cantripImages.frost_breath_tooltip],
    'Fire Breath': [cantripImages.fire_breath, cantripImages.fire_breath_tooltip],
    'Lightning Breath': [cantripImages.lightning_breath, cantripImages.lightning_breath_tooltip],
    'Poison Breath': [cantripImages.poison_breath, cantripImages.poison_breath_tooltip],
    'Jump Gith': [classImages.jump, cantripImages.githyanki_enhance_leap_tooltip],
    'Misty Step Gith': [cantripImages.misty_step, cantripImages.githyanki_misty_step_tooltip]
  }

  const cantripModalContent = (isCantripModalOpen, handleCloseCantripModal, raceCantrips, handleCantripButtonClick) => {
    const queryParams = new URLSearchParams(window.location.search);
    let raceCantripsSelected = [];
    if (queryParams.get("c") === "Wizard" && queryParams.has("cc")) {
      raceCantripsSelected = queryParams.get("cc").split(',');
    }

    const modifiedRaceCantrips = raceCantripsSelected === null
      ? raceCantrips
      : raceCantrips.filter((spell) => !raceCantripsSelected.includes(spell.name));


    return (
      <div>
        <Modal isOpen={isCantripModalOpen} onRequestClose={handleCloseCantripModal} style={raceModalStyle}>
          <h2 style={{ color: 'white' }}>Select a cantrip:</h2>
          <div className="button-container" style={buttonContainerStyle}>
            {modifiedRaceCantrips.map((button) => (
              <div key={button.id}>
                <div
                  className="cantrip-button-wrapper"
                  onClick={() => handleCantripButtonClick(button.name, button.image, button.tooltip)}
                >
                  <button
                    style={{
                      background: 'transparent',
                      border: 'none',
                      padding: 0,
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '50px',
                    }}
                  >
                    <img
                      src={button.image}
                      alt={button.name}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
                  </button>
                  <div className="tooltip-content" id={`tooltip-${button.id}`}>
                    <img src={button.tooltip} alt={button.name} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="close-race-button-container">
            <button onClick={handleCloseCantripModal} style={raceButtonStyle}>
              Close cantrip selection
            </button>
          </div>
        </Modal>
      </div>
    );
  };

  const cantripModalContentClassSpecific = (isCantripModalOpen, handleCloseCantripModal, raceCantrips, handleCantripButtonClick) => {
    return (
      <div>
        <Modal isOpen={isCantripModalOpen} onRequestClose={handleCloseCantripModal} style={raceModalStyle}>
          <h2 style={{ color: 'white' }}>Select a cantrip:</h2>
          <div className="button-container" style={buttonContainerStyle}>
            {raceCantrips.map((button) => (
              <div key={button.id}>
                <div
                  className="cantrip-button-wrapper"
                  onClick={() => handleCantripButtonClick(button.name, button.image, button.tooltip)}
                >
                  <button
                    style={{
                      background: 'transparent',
                      border: 'none',
                      padding: 0,
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '50px',
                    }}
                  >
                    <img
                      src={button.image}
                      alt={button.name}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
                  </button>
                  <div className="tooltip-content" id={`tooltip-${button.id}`}>
                    <img src={button.tooltip} alt={button.name} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="close-race-button-container">
            <button onClick={handleCloseCantripModal} style={raceButtonStyle}>
              Close cantrip selection
            </button>
          </div>
        </Modal>
      </div>
    );
  };


  const rightSideContent = (
    <div style={{ marginLeft: '50px', color: 'white' }}>
      {(selectedRaceData !== null && checkURL === true) && (
        <>
          <h3>
            <span style={{ color: '#FFBF00' }}>Lore:</span> <span style={{ fontSize: "20px" }}>{raceData[selectedRaceData].Lore}</span>
          </h3>
          <h3>
            <span style={{ color: '#FFBF00' }}>Ability score:</span> <span style={{ fontSize: "20px" }}>{raceData[selectedRaceData].abilityScore}</span>
          </h3>
          <h3 style={{ color: '#FFBF00' }}>Race Features:</h3>
          <ul>
            {Object.entries(raceData[selectedRaceData].raceFeatures).map(([key, value]) => (
              <li key={key}>
                <span style={{ color: '#D2B48C' }}>{key}: </span>
                {typeof value === 'object' ? Object.values(value).join(', ') : value}
              </li>
            ))}
          </ul>
          {raceData[selectedRaceData].subraceTraits && (
            <div>
              <h3 style={{ color: '#FFBF00' }}>Subrace Traits:</h3>
              <ul>
                {Object.entries(raceData[selectedRaceData].subraceTraits).map(([key, value]) => (
                  <li key={key}>
                    <span style={{ color: '#D2B48C' }}>{key}: </span>
                    {typeof value === 'object' ? Object.values(value).join(', ') : value}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div>
            {/* Button to open the modal */}
            {(selectedSecondaryButton === 'High Elf' || selectedSecondaryButton === 'High Half-Elf') && (
              <button
                onClick={handleOpenCantripModal}
                style={{
                  background: '#8B4513',
                  color: '#FDF5E6',
                  padding: '10px 20px',
                  border: '2px solid #CD853F',
                  borderRadius: '20px',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  cursor: 'pointer',
                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                  fontFamily: generalFont,
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                  marginBottom: "10px"
                }}
              >
                Select Cantrip
              </button>
            )}

            {selectedSecondaryButton === 'Asmodeus Tiefling' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Race Cantrip (At level 1):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Produce Flame"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Produce Flame"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 3):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Hellish Rebuke"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Hellish Rebuke"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 5):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Darkness"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Darkness"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedSecondaryButton === 'Mephistopheles Tiefling' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Race Cantrip (At level 1):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Mage Hand"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Mage Hand"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 3):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Burning Hands"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Burning Hands"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 5):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Flame Blade"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Flame Blade"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedSecondaryButton === 'Zariel Tiefling' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Race Cantrip (At level 1):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Thaumaturgy"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Thaumaturgy"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 3):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Searing Smite"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Searing Smite"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 5):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Branding Smite"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Branding Smite"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedSecondaryButton === 'Drow Half-Elf' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Race Cantrip (Level 1):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Dancing Lights"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Dancing Lights"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 3):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Faerie Fire"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Faerie Fire"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 5):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Darkness"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Darkness"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(selectedSecondaryButton === 'Lolth Drow' || selectedSecondaryButton === 'Seldarine Drow') && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 3):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Faerie Fire"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Faerie Fire"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 5):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Darkness"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Darkness"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedButton === 'Githyanki' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Race Cantrip (At level 1):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Mage Hand gith"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Mage Hand gith"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 3):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative', marginLeft: "25px" }}>
                    <img
                      src={raceCantripsSpecific["Jump Gith"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Jump Gith"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Cantrip (At level 5):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Misty Step Gith"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Misty Step Gith"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {selectedSecondaryButton === 'Forest Gnome' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Race Spell (At level 1):</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={raceCantripsSpecific["Speak with Animals"][0]}
                    style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={raceCantripsSpecific["Speak with Animals"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            )}
            {selectedSecondaryButton === 'Duergar' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 3):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Enlarge"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Enlarge"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Race Spell (At level 5):</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={raceCantripsSpecific["Invisibility"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={raceCantripsSpecific["Invisibility"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(selectedSecondaryButton === 'Black Dragonborn' || selectedSecondaryButton === 'Copper Dragonborn') && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Race Action (At level 1):</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={raceCantripsSpecific["Acid Breath"][0]}
                    style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={raceCantripsSpecific["Acid Breath"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            )}
            {(selectedSecondaryButton === 'Blue Dragonborn' || selectedSecondaryButton === 'Bronze Dragonborn') && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Race Action (At level 1):</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={raceCantripsSpecific["Lightning Breath"][0]}
                    style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={raceCantripsSpecific["Lightning Breath"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            )}
            {(selectedSecondaryButton === 'Brass Dragonborn' || selectedSecondaryButton === 'Gold Dragonborn' || selectedSecondaryButton === 'Red Dragonborn') && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Race Action (At level 1):</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={raceCantripsSpecific["Fire Breath"][0]}
                    style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={raceCantripsSpecific["Fire Breath"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            )}
            {(selectedSecondaryButton === 'Silver Dragonborn' || selectedSecondaryButton === 'White Dragonborn') && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Race Action (At level 1):</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={raceCantripsSpecific["Frost Breath"][0]}
                    style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={raceCantripsSpecific["Frost Breath"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            )}
            {selectedSecondaryButton === 'Green Dragonborn' && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Race Action (At level 1):</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={raceCantripsSpecific["Poison Breath"][0]}
                    style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={raceCantripsSpecific["Poison Breath"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            )}

            {/* Modal */}
            {isCantripModalOpen && cantripModalContent(isCantripModalOpen, handleCloseCantripModal, raceCantrips, handleCantripButtonClick)}
            {isCantripButtonClicked === true && (
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Race Cantrip:</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={cantripImage}
                    style={{ width: "55%", height: "55%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={cantripImageTooltip} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            )}
          </div>
          <h5 style={{ marginTop: "30px" }}>
            <span style={{ color: '#FFBF00' }}>*</span> <span style={{ fontSize: "14px" }}>{raceData[selectedRaceData].asterisk}</span>
          </h5>
        </>
      )}
    </div>
  );
  /* RIGHT SIDE RACE END */

  const RaceButtonSection = ({ openRaceModal, raceButtonStyle, selectedImage, selectedSecondaryButton, selectedButton }) => (
    <div>
      {/* Race button start */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button onClick={openRaceModal} style={raceButtonStyle}>
          Race
        </button>
        {/* Display the selected image */}
        {selectedImage && (
          <div style={{ marginLeft: '50px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={selectedImage} alt="Selected Race" style={{ width: '100px', height: '100px' }} />
            <p style={{ color: 'white', fontSize: '20px', marginTop: '5px', textAlign: 'center' }}>
              {selectedSecondaryButton || selectedButton}
            </p>
          </div>
        )}
      </div>
      {/* Race button end */}
      <div>
        <RaceModal />
        <SecondaryModal />
      </div>
    </div>

  );

  /* NAME START */
  const [name, setName] = useState('Tav');
  const [isRaceSelected, setIsRaceSelected] = useState(null);
  const [isNameSelected, setIsNameSelected] = useState(null);
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  const handleInputBlur = (e) => {
    // Update the URL with the name value
    setIsNameSelected(true);
    if (name.trim() !== '') {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('n', e.target.value);

      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${searchParams.toString()}`;
      window.history.pushState(null, '', newUrl);
      setName(e.target.value);
    }
    else {
      e.target.value = "Tav";
      handleInputChange(e);
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('n', e.target.value);
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${searchParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }
  };

  const handleKeyPress = (e) => {
    // If the "Enter" key is pressed (key code 13), trigger the input blur event
    if (e.key === 'Enter') {
      handleInputBlur(e);
      inputRef.current.blur(); // Remove the focus from the input element
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const nameParam = queryParams.get('n');
    const selectedButtonName = queryParams.get('r');
    const existsInRaceButtons = raceButtons.some(
      (race) => race.name === selectedButtonName || (race.secondaryModal && race.secondaryModal.includes(selectedButtonName))
    );

    if (selectedButtonName && existsInRaceButtons === true) {
      setName('');
      setIsRaceSelected(true);
    }

    if (nameParam && existsInRaceButtons === true) {
      setName(nameParam);
      setIsNameSelected(true);
    } else if (queryParams.has("b") && existsInRaceButtons === true && !nameParam) {
      setName('Tav');
      setIsNameSelected(true);
    } else {
      setIsNameSelected(false);
      setName("");
    }
  }, [location.search]);

  const NameTextAndFrame = (
    isRaceSelected === true && (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '2px solid white', // Adding a brown border around the frame
          borderRadius: '10px',
          padding: '22px', // Padding for the frame
          boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)', // Adding the box shadow
          marginTop: "20px",
        }}
      >
        <input
          ref={inputRef}
          type="text"
          placeholder="Character name"
          value={name}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
          style={{
            width: '350px',
            padding: '10px',
            fontSize: '18px',
            borderRadius: '5px',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'white',
            fontFamily: generalFont
          }}
        />
      </div>
    )
  );

  /* NAME END */

  /* BACKGROUND START */
  const [backgroundName, setBackgroundName] = useState('');
  const [isBackgroundSelected, setIsBackgroundSelected] = useState(false);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const backgroundList = ["Acolyte", "Charlatan", "Criminal", "Entertainer", "Folk Hero", "Guild Artisan", "Hermit", "Noble", "Outlander", "Sage", "Soldier", "Sailor", "Urchin"];

  const handleButtonClick = (buttonName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('b', buttonName);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    setIsBackgroundSelected(true);
    setBackgroundName(buttonName);
    setSelectedBackground(buttonName);
    handleSkills(buttonName, currentParams.get("c"));
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('b');
    const containsName = backgroundList.includes(selectedButtonName);
    if (containsName) {
      setIsBackgroundSelected(true);
      setBackgroundName(selectedButtonName);
      setSelectedBackground(selectedButtonName);
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'b') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setIsBackgroundSelected(false);
      setBackgroundName("");
      setSelectedBackground(null);
    }
  }, [location.search]);

  useEffect(() => {
  }, [backgroundName, isBackgroundSelected]);

  const backgroundButtonStyle = {
    background: '#8B4513',
    color: '#FDF5E6', // Light beige color that works well on the dark background
    padding: '10px 5px', // Smaller padding
    border: '2px solid #CD853F',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: '16px', // Smaller font size
    cursor: 'pointer',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
    fontFamily: generalFont, // Medieval-like font
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    width: "110px",
    height: "87px"
  };

  const highlightedBackgroundButtonStyle = {
    ...backgroundButtonStyle,
    background: '#CD853F', // Change the background color for the highlighted button
    color: 'white', // Change the text color for the highlighted button
  };

  const BackgroundFrame = (
    isNameSelected === true && (
      <div style={frameStyle2}>
        <div style={{ alignItems: 'center' }}>
          {/* "Background" text box */}
          <div
            style={{
              width: '210px', // Adjust the width as needed
              margin: '0 auto', // Center the text horizontally
              fontFamily: generalFont, // Medieval-like font
              marginTop: "20px",
              marginBottom: "20px",
              background: '#444444',
              color: '#FDF5E6',
              padding: '35px 35px', // Smaller padding
              border: '2px solid #CD853F',
              borderRadius: '50px',
              fontWeight: 'bold',
              fontSize: '22px', // Smaller font size
              boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            }}
          >
            Background

          </div>

          {/* Buttons container */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)', // 4 columns
              gap: '10px', // Gap between buttons
            }}
          >
            {/* First row of buttons */}
            <button style={selectedBackground === 'Acolyte' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Acolyte')}>
              Acolyte
            </button>
            <button style={selectedBackground === 'Charlatan' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Charlatan')}>
              Charlatan
            </button>
            <button style={selectedBackground === 'Criminal' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Criminal')}>
              Criminal
            </button>
            <button style={selectedBackground === 'Entertainer' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Entertainer')}>
              Entertainer
            </button>

            {/* Second row of buttons */}
            <button style={selectedBackground === 'Folk Hero' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Folk Hero')}>
              Folk Hero
            </button>
            <button style={selectedBackground === 'Guild Artisan' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Guild Artisan')}>
              Guild Artisan
            </button>
            <button style={selectedBackground === 'Noble' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Noble')}>
              Noble
            </button>

            {/* Third row of buttons */}
            <button style={selectedBackground === 'Outlander' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Outlander')}>
              Outlander
            </button>
            <button style={selectedBackground === 'Sage' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Sage')}>
              Sage
            </button>
            <button style={selectedBackground === 'Soldier' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Soldier')}>
              Soldier
            </button>

            {/* Fourth row of buttons */}
            <button style={selectedBackground === 'Urchin' ? highlightedBackgroundButtonStyle : backgroundButtonStyle} onClick={() => handleButtonClick('Urchin')}>
              Urchin
            </button>
          </div>
        </div>
        <div style={{ marginLeft: '50px', color: 'white' }}>
          {(isNameSelected && isBackgroundSelected) && (
            <>
              <h3>
                <span style={{ color: '#FFBF00' }}>Description:</span> <span style={{ fontSize: "17px" }}>{backgroundData[backgroundName].Description}</span>
              </h3>
              <h3 style={{ color: '#FFBF00' }}>Features:</h3>
              <ul>
                {Object.entries(backgroundData[backgroundName].Features).map(([key, value]) => (
                  <li key={key}>
                    <span style={{ color: '#D2B48C' }}>{key}: </span>
                    {typeof value === 'object' ? Object.values(value).join(', ') : value}
                  </li>
                ))}
              </ul>
              <h3>
                <span style={{ color: '#FFBF00' }}>Background Goals:</span> <span style={{ fontSize: "17px" }}>{backgroundData[backgroundName].BackgroundGoals}</span>
              </h3>
            </>
          )}
        </div>
      </div>
    )
  );

  /* BACKGROUND END */

  /* CLASSES START */

  const [className, setClassName] = useState('');
  const [isClassSelected, setIsClassSelected] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [subclassName, setSubclassName] = useState('');
  const [isSubclassSelected, setIsSubclassSelected] = useState(false);
  const [selectedSubclass, setSelectedSubclass] = useState(null);

  const [isClassCantripModalOpen, setIsClassCantripModalOpen] = useState(false);
  const [isClassCantripButtonClicked, setIsClassCantripButtonClicked] = useState(false);
  const [classCantripImages, setClassCantripImages] = useState([]);
  const [classCantripImageTooltips, setClassCantripImageTooltips] = useState([]);

  const [isClassCantripSpecificModalOpen, setIsClassCantripSpecificModalOpen] = useState(false);
  const [isClassCantripSpecificButtonClicked, setIsClassCantripSpecificButtonClicked] = useState(false);
  const [classCantripSpecificImage, setClassCantripSpecificImage] = useState();
  const [classCantripSpecificImageTooltip, setClassCantripSpecificImageTooltip] = useState();

  const [clickedCantripButtons, setClickedCantripButtons] = useState([]);

  const handleOpenClassCantripModal = () => {
    setIsClassCantripModalOpen(true);
  };

  const handleOpenClassCantripSpecificModal = () => {
    setIsClassCantripSpecificModalOpen(true);
  };

  const handleCloseClassCantripModal = () => {
    setIsClassCantripModalOpen(false);
    setClickedCantripButtons([]); // Reset clicked buttons when closing the modal
    setClassCantripImages([]);
    setClassCantripImageTooltips([]);

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('cc')) {
      // Remove the "cc" parameter
      queryParams.delete('cc');

      // Get the modified search string
      const modifiedSearch = queryParams.toString();

      // Build the new URL without the "cc" parameter
      const newPath = `${location.pathname}?${modifiedSearch}`;

      // Update the URL (without the "cc" parameter)
      navigate(newPath, { replace: true });
    }
  };

  const handleCloseClassCantripSpecificModalWithClick = () => {
    setIsClassCantripSpecificModalOpen(false);
  };

  const handleCloseClassCantripSpecificModal = () => {
    setIsClassCantripSpecificModalOpen(false);
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("ccs")) {
      queryParams.delete('ccs');
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({}, '', newUrl);
    }
    setIsClassCantripSpecificButtonClicked(false);
    setClassCantripSpecificImage();
    setClassCantripSpecificImageTooltip();
  };

  const handleCloseCantripModalAccept = () => {
    setIsClassCantripModalOpen(false);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('cc', clickedCantripButtons);
    setIsClassCantripButtonClicked(true);
    const baseUrl = window.location.href.split('?')[0];
    const newUrl = `${baseUrl}?${searchParams.toString()}`;
    window.history.pushState(null, '', newUrl);
  };


  const handleClassCantripButtonsClick = (buttonName, numberOfCantrips, buttonImage, buttonTooltip) => {
    // ...

    if (clickedCantripButtons.includes(buttonName)) {
      const updatedCantripButtons = clickedCantripButtons.filter((button) => button !== buttonName);
      setClickedCantripButtons(updatedCantripButtons);

      // Find the index of the button to remove its corresponding image and tooltip
      const indexToRemove = classCantripImages.findIndex((image) => image === buttonImage);
      if (indexToRemove !== -1) {
        // Create a new array without the image to remove
        const updatedImages = [...classCantripImages];
        updatedImages.splice(indexToRemove, 1);
        setClassCantripImages(updatedImages);
      }

      // Find the index of the button to remove its corresponding tooltip image
      const indexToRemoveTooltip = classCantripImageTooltips.findIndex((tooltip) => tooltip === buttonTooltip);
      if (indexToRemoveTooltip !== -1) {
        // Create a new array without the tooltip image to remove
        const updatedTooltips = [...classCantripImageTooltips];
        updatedTooltips.splice(indexToRemoveTooltip, 1);
        setClassCantripImageTooltips(updatedTooltips);
      }
    } else if (clickedCantripButtons.length < numberOfCantrips) {
      setClickedCantripButtons((prevClickedButtons) => [...prevClickedButtons, buttonName]);

      // Append the new image and tooltip to their respective lists
      setClassCantripImages((prevImages) => [...prevImages, buttonImage]);

      setClassCantripImageTooltips((prevTooltips) => [...prevTooltips, buttonTooltip]);
    }
  };

  const handleCantripSpecificButtonClick = (buttonName, buttonImage, buttonTooltip) => {
    setClassCantripSpecificImage(buttonImage);
    setIsClassCantripSpecificButtonClicked(true);
    setClassCantripSpecificImageTooltip(buttonTooltip);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('ccs', buttonName);

    const baseUrl = window.location.href.split('?')[0];
    const newUrl = `${baseUrl}?${searchParams.toString()}`;
    window.history.pushState(null, '', newUrl);
    handleCloseClassCantripSpecificModalWithClick();
  };

  const [isClassSpellModalOpen, setIsClassSpellModalOpen] = useState(false);
  const [isClassSpellButtonClicked, setIsClassSpellButtonClicked] = useState(false);
  const [classSpellImages, setClassSpellImages] = useState([]);
  const [classSpellImageTooltips, setClassSpellImageTooltips] = useState([]);

  const [clickedSpellButtons, setClickedSpellButtons] = useState([]);
  const handleOpenClassSpellModal = () => {
    setIsClassSpellModalOpen(true);
  };
  const handleCloseClassSpellModal = () => {
    setIsClassSpellModalOpen(false);
    setClickedSpellButtons([]); // Reset clicked buttons when closing the modal
    setClassSpellImages([]);
    setClassSpellImageTooltips([]);

    if (selectedClass === "Wizard") {
      handleCloseClassPreparedSpellModal();
    }

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('cs')) {
      // Remove the "cc" parameter
      queryParams.delete('cs');

      // Get the modified search string
      const modifiedSearch = queryParams.toString();

      // Build the new URL without the "cc" parameter
      const newPath = `${location.pathname}?${modifiedSearch}`;

      // Update the URL (without the "cc" parameter)
      navigate(newPath, { replace: true });
    }
  };

  const handleCloseSpellModalAccept = () => {
    setIsClassSpellModalOpen(false);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('cs', clickedSpellButtons);
    setIsClassSpellButtonClicked(true);
    const baseUrl = window.location.href.split('?')[0];
    const newUrl = `${baseUrl}?${searchParams.toString()}`;
    window.history.pushState(null, '', newUrl);
  };

  const handleClassSpellButtonsClick = (buttonName, numberOfSpells, buttonImage, buttonTooltip) => {
    // ...

    if (clickedSpellButtons.includes(buttonName)) {
      const updatedSpellButtons = clickedSpellButtons.filter((button) => button !== buttonName);
      setClickedSpellButtons(updatedSpellButtons);

      // Find the index of the button to remove its corresponding image and tooltip
      const indexToRemove = classSpellImages.findIndex((image) => image === buttonImage);
      if (indexToRemove !== -1) {
        // Create a new array without the image to remove
        const updatedImages = [...classSpellImages];
        updatedImages.splice(indexToRemove, 1);
        setClassSpellImages(updatedImages);
      }

      // Find the index of the button to remove its corresponding tooltip image
      const indexToRemoveTooltip = classSpellImageTooltips.findIndex((tooltip) => tooltip === buttonTooltip);
      if (indexToRemoveTooltip !== -1) {
        // Create a new array without the tooltip image to remove
        const updatedTooltips = [...classSpellImageTooltips];
        updatedTooltips.splice(indexToRemoveTooltip, 1);
        setClassSpellImageTooltips(updatedTooltips);
      }
    } else if (clickedSpellButtons.length < numberOfSpells) {
      setClickedSpellButtons((prevClickedButtons) => [...prevClickedButtons, buttonName]);

      // Append the new image and tooltip to their respective lists
      setClassSpellImages((prevImages) => [...prevImages, buttonImage]);

      setClassSpellImageTooltips((prevTooltips) => [...prevTooltips, buttonTooltip]);
    }
  };

  const [isClassPreparedSpellModalOpen, setIsClassPreparedSpellModalOpen] = useState(false);
  const [isClassPreparedSpellButtonClicked, setIsClassPreparedSpellButtonClicked] = useState(false);
  const [classPreparedSpellImages, setClassPreparedSpellImages] = useState([]);
  const [classPreparedSpellImageTooltips, setClassPreparedSpellImageTooltips] = useState([]);

  const [clickedPreparedSpellButtons, setClickedPreparedSpellButtons] = useState([]);
  const handleOpenClassPreparedSpellModal = () => {
    setIsClassPreparedSpellModalOpen(true);
  };
  const handleCloseClassPreparedSpellModal = () => {
    setIsClassPreparedSpellModalOpen(false);
    setClickedPreparedSpellButtons([]); // Reset clicked buttons when closing the modal
    setClassPreparedSpellImages([]);
    setClassPreparedSpellImageTooltips([]);

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('ps')) {
      // Remove the "cc" parameter
      queryParams.delete('ps');

      // Get the modified search string
      const modifiedSearch = queryParams.toString();

      // Build the new URL without the "cc" parameter
      const newPath = `${location.pathname}?${modifiedSearch}`;

      // Update the URL (without the "cc" parameter)
      navigate(newPath, { replace: true });
    }
  };

  const handleClosePreparedSpellModalAccept = () => {
    setIsClassPreparedSpellModalOpen(false);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('ps', clickedPreparedSpellButtons);
    setIsClassPreparedSpellButtonClicked(true);
    const baseUrl = window.location.href.split('?')[0];
    const newUrl = `${baseUrl}?${searchParams.toString()}`;
    window.history.pushState(null, '', newUrl);
  };

  const handleClassPreparedSpellButtonsClick = (buttonName, numberOfSpells, buttonImage, buttonTooltip) => {
    // ...

    if (clickedPreparedSpellButtons.includes(buttonName)) {
      const updatedPreparedSpellButtons = clickedPreparedSpellButtons.filter((button) => button !== buttonName);
      setClickedPreparedSpellButtons(updatedPreparedSpellButtons);

      // Find the index of the button to remove its corresponding image and tooltip
      const indexToRemove = classPreparedSpellImages.findIndex((image) => image === buttonImage);
      if (indexToRemove !== -1) {
        // Create a new array without the image to remove
        const updatedImages = [...classPreparedSpellImages];
        updatedImages.splice(indexToRemove, 1);
        setClassPreparedSpellImages(updatedImages);
      }

      // Find the index of the button to remove its corresponding tooltip image
      const indexToRemoveTooltip = classPreparedSpellImageTooltips.findIndex((tooltip) => tooltip === buttonTooltip);
      if (indexToRemoveTooltip !== -1) {
        // Create a new array without the tooltip image to remove
        const updatedTooltips = [...classPreparedSpellImageTooltips];
        updatedTooltips.splice(indexToRemoveTooltip, 1);
        setClassPreparedSpellImageTooltips(updatedTooltips);
      }
    } else if (clickedPreparedSpellButtons.length < numberOfSpells) {
      setClickedPreparedSpellButtons((prevClickedButtons) => [...prevClickedButtons, buttonName]);

      // Append the new image and tooltip to their respective lists
      setClassPreparedSpellImages((prevImages) => [...prevImages, buttonImage]);

      setClassPreparedSpellImageTooltips((prevTooltips) => [...prevTooltips, buttonTooltip]);
    }
  };

  const [selectedInstrument, setSelectedInstrument] = useState('');
  const [selectedBloodline, setSelectedBloodline] = useState('');
  const [selectedDeity, setSelectedDeity] = useState('');
  const [selectedDeityDescription, setSelectedDeityDescription] = useState('');
  const [selectedFighterStyle, setSelectedFighterStyle] = useState('');
  const [selectedFighterIndex, setSelectedFighterIndex] = useState(null);
  const [selectedFavouredEnemy, setSelectedFavouredEnemy] = useState('');
  const [selectedFavouredEnemyIndex, setSelectedFavouredEnemyIndex] = useState(null);
  const [selectedNaturalExplorer, setSelectedNaturalExplorer] = useState('');
  const [selectedNaturalExplorerIndex, setSelectedNaturalExplorerIndex] = useState(null);
  const [classProficiencies, setClassProficiencies] = useState([]);

  const classList = ["Barbarian", "Bard", "Cleric", "Druid", "Fighter", "Monk", "Paladin", "Ranger", "Rogue", "Sorcerer", "Warlock", "Wizard"];
  const subclassList = ["Berserker", "Wildheart", "Wild Magic", "Life Domain", "Light Domain", "Trickery Domain", "Knowledge Domain", "Nature Domain", "Tempest Domain", "War Domain", "College of Lore",
    "College of Valour", "College of Swords", "Circle of the Moon", "Circle of the Land", "Circle of the Spores", "Battle Master", "Eldritch Knight", "Champion", "Way of the Open Hand", "Way of Shadow",
    "Way of the Four Elements", "Oath of Devotion", "Oath of the Ancients", "Oath of Vengeance", "Oathbreaker", "Beast Master", "Hunter", "Gloom Stalker", "Thief", "Arcane Trickster", "Assassin",
    "Draconic Bloodline", "Wild Magic", "Storm Sorcery", "The Fiend", "The Great Old One", "Archfey", "Abjuration School", "Conjuration School", "Divination School", "Enchantment School", "Evocation School", "Necromancy School", "Illusion School", "Transmutation School"];

  const bardInstruments = ["Hand Drum", "Flute", "Lute", "Lyre", "Violin"];
  const deityNames = ["Corellon Larethian", "Eilistrae", "Garl Glittergold", "Helm", "Ilmater", "Kelemvor", "Lolth", "Moradin", "Myrkul", "Mystra", "Oghma", "Selune", "Shar", "Tempus", "Tyr", "Vlaakith", "Yondalla", "Talos", "Mielikki", "Tymora", "Lathander"];
  const deityDescriptions = [
    "(Chaotic Good) Creator of the Elves, Corellon Larethian oversees the elven pantheon as a whole, providing blessings to those who study art, magic, and nature.",
    "(Chaotic Good) Eilistrae is the goddess of good-aligned Drow, beauty, song, and freedom. The Dark Maiden desires balance between all races, and struggles against her mother Lolth's corrupt aims.",
    "(Lawful Good) The Watchful Protector is the king of gnomish gods - a deity of humour, gemcutting, protection, and trickery.",
    "(Lawful Neutral) The Watcher is an eternal sentry among the gods, representing guardians across the planes. After more than a century of fading worship, Helm's power was restored with the Second Sundering.",
    "(Lawful Good) The Crying God protects the oppressed and persecuted. His clergy is sworn to alleviate suffering even if that means taking on that pain personally.",
    "(Lawful Neutral) Fair but distant, Kelemvor guides the dead to their appropriate plane in the afterlife. His clergy provides last rites across Faerun, but also destroy undead that have escaped Kelemvor's judgement.",
    "(Chaotic Evil) The much-reviled matriarch of the Drow pantheon, Lolth holds sway over spiders, the Underdark, and the infernal creatures of the Demonweb Pits. Her primary goal is to corrupt all Drow, transforming them into heartless cultists.",
    "(Lawful Good) The All-Hammer is a dwarven god worshipped by smiths, artisans and miners alike. He and Laduguer are constantly at odds.",
    "(Neutral Evil) A member of the Dead Three with Bhaal and Bane, Myrkul is a cruel necromancer turned god, inspiring the fear of death in mortals. He often clashes with Kelemvor, the even-handed judge of fallen souls.",
    "(Neutral Good) As the mother of all magic, Mystra oversees the Weave and spreads arcane knowledge to mortal spellcasters. Her clerics preserve ancient lore and protect bastions of magical energy.",
    "(Neutral) Oghma is the god of inspiration and invention, sharing knowledge with the world through his Bards and Clerics. Unlike many other Deities, Oghma accepts all moral alignments into his clergy.",
    "(Chaotic Good) The Lady of Silver presides over the moon, stars, and navigation. Her power over the heavens is constantly challenged by her sister Shar, who seeks to plunge the world into eternal shadow.",
    "(Neutral Evil) As the greater deity of darkness, Shar is feared for her power over the night, secrets, and loss. She is locked in eternal conflict with her twin sister Selune, goddess of the moon.",
    "(Neutral) Tempus is the Lord of Battles, overseeing war and its soldiers. He is the embodiment of honourable combat, and condemns needless bloodlust.",
    "(Lawful Good) The Blind God rules over law and justice, encouraging valiant acts from his followers and relentlessly pursuing oathbreakers.",
    "(Neutral Evil) The Lich Queen is the sole respected leader of the Githyanki. She encourages their worship and unquestioning devotion, essentially acting as a Deity.",
    "(Lawful Good) As the mother of the Halfling pantheon, Yondalla is known for her kindness and open mind, encouraging her followers to protect the home, hearth, and nature.",
    "(Chaotic Evil) While Mielikki values the quiet that nature brings, Talos represents how uncaring and destructive it can be. His followers have a philosophy that regards life as a series of chaotic events, with random chance and luck dictating what happens.",
    "(Neutral Good) This goddess is specifically a goddess of the beautiful silence in nature, described as remote and spiritual in BG3 itself. She has the ability to turn trees into treants with a mere touch.",
    "(Chaotic Good) Tymora is a deity who values those who take risks. She blesses those with skill and the willingness to gamble their time, possessions, and lives on both frivolous and important pursuits.",
    "(Neutral Good) Also known as the Morning Lord, is a neutral good greater deity of spring, dawn, birth, renewal, beginnings, hidden potential, conception, vitality, youth and self-perfection.",
  ];
  const favouredEnemy = ["Bounty Hunter", "Keeper of the Veil", "Mage Breaker", "Ranger Knight", "Sanctified Stalker"];
  const favouredEnemyDescription = [
    "Gain Proficiency in Investigation. Creatures you hit with Ensnaring Strike (either ranged or melee) have Disadvantage on their Saving Throw.",
    "You specialise in hunting creatures from other planes of existence. You gain Proficiency in Arcana and can grant protection against aberrations, celestials, elementals, fey, fiends, and undead.",
    "You have a history of battling spellcasters. Gain Proficiency with Arcana and the True Strike Cantrip, which gives you Advantage on Attack Rolls against a creature. Wisdom is your Spellcasting Ability for this spell.",
    "You have sworn to serve a crown or nation and seek to bring its foes to ruin. Gain Proficiency with History and Proficiency with Heavy Armour.",
    "You swore to hunt the enemies of a holy or druidic order. Gain Proficiency with Religion and the Sacred Flame Cantrip, which deals 1d8 Radiant damage. Wisdom is your Spellcasting Ability for the Cantrip."
  ];

  const naturalExplorer = ["Beast Tamer", "Urban Tracker", "Wasteland Wanderer: Cold", "Wasteland Wanderer: Fire", "Wasteland Wanderer: Poison"];
  const naturalExplorerDescription = [
    "You have cultivated a strong bond with animals. You can cast Find Familiar as a ritual.",
    "An expert at navigating the wild within the city, you gain Sleight of Hand Proficiency.",
    "You have spent endless days surviving desolate tundras. Gain resistance to Cold damage, taking only half damage from it.",
    "You have spent endless days surviving forbidding deserts. Gain resistance to Fire, taking only half damage from it.",
    "You have spent endless days surviving fetid swamps. Gain resistance to Poison, taking only half damage from it."
  ];

  const rangerProficiencies = {
    "Bounty Hunter": "Investigation",
    "Keeper of the Veil": "Arcana",
    "Mage Breaker": "Arcana",
    "Ranger Knight": "History",
    "Sanctified Stalker": "Religion",
    "Urban Tracker": "Sleight of Hand"
  }


  const fighterStyles = ["Archery", "Defence", "Duelling", "Great Weapon Fighting", "Protection", "Two-Weapon Fighting"];
  const fighterStylesDescription = [
    "You gain a +2 bonus to attack rolls you make with ranged weapons.",
    "You gain a +1 bonus to Armour Class while wearing armour.",
    "When you are wielding a melee weapon that that is not Two-Handed or Versatile in one hand, and no weapon in the other, you deal an additional 2 damage with that weapon.",
    "When you roll a 1 or 2 on a damage die for an attack with a two-handed melee weapon, you can reroll the die and must use the new roll.",
    "When you have a shield, impose disadvantage on an enemy who attacks one of your allies when you are within 1.5 m. You must be able to see the enemy.",
    "When you attack with two weapons, you can add your ability modifier to the damage of the offhand attack.",
  ];

  const sorcererBloodlines = ["Red (Fire)", "Burning Hands", "Black (Acid)", "Grease", "Blue (Lightning)", "Witch Bolt", "White (Cold)", "Armor of Agathys", "Green (Poison)", "Ray of Sickness", "Gold (Fire)", "Disguise Self", "Silver (Cold)", "Feather Fall", "Bronze (Lightning)", "Fog Cloud", "Copper (Acid)", "Tasha's Hideous Laughter", "Brass (Fire)", "Sleep"];

  const classCantripsList = ["Blade Ward", "Dancing Lights", "Friends", "Light", "Mage Hand", "Minor Illusion", "True Strike", "Vicious Mockery", "Guidance", "Resistance", "Sacred Flame", "Guidance", "Resistance", "Sacred Flame", "Thaumaturgy",
    "Shillelagh", "Thorn Whip", "True Strike", "Acid Splash", "Poison Spray", "Fire Bolt", "Ray of Frost", "Shocking Grasp", "Chill Touch", "Eldritch Blast", "Produce Flame"];

  const classSpellsList = ["Animal Friendship", "Bane", "Charm Person", "Cure Wounds", "Disguise Self", "Dissonant Whispers", "Faerie Fire", "Feather Fall", "Healing Word", "Heroism", "Tasha's Hideous Laughter",
    "Longstrider", "Sleep", "Speak with Animals", "Thunderwave", "Protection from Evil and Good", "Find Familiar", "Chromatic Orb", "Fog Cloud", "Burning Hands", "Magic Missile", "Mage Armour", "Witch Bolt",
    "Colour Spray", "False Life", "Ray of Sickness", "Expeditious Retreat", "Feather Fall", "Jump", "Grease", "Armor of Agathys", "Arms of Hadar(Warlock)", "Hellish Rebuke(Warlock)", "Hex(Warlock)", "Tasha's Hideous Laughter(Warlock)", "Dissonant Whispers(Warlock)",
    "Armor of Agathys(Warlock)", "Burning Hands(Warlock)", "Charm Person(Warlock)", "Command(Warlock)", "Expeditious Retreat(Warlock)", "Protection from Evil and Good(Warlock)", "Witch Bolt(Warlock)", "Sanctuary", "Ice Knife", "Shield", "Sleep(Warlock)", "Faerie Fire(Warlock)"];

  const classPreparedSpellsList = ["Bane", "Command", "Create or Destroy Water", "Guiding Bolt", "Healing Word", "Inflict Wounds", "Protection from Evil and Good", "Shield of Faith", "Bless", "Command", "Cure Wounds",
    "Entangle", "Fog Cloud", "Goodberry", "Jump", "Animal Friendship", "Bane", "Charm Person", "Cure Wounds", "Disguise Self", "Dissonant Whispers", "Faerie Fire", "Feather Fall", "Healing Word", "Heroism", "Tasha's Hideous Laughter",
    "Longstrider", "Sleep", "Speak with Animals", "Thunderwave", "Protection from Evil and Good", "Find Familiar", "Chromatic Orb", "Fog Cloud", "Burning Hands", "Magic Missile", "Mage Armour", "Witch Bolt",
    "Colour Spray", "False Life", "Ray of Sickness", "Expeditious Retreat", "Feather Fall", "Jump", "Grease", "Armor of Agathys", "Arms of Hadar(Warlock)", "Hellish Rebuke(Warlock)", "Hex(Warlock)", "Tasha's Hideous Laughter(Warlock)", "Dissonant Whispers(Warlock)",
    "Armor of Agathys(Warlock)", "Burning Hands(Warlock)", "Charm Person(Warlock)", "Command(Warlock)", "Expeditious Retreat(Warlock)", "Protection from Evil and Good(Warlock)", "Witch Bolt(Warlock)", "Sanctuary", "Ice Knife", "Shield", "Sleep(Warlock)", "Faerie Fire(Warlock)"];

  const handleClassButtonClick = (buttonName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('c', buttonName);
    currentParams.delete("sc")
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    setIsClassSelected(true);
    setClassName(buttonName);
    setSelectedClass(buttonName);
    setIsSubclassSelected(false);
    setSubclassName("");
    setSelectedSubclass(null);
    if (buttonName === "Cleric") {
      currentParams.set('sc', "Life Domain");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Life Domain");
      setSelectedSubclass("Life Domain");
    }
    else if (buttonName === "Bard") {
      currentParams.set('sc', "College of Lore");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("College of Lore");
      setSelectedSubclass("College of Lore");
    }
    else if (buttonName === "Barbarian") {
      currentParams.set('sc', "Berserker");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Berserker");
      setSelectedSubclass("Berserker");
    }
    else if (buttonName === "Druid") {
      currentParams.set('sc', "Circle of the Land");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Circle of the Land");
      setSelectedSubclass("Circle of the Land");
    }
    else if (buttonName === "Fighter") {
      currentParams.set('sc', "Battle Master");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Battle Master");
      setSelectedSubclass("Battle Master");
    }
    else if (buttonName === "Monk") {
      currentParams.set('sc', "Way of the Open Hand");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Way of the Open Hand");
      setSelectedSubclass("Way of the Open Hand");
    }
    else if (buttonName === "Paladin") {
      currentParams.set('sc', "Oath of the Ancients");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Oath of the Ancients");
      setSelectedSubclass("Oath of the Ancients");
    }
    else if (buttonName === "Ranger") {
      currentParams.set('sc', "Beast Master");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Beast Master");
      setSelectedSubclass("Beast Master");
    }
    else if (buttonName === "Rogue") {
      currentParams.set('sc', "Thief");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Thief");
      setSelectedSubclass("Thief");
    }
    else if (buttonName === "Sorcerer") {
      currentParams.set('sc', "Wild Magic");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Wild Magic");
      setSelectedSubclass("Wild Magic");
    }
    else if (buttonName === "Warlock") {
      currentParams.set('sc', "The Fiend");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("The Fiend");
      setSelectedSubclass("The Fiend");
    }
    else if (buttonName === "Wizard") {
      currentParams.set('sc', "Abjuration School");
      const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
      window.history.pushState({}, '', newUrl);
      setIsSubclassSelected(true);
      setSubclassName("Abjuration School");
      setSelectedSubclass("Abjuration School");
    }

    removeDeity();
    removeInstrument();
    removeFightingStyle();
    removeFavouredEnemy();
    removeNaturalExplorer();
    removeBloodline();
    handleCloseClassCantripModal();
    handleCloseClassSpellModal();
    handleCloseClassPreparedSpellModal();
    handleCloseClassCantripSpecificModal();

    handleAttributes();
    handleSkills(selectedBackground, buttonName);
    setSkillPresses(classData[buttonName]?.Skills_to_choose)
    setExpertiseSkillPresses(2);


    setClassesAllLevels({ [buttonName]: 1 });
    setLevel1Class(buttonName);
    setLevel2Class("-2");
    setLevel3Class("-3");
    setLevel4Class("-4");
    setLevel5Class("-5");
    setLevel6Class("-6");
    setLevel7Class("-7");
    setLevel8Class("-8");
    setLevel9Class("-9");
    setLevel10Class("-10");
    setLevel11Class("-11");
    setLevel12Class("-12");
  };

  const handleSubclassButtonClick = (buttonName) => {
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('sc', buttonName);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    setIsSubclassSelected(true);
    setSubclassName(buttonName);
    setSelectedSubclass(buttonName);
    handleCloseClassSpellModal();
    handleCloseClassCantripModal();
    handleCloseClassPreparedSpellModal();
  };

  const handleInstrumentChange = (event) => {
    setSelectedInstrument(event.target.value);
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('bi', event.target.value);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const handleBloodlineChange = (event) => {
    setSelectedBloodline(event.target.value);
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('sb', event.target.value);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    handleCloseClassSpellModal();
  };

  const handleDeityChange = (event) => {
    const selectedDeityName = event.target.value;
    const index = deityNames.indexOf(selectedDeityName);
    if (index !== -1) {
      setSelectedDeity(deityNames[index]);
      setSelectedDeityDescription(deityDescriptions[index]);
    }
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('cd', selectedDeityName);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const handleFightingStyleChange = (index) => {
    setSelectedFighterStyle(fighterStyles[index]);
    setSelectedFighterIndex(index);
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('fs', fighterStyles[index]);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const handleFavouredEnemyChange = (index) => {
    setSelectedFavouredEnemy(favouredEnemy[index]);
    setSelectedFavouredEnemyIndex(index);
    if (classProficiencies.includes(rangerProficiencies["Urban Tracker"])) {
      setClassProficiencies([rangerProficiencies["Urban Tracker"], rangerProficiencies[favouredEnemy[index]]]);
    } else {
      setClassProficiencies((prevState) => [rangerProficiencies[favouredEnemy[index]]]);
    }
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('fe', favouredEnemy[index]);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    handleSkills(selectedBackground, className);
  };

  const handleNaturalExplorerChange = (index) => {
    setSelectedNaturalExplorer(naturalExplorer[index]);
    setSelectedNaturalExplorerIndex(index);
    if (naturalExplorer[index] === "Urban Tracker" && !classProficiencies.includes(rangerProficiencies["Urban Tracker"])) {
      setClassProficiencies((prevState) => prevState.concat(rangerProficiencies[naturalExplorer[index]]));
    }
    else if (naturalExplorer[index] !== "Urban Tracker") {
      setClassProficiencies((prevState) => prevState.filter((proficiency) => proficiency !== rangerProficiencies["Urban Tracker"]));
    }
    const currentParams = new URLSearchParams(window.location.search);
    currentParams.set('ne', naturalExplorer[index]);
    const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
    window.history.pushState({}, '', newUrl);
    handleSkills(selectedBackground, className);
  };


  const removeDeity = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("cd")) {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'cd') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedDeity('');
      setSelectedDeityDescription('');
    }
  }

  const removeFightingStyle = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("fs")) {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'fs') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedFighterStyle('')
      setSelectedFighterIndex(null)
    }
  }

  const removeFavouredEnemy = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("fe")) {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'fe') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedFavouredEnemy('')
      setSelectedFavouredEnemyIndex(null)
    }
  }

  const removeNaturalExplorer = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("ne")) {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'ne') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedNaturalExplorer('')
      setSelectedNaturalExplorerIndex(null)
    }
  }

  const removeInstrument = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("bi")) {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'bi') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedInstrument('');
    }
  }

  const removeBloodline = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("sb")) {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'sb') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedBloodline('');
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('c');
    const containsName = classList.includes(selectedButtonName);
    if (containsName) {
      setIsClassSelected(true);
      setClassName(selectedButtonName);
      setSelectedClass(selectedButtonName);

      if (selectedButton === "Cleric" && !queryParams.has('sc')) {
        setIsSubclassSelected(true);
        setSubclassName("Life Domain");
        setSelectedSubclass("Life Domain");
        queryParams.set('sc', "Life Domain");
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, '', newUrl);
      }
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'c') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setIsClassSelected(false);
      setClassName("");
      setSelectedClass(null);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('sc');
    const containsName = subclassList.includes(selectedButtonName);
    if (containsName) {
      setIsSubclassSelected(true);
      setSubclassName(selectedButtonName);
      setSelectedSubclass(selectedButtonName);
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'sc') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setIsSubclassSelected(false);
      setSubclassName("");
      setSelectedSubclass(null);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('bi');
    const containsName = bardInstruments.includes(selectedButtonName);
    if (containsName) {
      setSelectedInstrument(selectedButtonName)
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'bi') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedInstrument('');
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('sb');
    const containsName = sorcererBloodlines.includes(selectedButtonName);
    if (containsName) {
      setSelectedBloodline(selectedButtonName)
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'sb') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedBloodline('');
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('cd');
    const containsName = deityNames.includes(selectedButtonName);
    if (containsName) {
      setSelectedDeity(selectedButtonName)
      const index = deityNames.indexOf(selectedButtonName);
      if (index !== -1) {
        setSelectedDeityDescription(deityDescriptions[index]);
      }
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'cd') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedDeity('');
      setSelectedDeityDescription('');
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('fs');
    const containsName = fighterStyles.includes(selectedButtonName);
    if (containsName) {
      setSelectedFighterStyle(selectedButtonName)
      setSelectedFighterIndex(fighterStyles.indexOf(selectedButtonName))
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'fs') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedFighterStyle('');
      setSelectedFighterIndex(null);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('fe');
    const containsName = favouredEnemy.includes(selectedButtonName);
    if (containsName) {
      setSelectedFavouredEnemy(selectedButtonName)
      setSelectedFavouredEnemyIndex(favouredEnemy.indexOf(selectedButtonName))
      if (classProficiencies.includes(rangerProficiencies["Urban Tracker"])) {
        setClassProficiencies([rangerProficiencies["Urban Tracker"], rangerProficiencies[selectedButtonName]]);
      } else {
        setClassProficiencies((prevState) => [rangerProficiencies[selectedButtonName]]);
      }
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'fe') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedFavouredEnemy('');
      setSelectedFavouredEnemyIndex(null);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('ne');
    const containsName = naturalExplorer.includes(selectedButtonName);
    if (containsName) {
      setSelectedNaturalExplorer(selectedButtonName)
      setSelectedNaturalExplorerIndex(naturalExplorer.indexOf(selectedButtonName))
      if (selectedButtonName === "Urban Tracker" && !classProficiencies.includes(rangerProficiencies["Urban Tracker"])) {
        setClassProficiencies((prevState) => prevState.concat(rangerProficiencies[selectedButtonName]));
      }
      else if (selectedButtonName !== "Urban Tracker") {
        setClassProficiencies((prevState) => prevState.filter((proficiency) => proficiency !== rangerProficiencies["Urban Tracker"]));
      }
    } else {
      const keys = [...queryParams.keys()];
      let removeNext = false;

      for (const key of keys) {
        if (key === 'ne') {
          removeNext = true;
        }
        if (removeNext) {
          queryParams.delete(key);
        }
      }

      const newURL =
        window.location.origin + window.location.pathname + '?' + queryParams.toString();
      window.history.replaceState(null, document.title, newURL);
      setSelectedNaturalExplorer('');
      setSelectedNaturalExplorerIndex(null);
    }
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('cc');
    if (selectedButtonName) {
      const arrayList = selectedButtonName.split(",");
      const allElementsPresent = arrayList.every(item => classCantripsList.includes(item));
      if (allElementsPresent) {
        setClickedCantripButtons(arrayList);
        setIsClassCantripButtonClicked(true);
        const asdf = arrayList.map((cantrip) => classCantrips[cantrip][0]);
        const zxcv = arrayList.map((cantrip) => classCantrips[cantrip][1]);
        setClassCantripImages(asdf);
        setClassCantripImageTooltips(zxcv);
      } else {
        const keys = [...queryParams.keys()];
        let removeNext = false;

        for (const key of keys) {
          if (key === 'cc') {
            removeNext = true;
          }
          if (removeNext) {
            queryParams.delete(key);
          }
        }

        const newURL =
          window.location.origin + window.location.pathname + '?' + queryParams.toString();
        window.history.replaceState(null, document.title, newURL);
      }
    }

  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('cs');
    if (selectedButtonName) {
      const arrayList = selectedButtonName.split(",");
      const allElementsPresent = arrayList.every(item => classSpellsList.includes(item));
      if (allElementsPresent) {
        setClickedSpellButtons(arrayList);
        setIsClassSpellButtonClicked(true);
        const asdf = arrayList.map((spell) => classSpells[spell][0]);
        const zxcv = arrayList.map((spell) => classSpells[spell][1]);
        setClassSpellImages(asdf);
        setClassSpellImageTooltips(zxcv);
      } else {
        const keys = [...queryParams.keys()];
        let removeNext = false;

        for (const key of keys) {
          if (key === 'cs') {
            removeNext = true;
          }
          if (removeNext) {
            queryParams.delete(key);
          }
        }

        const newURL =
          window.location.origin + window.location.pathname + '?' + queryParams.toString();
        window.history.replaceState(null, document.title, newURL);
      }
    }

  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('ps');
    if (selectedButtonName) {
      const arrayList = selectedButtonName.split(",");
      const allElementsPresent = arrayList.every(item => classPreparedSpellsList.includes(item));
      if (allElementsPresent) {
        setClickedPreparedSpellButtons(arrayList);
        setIsClassPreparedSpellButtonClicked(true);
        const asdf = arrayList.map((spell) => classPreparedSpells[spell][0]);
        const zxcv = arrayList.map((spell) => classPreparedSpells[spell][1]);
        setClassPreparedSpellImages(asdf);
        setClassPreparedSpellImageTooltips(zxcv);
      } else {
        const keys = [...queryParams.keys()];
        let removeNext = false;

        for (const key of keys) {
          if (key === 'ps') {
            removeNext = true;
          }
          if (removeNext) {
            queryParams.delete(key);
          }
        }

        const newURL =
          window.location.origin + window.location.pathname + '?' + queryParams.toString();
        window.history.replaceState(null, document.title, newURL);
      }
    }

  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const selectedButtonName = queryParams.get('ccs');
    if (selectedButtonName) {
      const arrayList = selectedButtonName.split(",");
      const allElementsPresent = arrayList.every(item => classCantripsList.includes(item));
      if (allElementsPresent) {
        setIsClassCantripSpecificButtonClicked(true);
        const asdf = arrayList.map((cantrip) => classCantrips[cantrip][0]);
        const zxcv = arrayList.map((cantrip) => classCantrips[cantrip][1]);
        setClassCantripSpecificImage(asdf);
        setClassCantripSpecificImageTooltip(zxcv);
      } else {
        const keys = [...queryParams.keys()];
        let removeNext = false;

        for (const key of keys) {
          if (key === 'ccs') {
            removeNext = true;
          }
          if (removeNext) {
            queryParams.delete(key);
          }
        }

        const newURL =
          window.location.origin + window.location.pathname + '?' + queryParams.toString();
        window.history.replaceState(null, document.title, newURL);
      }
    }

  }, [location.search]);

  useEffect(() => {
  }, [className, isClassSelected, isSubclassSelected]);

  const classActions = {
    Rage: [classImages.rage, classImages.rage_tooltip],
    Combat_Inspiration: [classImages.combat_inspiration, classImages.combat_inspiration_tooltip],
    'Second Wind': [classImages.second_wind, classImages.second_wind_tooltip],
    'Lay on Hands': [classImages.lay_on_hands, classImages.lay_on_hands_tooltip],
    'Divine Sense': [classImages.divine_sense, classImages.divine_sense_tooltip],
    'Sneak Attack (Melee)': [classImages.sneak_attack_melee, classImages.sneak_attack_melee_tooltip],
    'Sneak Attack (Range)': [classImages.sneak_attack_range, classImages.sneak_attack_range_tooltip],
    'Arcane Recovery': [classImages.arcane_recovery, classImages.arcane_recovery_tooltip],
    'Flurry of Blows': [classImages.flurry_of_blows, classImages.flurry_of_blows_tooltip]
  };

  const subclassActions = {
    'Blessing of the Trickster': [classImages.blessing_of_the_trickster, classImages.blessing_of_the_trickster_tooltip],
    'Healing Radiance': [classImages.healing_radiance, classImages.healing_radiance_tooltip],
    'Holy Rebuke': [classImages.holy_rebuke, classImages.holy_rebuke_tooltip],
    'Spiteful Suffering': [classImages.spiteful_suffering, classImages.spiteful_suffering_tooltip],
    "Inquisitor Might": [classImages.inquisitor_might, classImages.inquisitor_might_tooltip],
    "Fey Presence": [classImages.fey_presence, classImages.fey_presence_tooltip]
  };

  const classCantrips = {
    'Blade Ward': [cantripImages.blade_ward, classImages.blade_ward_tooltip2],
    Friends: [cantripImages.friends, classImages.friends_tooltip2],
    'Dancing Lights': [cantripImages.dancing_lights, classImages.dancing_lights_tooltip2],
    Light: [cantripImages.light, classImages.light_tooltip2],
    'Mage Hand': [cantripImages.mage_hand, classImages.mage_hand_tooltip2],
    'Minor Illusion': [cantripImages.minor_illusion, classImages.minor_illusion_tooltip2],
    'True Strike': [cantripImages.true_strike, classImages.true_strike_tooltip2],
    'Vicious Mockery': [classImages.viciousmockery, classImages.viciousmockery_tooltip],
    'Guidance': [classImages.guidance, classImages.guidance_tooltip],
    'Resistance': [classImages.resistance, classImages.resistance_tooltip],
    'Sacred Flame': [classImages.sacred_flame, classImages.sacred_flame_tooltip],
    'Thaumaturgy': [cantripImages.thaumaturgy, classImages.thaumaturgy_tooltip2],
    'Shillelagh': [classImages.shillelagh, classImages.shillelagh_tooltip],
    'Thorn Whip': [classImages.thorn_whip, classImages.thorn_whip_tooltip],
    'Poison Spray': [cantripImages.poison_spray, classImages.poison_spray_tooltip2],
    'Produce Flame': [cantripImages.produce_flame, cantripImages.produce_flame_tooltip],
    'Acid Splash': [cantripImages.acid_splash, classImages.acid_splash_tooltip2],
    'Fire Bolt': [cantripImages.fire_bolt, classImages.fire_bolt_tooltip2],
    'Ray of Frost': [cantripImages.ray_of_frost, classImages.ray_of_frost_tooltip2],
    'Shocking Grasp': [cantripImages.shocking_grasp, classImages.shocking_grasp_tooltip2],
    'Chill Touch': [cantripImages.chill_touch, classImages.chill_touch_tooltip2],
    'Eldritch Blast': [classImages.eldritch_blast, classImages.eldritch_blast_tooltip]
  };

  const classSpells = {
    'Animal Friendship': [classImages.animal_friendship, classImages.animal_friendship_tooltip],
    Bane: [classImages.bane, classImages.bane_tooltip],
    'Charm Person': [classImages.charm_person, classImages.charm_person_tooltip],
    'Cure Wounds': [classImages.cure_wounds, classImages.cure_wounds_tooltip],
    'Disguise Self': [classImages.disguise_self, classImages.disguise_self_tooltip],
    'Dissonant Whispers': [classImages.dissonant_whispers, classImages.dissonant_whispers_tooltip],
    'Faerie Fire': [classImages.faerie_fire, classImages.faerie_fire_tooltip],
    'Feather Fall': [classImages.feather_fall, classImages.feather_fall_tooltip],
    'Healing Word': [classImages.healing_word, classImages.healing_word_tooltip],
    Heroism: [classImages.heroism, classImages.heroism_tooltip],
    "Tasha's Hideous Laughter": [classImages.tashas_hideous_laughter, classImages.tashas_hideous_laughter_tooltip],
    'Longstrider': [classImages.longstrider, classImages.longstrider_tooltip],
    'Sleep': [classImages.sleep, classImages.sleep_tooltip],
    'Speak with Animals': [classImages.speak_with_animals, classImages.speak_with_animals_tooltip],
    'Thunderwave': [classImages.thunderwave, classImages.thunderwave_tooltip],
    'Protection from Evil and Good': [classImages.protection_from_good_and_evil, classImages.protection_from_good_and_evil_tooltip2],
    "Find Familiar": [classImages.find_familiar, classImages.find_familiar_tooltip],
    'Chromatic Orb': [classImages.chromatic_orb, classImages.chromatic_orb_tooltip],
    'Fog Cloud': [classImages.fog_cloud, classImages.fog_cloud_tooltip],
    'Burning Hands': [classImages.burning_hands, classImages.burning_hands_tooltip],
    'Magic Missile': [classImages.magic_missile, classImages.magic_missile_tooltip],
    'Mage Armour': [classImages.mage_armour, classImages.mage_armour_tooltip],
    'Witch Bolt': [classImages.witch_bolt, classImages.witch_bolt_tooltip],
    'Colour Spray': [classImages.colour_spray, classImages.colour_spray_tooltip],
    'False Life': [classImages.false_life, classImages.false_life_tooltip],
    'Ray of Sickness': [classImages.ray_of_sickness, classImages.ray_of_sickness_tooltip],
    'Expeditious Retreat': [classImages.expeditious_retreat, classImages.expeditious_retreat_tooltip],
    'Jump': [classImages.jump, classImages.jump_tooltip],
    'Grease': [classImages.grease, classImages.grease_tooltip],
    'Armor of Agathys': [classImages.armor_of_agathys, classImages.armor_of_agathys_tooltip],
    'Arms of Hadar(Warlock)': [classImages.arms_of_hadar, classImages.arms_of_hadar_tooltipw],
    'Hellish Rebuke(Warlock)': [classImages.hellish_rebuke, classImages.hellish_rebuke_tooltipw],
    'Hex(Warlock)': [classImages.hex, classImages.hex_tooltipw],
    "Tasha's Hideous Laughter(Warlock)": [classImages.tashas_hideous_laughter, classImages.tashas_hideous_laughter_tooltipw],
    'Dissonant Whispers(Warlock)': [classImages.dissonant_whispers, classImages.dissonant_whispers_tooltipw],
    'Armor of Agathys(Warlock)': [classImages.armor_of_agathys, classImages.armor_of_agathys_tooltipw],
    'Burning Hands(Warlock)': [classImages.armor_of_agathys, classImages.armor_of_agathys_tooltip],
    'Charm Person(Warlock)': [classImages.charm_person, classImages.charm_person_tooltipw],
    'Command(Warlock)': [classImages.command, classImages.command_tooltipw],
    'Expeditious Retreat(Warlock)': [classImages.expeditious_retreat, classImages.expeditious_retreat_tooltipw],
    'Protection from Evil and Good(Warlock)': [classImages.protection_from_good_and_evil, classImages.protection_from_good_and_evil_tooltipw],
    'Witch Bolt(Warlock)': [classImages.witch_bolt, classImages.witch_bolt_tooltipw],
    'Ice Knife': [classImages.ice_knife, classImages.ice_knife_tooltip],
    'Shield': [classImages.shield, classImages.shield_tooltip],
    "Faerie Fire(Warlock)": [classImages.faerie_fire, classImages.faerie_fire_tooltipw],
    "Sleep(Warlock)": [classImages.sleep, classImages.sleep_tooltipw]
  }

  const classPreparedSpells = {
    'Animal Friendship': [classImages.animal_friendship, classImages.animal_friendship_tooltip],
    Bane: [classImages.bane, classImages.bane_tooltip],
    Bless: [classImages.bless, classImages.bless_tooltip],
    Command: [classImages.command, classImages.command_tooltip],
    'Charm Person': [classImages.charm_person, classImages.charm_person_tooltip],
    'Create or Destroy Water': [classImages.create_water, classImages.create_water_tooltip],
    'Cure Wounds': [classImages.cure_wounds, classImages.cure_wounds_tooltip],
    Entangle: [classImages.entangle, classImages.entangle],
    'Faerie Fire': [classImages.faerie_fire, classImages.faerie_fire_tooltip],
    'Fog Cloud': [classImages.fog_cloud, classImages.fog_cloud_tooltip],
    Goodberry: [classImages.goodberry, classImages.goodberry_tooltip],
    'Guiding Bolt': [classImages.guiding_bolt, classImages.guiding_bolt_tooltip],
    'Healing Word': [classImages.healing_word, classImages.healing_word_tooltip],
    'Inflict Wounds': [classImages.inflict_wounds, classImages.inflict_wounds_tooltip],
    Jump: [classImages.jump, classImages.jump_tooltip],
    Longstrider: [classImages.longstrider, classImages.longstrider_tooltip],
    'Protection from Evil and Good': [classImages.protection_from_good_and_evil, classImages.protection_from_good_and_evil_tooltip],
    'Shield of Faith': [classImages.shield_of_faith, classImages.shield_of_faith_tooltip],
    'Speak with Animals': [classImages.speak_with_animals, classImages.speak_with_animals_tooltip],
    'Thunderwave': [classImages.thunderwave, classImages.thunderwave_tooltip],
    'Animal Friendship': [classImages.animal_friendship, classImages.animal_friendship_tooltip],
    Bane: [classImages.bane, classImages.bane_tooltip],
    'Charm Person': [classImages.charm_person, classImages.charm_person_tooltip],
    'Cure Wounds': [classImages.cure_wounds, classImages.cure_wounds_tooltip],
    'Disguise Self': [classImages.disguise_self, classImages.disguise_self_tooltip],
    'Dissonant Whispers': [classImages.dissonant_whispers, classImages.dissonant_whispers_tooltip],
    'Faerie Fire': [classImages.faerie_fire, classImages.faerie_fire_tooltip],
    'Feather Fall': [classImages.feather_fall, classImages.feather_fall_tooltip],
    'Healing Word': [classImages.healing_word, classImages.healing_word_tooltip],
    Heroism: [classImages.heroism, classImages.heroism_tooltip],
    "Tasha's Hideous Laughter": [classImages.tashas_hideous_laughter, classImages.tashas_hideous_laughter_tooltip],
    'Longstrider': [classImages.longstrider, classImages.longstrider_tooltip],
    'Sleep': [classImages.sleep, classImages.sleep_tooltip],
    'Speak with Animals': [classImages.speak_with_animals, classImages.speak_with_animals_tooltip],
    'Thunderwave': [classImages.thunderwave, classImages.thunderwave_tooltip],
    'Protection from Evil and Good': [classImages.protection_from_good_and_evil, classImages.protection_from_good_and_evil_tooltip2],
    "Find Familiar": [classImages.find_familiar, classImages.find_familiar_tooltip],
    'Chromatic Orb': [classImages.chromatic_orb, classImages.chromatic_orb_tooltip],
    'Fog Cloud': [classImages.fog_cloud, classImages.fog_cloud_tooltip],
    'Burning Hands': [classImages.burning_hands, classImages.burning_hands_tooltip],
    'Magic Missile': [classImages.magic_missile, classImages.magic_missile_tooltip],
    'Mage Armour': [classImages.mage_armour, classImages.mage_armour_tooltip],
    'Witch Bolt': [classImages.witch_bolt, classImages.witch_bolt_tooltip],
    'Colour Spray': [classImages.colour_spray, classImages.colour_spray_tooltip],
    'False Life': [classImages.false_life, classImages.false_life_tooltip],
    'Ray of Sickness': [classImages.ray_of_sickness, classImages.ray_of_sickness_tooltip],
    'Expeditious Retreat': [classImages.expeditious_retreat, classImages.expeditious_retreat_tooltip],
    'Jump': [classImages.jump, classImages.jump_tooltip],
    'Grease': [classImages.grease, classImages.grease_tooltip],
    'Armor of Agathys': [classImages.armor_of_agathys, classImages.armor_of_agathys_tooltip],
    'Arms of Hadar(Warlock)': [classImages.arms_of_hadar, classImages.arms_of_hadar_tooltipw],
    'Hellish Rebuke(Warlock)': [classImages.hellish_rebuke, classImages.hellish_rebuke_tooltipw],
    'Hex(Warlock)': [classImages.hex, classImages.hex_tooltipw],
    "Tasha's Hideous Laughter(Warlock)": [classImages.tashas_hideous_laughter, classImages.tashas_hideous_laughter_tooltipw],
    'Dissonant Whispers(Warlock)': [classImages.dissonant_whispers, classImages.dissonant_whispers_tooltipw],
    'Armor of Agathys(Warlock)': [classImages.armor_of_agathys, classImages.armor_of_agathys_tooltipw],
    'Burning Hands(Warlock)': [classImages.armor_of_agathys, classImages.armor_of_agathys_tooltip],
    'Charm Person(Warlock)': [classImages.charm_person, classImages.charm_person_tooltipw],
    'Command(Warlock)': [classImages.command, classImages.command_tooltipw],
    'Expeditious Retreat(Warlock)': [classImages.expeditious_retreat, classImages.expeditious_retreat_tooltipw],
    'Protection from Evil and Good(Warlock)': [classImages.protection_from_good_and_evil, classImages.protection_from_good_and_evil_tooltipw],
    'Witch Bolt(Warlock)': [classImages.witch_bolt, classImages.witch_bolt_tooltipw],
    'Sanctuary': [classImages.sanctuary, classImages.sanctuary_tooltip],
    'Ice Knife': [classImages.ice_knife, classImages.ice_knife_tooltip],
    'Shield': [classImages.shield, classImages.shield_tooltip],
    "Faerie Fire(Warlock)": [classImages.faerie_fire, classImages.faerie_fire_tooltipw],
    "Sleep(Warlock)": [classImages.sleep, classImages.sleep_tooltipw]
  }

  const bardCantrips = [
    { id: 2, name: 'Blade Ward', image: cantripImages.blade_ward, tooltip: classImages.blade_ward_tooltip2 },
    { id: 4, name: 'Dancing Lights', image: cantripImages.dancing_lights, tooltip: classImages.dancing_lights_tooltip2 },
    { id: 6, name: 'Friends', image: cantripImages.friends, tooltip: classImages.friends_tooltip2 },
    { id: 7, name: 'Light', image: cantripImages.light, tooltip: classImages.light_tooltip2 },
    { id: 8, name: 'Mage Hand', image: cantripImages.mage_hand, tooltip: classImages.mage_hand_tooltip2 },
    { id: 9, name: 'Minor Illusion', image: cantripImages.minor_illusion, tooltip: classImages.minor_illusion_tooltip2 },
    { id: 13, name: 'True Strike', image: cantripImages.true_strike, tooltip: classImages.true_strike_tooltip2 },
    { id: 14, name: 'Vicious Mockery', image: classImages.viciousmockery, tooltip: classImages.viciousmockery_tooltip }
  ];

  const bardSpells = [
    { id: 1, name: 'Animal Friendship', image: classImages.animal_friendship, tooltip: classImages.animal_friendship_tooltip },
    { id: 2, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 3, name: 'Charm Person', image: classImages.charm_person, tooltip: classImages.charm_person_tooltip },
    { id: 4, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 5, name: 'Disguise Self', image: classImages.disguise_self, tooltip: classImages.disguise_self_tooltip },
    { id: 6, name: 'Dissonant Whispers', image: classImages.dissonant_whispers, tooltip: classImages.dissonant_whispers_tooltip },
    { id: 7, name: 'Faerie Fire', image: classImages.faerie_fire, tooltip: classImages.faerie_fire_tooltip },
    { id: 8, name: 'Feather Fall', image: classImages.feather_fall, tooltip: classImages.feather_fall_tooltip },
    { id: 9, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 10, name: 'Heroism', image: classImages.heroism, tooltip: classImages.heroism_tooltip },
    { id: 11, name: "Tasha's Hideous Laughter", image: classImages.tashas_hideous_laughter, tooltip: classImages.tashas_hideous_laughter_tooltip },
    { id: 12, name: 'Longstrider', image: classImages.longstrider, tooltip: classImages.longstrider_tooltip },
    { id: 13, name: 'Sleep', image: classImages.sleep, tooltip: classImages.sleep_tooltip },
    { id: 14, name: 'Speak with Animals', image: classImages.speak_with_animals, tooltip: classImages.speak_with_animals_tooltip },
    { id: 15, name: 'Thunderwave', image: classImages.thunderwave, tooltip: classImages.thunderwave_tooltip }
  ]

  const clericLifeCantrips = [
    { id: 1, name: 'Guidance', image: classImages.guidance, tooltip: classImages.guidance_tooltip },
    { id: 2, name: 'Resistance', image: classImages.resistance, tooltip: classImages.resistance_tooltip },
    { id: 3, name: 'Sacred Flame', image: classImages.sacred_flame, tooltip: classImages.sacred_flame_tooltip },
    { id: 4, name: 'Light', image: cantripImages.light, tooltip: classImages.light_tooltip2 },
    { id: 5, name: 'Thaumaturgy', image: cantripImages.thaumaturgy, tooltip: classImages.thaumaturgy_tooltip2 },
    { id: 6, name: 'Blade Ward', image: cantripImages.blade_ward, tooltip: classImages.blade_ward_tooltip2 },
    { id: 7, name: 'Produce Flame', image: cantripImages.produce_flame, tooltip: cantripImages.produce_flame_tooltip },
  ];

  const clericTrickeryCantrips = [
    { id: 1, name: 'Guidance', image: classImages.guidance, tooltip: classImages.guidance_tooltip },
    { id: 2, name: 'Resistance', image: classImages.resistance, tooltip: classImages.resistance_tooltip },
    { id: 3, name: 'Sacred Flame', image: classImages.sacred_flame, tooltip: classImages.sacred_flame_tooltip },
    { id: 4, name: 'Light', image: cantripImages.light, tooltip: classImages.light_tooltip2 },
    { id: 5, name: 'Thaumaturgy', image: cantripImages.thaumaturgy, tooltip: classImages.thaumaturgy_tooltip2 },
    { id: 6, name: 'Blade Ward', image: cantripImages.blade_ward, tooltip: classImages.blade_ward_tooltip2 },
    { id: 7, name: 'Produce Flame', image: cantripImages.produce_flame, tooltip: cantripImages.produce_flame_tooltip },
  ];

  const clericLightCantrips = [
    { id: 1, name: 'Guidance', image: classImages.guidance, tooltip: classImages.guidance_tooltip },
    { id: 2, name: 'Resistance', image: classImages.resistance, tooltip: classImages.resistance_tooltip },
    { id: 3, name: 'Sacred Flame', image: classImages.sacred_flame, tooltip: classImages.sacred_flame_tooltip },
    { id: 5, name: 'Thaumaturgy', image: cantripImages.thaumaturgy, tooltip: classImages.thaumaturgy_tooltip2 },
    { id: 6, name: 'Blade Ward', image: cantripImages.blade_ward, tooltip: classImages.blade_ward_tooltip2 },
    { id: 7, name: 'Produce Flame', image: cantripImages.produce_flame, tooltip: cantripImages.produce_flame_tooltip },
  ];

  const clericLifePreparedSpells = [
    { id: 1, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 2, name: 'Command', image: classImages.command, tooltip: classImages.command_tooltip },
    { id: 3, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 4, name: 'Guiding Bolt', image: classImages.guiding_bolt, tooltip: classImages.guiding_bolt_tooltip },
    { id: 5, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 6, name: 'Inflict Wounds', image: classImages.inflict_wounds, tooltip: classImages.inflict_wounds_tooltip },
    { id: 7, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 8, name: 'Shield of Faith', image: classImages.shield_of_faith, tooltip: classImages.shield_of_faith_tooltip },
    { id: 9, name: 'Sanctuary', image: classImages.sanctuary, tooltip: classImages.sanctuary_tooltip },
  ];

  const clericLifeSpecificSpells = {
    Bless: [classImages.bless, classImages.bless_tooltip],
    'Cure Wounds': [classImages.cure_wounds, classImages.cure_wounds_tooltip]
  }

  const clericLightPreparedSpells = [
    { id: 1, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 2, name: 'Bless', image: classImages.bless, tooltip: classImages.bless_tooltip },
    { id: 3, name: 'Command', image: classImages.command, tooltip: classImages.command_tooltip },
    { id: 4, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 5, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 6, name: 'Guiding Bolt', image: classImages.guiding_bolt, tooltip: classImages.guiding_bolt_tooltip },
    { id: 7, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 8, name: 'Inflict Wounds', image: classImages.inflict_wounds, tooltip: classImages.inflict_wounds_tooltip },
    { id: 9, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 10, name: 'Shield of Faith', image: classImages.shield_of_faith, tooltip: classImages.shield_of_faith_tooltip },
    { id: 11, name: 'Sanctuary', image: classImages.sanctuary, tooltip: classImages.sanctuary_tooltip },
  ];

  const clericLightSpecificSpells = {
    'Burning Hands': [classImages.burning_hands, classImages.burning_hands_tooltip],
    'Faerie Fire': [classImages.faerie_fire, classImages.faerie_fire_tooltip]
  }

  const clericTrickeryPreparedSpells = [
    { id: 1, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 2, name: 'Bless', image: classImages.bless, tooltip: classImages.bless_tooltip },
    { id: 3, name: 'Command', image: classImages.command, tooltip: classImages.command_tooltip },
    { id: 4, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 5, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 6, name: 'Guiding Bolt', image: classImages.guiding_bolt, tooltip: classImages.guiding_bolt_tooltip },
    { id: 7, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 8, name: 'Inflict Wounds', image: classImages.inflict_wounds, tooltip: classImages.inflict_wounds_tooltip },
    { id: 9, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 10, name: 'Shield of Faith', image: classImages.shield_of_faith, tooltip: classImages.shield_of_faith_tooltip },
    { id: 11, name: 'Sanctuary', image: classImages.sanctuary, tooltip: classImages.sanctuary_tooltip },
  ];

  const clericTrickerySpecificSpells = {
    'Charm Person': [classImages.charm_person, classImages.charm_person_tooltip],
    'Disguise Self': [classImages.disguise_self, classImages.disguise_self_tooltip]
  }

  const clericKnowledgePreparedSpells = [
    { id: 1, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 2, name: 'Bless', image: classImages.bless, tooltip: classImages.bless_tooltip },
    { id: 4, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 5, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 6, name: 'Guiding Bolt', image: classImages.guiding_bolt, tooltip: classImages.guiding_bolt_tooltip },
    { id: 7, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 8, name: 'Inflict Wounds', image: classImages.inflict_wounds, tooltip: classImages.inflict_wounds_tooltip },
    { id: 9, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 10, name: 'Shield of Faith', image: classImages.shield_of_faith, tooltip: classImages.shield_of_faith_tooltip },
    { id: 11, name: 'Sanctuary', image: classImages.sanctuary, tooltip: classImages.sanctuary_tooltip },
  ];

  const clericKnowledgeSpecificSpells = {
    'Command': [classImages.command, classImages.command_tooltip],
    'Sleep': [classImages.sleep, classImages.sleep_tooltip]
  }

  const clericNaturePreparedSpells = [
    { id: 1, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 2, name: 'Bless', image: classImages.bless, tooltip: classImages.bless_tooltip },
    { id: 3, name: 'Command', image: classImages.command, tooltip: classImages.command_tooltip },
    { id: 4, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 5, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 6, name: 'Guiding Bolt', image: classImages.guiding_bolt, tooltip: classImages.guiding_bolt_tooltip },
    { id: 7, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 8, name: 'Inflict Wounds', image: classImages.inflict_wounds, tooltip: classImages.inflict_wounds_tooltip },
    { id: 9, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 10, name: 'Shield of Faith', image: classImages.shield_of_faith, tooltip: classImages.shield_of_faith_tooltip },
    { id: 11, name: 'Sanctuary', image: classImages.sanctuary, tooltip: classImages.sanctuary_tooltip },
  ];

  const clericNatureSpecificSpells = {
    'Speak with Animals': [classImages.speak_with_animals, classImages.speak_with_animals_tooltip],
    'Animal Friendship': [classImages.animal_friendship, classImages.animal_friendship_tooltip]
  }

  const clericNatureCantripsSpecific = [
    { id: 2, name: 'Poison Spray', image: cantripImages.poison_spray, tooltip: classImages.poison_spray_tooltip2 },
    { id: 3, name: 'Produce Flame', image: cantripImages.produce_flame, tooltip: cantripImages.produce_flame_tooltip },
    { id: 5, name: 'Shillelagh', image: classImages.shillelagh, tooltip: classImages.shillelagh_tooltip },
    { id: 6, name: 'Thorn Whip', image: classImages.thorn_whip, tooltip: classImages.thorn_whip_tooltip },
  ];

  const clericTempestPreparedSpells = [
    { id: 1, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 2, name: 'Bless', image: classImages.bless, tooltip: classImages.bless_tooltip },
    { id: 3, name: 'Command', image: classImages.command, tooltip: classImages.command_tooltip },
    { id: 4, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 5, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 6, name: 'Guiding Bolt', image: classImages.guiding_bolt, tooltip: classImages.guiding_bolt_tooltip },
    { id: 7, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 8, name: 'Inflict Wounds', image: classImages.inflict_wounds, tooltip: classImages.inflict_wounds_tooltip },
    { id: 9, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 10, name: 'Shield of Faith', image: classImages.shield_of_faith, tooltip: classImages.shield_of_faith_tooltip },
    { id: 11, name: 'Sanctuary', image: classImages.sanctuary, tooltip: classImages.sanctuary_tooltip },
  ];

  const clericTempestSpecificSpells = {
    'Thunderwave': [classImages.thunderwave, classImages.thunderwave_tooltip],
    'Fog Cloud': [classImages.fog_cloud, classImages.fog_cloud_tooltip]
  }

  const clericWarPreparedSpells = [
    { id: 1, name: 'Bane', image: classImages.bane, tooltip: classImages.bane_tooltip },
    { id: 2, name: 'Bless', image: classImages.bless, tooltip: classImages.bless_tooltip },
    { id: 3, name: 'Command', image: classImages.command, tooltip: classImages.command_tooltip },
    { id: 4, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 5, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 6, name: 'Guiding Bolt', image: classImages.guiding_bolt, tooltip: classImages.guiding_bolt_tooltip },
    { id: 7, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 8, name: 'Inflict Wounds', image: classImages.inflict_wounds, tooltip: classImages.inflict_wounds_tooltip },
    { id: 9, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 11, name: 'Sanctuary', image: classImages.sanctuary, tooltip: classImages.sanctuary_tooltip },
  ];

  const clericWarSpecificSpells = {
    'Divine Favour': [classImages.divine_favour, classImages.divine_favour_tooltip],
    'Shield of Faith': [classImages.shield_of_faith, classImages.shield_of_faith_tooltip]
  }

  const druidCantrips = [
    { id: 1, name: 'Guidance', image: classImages.guidance, tooltip: classImages.guidance_tooltip },
    { id: 2, name: 'Poison Spray', image: cantripImages.poison_spray, tooltip: classImages.poison_spray_tooltip2 },
    { id: 3, name: 'Produce Flame', image: cantripImages.produce_flame, tooltip: cantripImages.produce_flame_tooltip },
    { id: 4, name: 'Resistance', image: classImages.resistance, tooltip: classImages.resistance_tooltip },
    { id: 5, name: 'Shillelagh', image: classImages.shillelagh, tooltip: classImages.shillelagh_tooltip },
    { id: 6, name: 'Thorn Whip', image: classImages.thorn_whip, tooltip: classImages.thorn_whip_tooltip },
  ];

  const druidPreparedSpells = [
    { id: 1, name: 'Animal Friendship', image: classImages.animal_friendship, tooltip: classImages.animal_friendship_tooltip },
    { id: 2, name: 'Charm Person', image: classImages.charm_person, tooltip: classImages.charm_person_tooltip },
    { id: 3, name: 'Cure Wounds', image: classImages.cure_wounds, tooltip: classImages.cure_wounds_tooltip },
    { id: 4, name: 'Create or Destroy Water', image: classImages.create_water, tooltip: classImages.create_water_tooltip },
    { id: 5, name: 'Entangle', image: classImages.entangle, tooltip: classImages.entangle_tooltip },
    { id: 6, name: 'Faerie Fire', image: classImages.faerie_fire, tooltip: classImages.faerie_fire_tooltip },
    { id: 7, name: 'Fog Cloud', image: classImages.fog_cloud, tooltip: classImages.fog_cloud_tooltip },
    { id: 8, name: 'Goodberry', image: classImages.goodberry, tooltip: classImages.goodberry_tooltip },
    { id: 9, name: 'Healing Word', image: classImages.healing_word, tooltip: classImages.healing_word_tooltip },
    { id: 10, name: 'Jump', image: classImages.jump, tooltip: classImages.jump_tooltip },
    { id: 11, name: 'Longstrider', image: classImages.longstrider, tooltip: classImages.longstrider_tooltip },
    { id: 12, name: 'Speak with Animals', image: classImages.speak_with_animals, tooltip: cantripImages.speak_with_animals_tooltip2 },
    { id: 13, name: 'Thunderwave', image: classImages.thunderwave, tooltip: classImages.thunderwave_tooltip },
    { id: 14, name: 'Ice Knife', image: classImages.ice_knife, tooltip: classImages.ice_knife_tooltip },
  ];

  const paladinAncientsSpecificActions = {
    'Lay on Hands': [classImages.lay_on_hands, classImages.lay_on_hands_tooltip],
    'Divine Sense': [classImages.divine_sense, classImages.divine_sense_tooltip]
  }

  const paladinDevotionSpecificActions = {
    'Lay on Hands': [classImages.lay_on_hands, classImages.lay_on_hands_tooltip],
    'Divine Sense': [classImages.divine_sense, classImages.divine_sense_tooltip]
  }

  const paladinVengeanceSpecificActions = {
    'Lay on Hands': [classImages.lay_on_hands, classImages.lay_on_hands_tooltip],
    'Divine Sense': [classImages.divine_sense, classImages.divine_sense_tooltip]
  }

  const paladinSubclassActions = {
    'Healing Radiance': [classImages.healing_radiance, classImages.healing_radiance_tooltip],
    'Holy Rebuke': [classImages.holy_rebuke, classImages.holy_rebuke_tooltip],
    'Spiteful Suffering': [classImages.spiteful_suffering, classImages.spiteful_suffering_tooltip],
    "Inquisitor Might": [classImages.inquisitor_might, classImages.inquisitor_might_tooltip]
  }

  const rangerCantripsSpells = {
    'Beast Tamer': [classImages.find_familiar, classImages.find_familiar_tooltip],
    'Keeper of the Veil': [classImages.protection_from_good_and_evil, classImages.protection_from_good_and_evil_tooltip2],
    'Mage Breaker': [cantripImages.true_strike, classImages.true_strike_tooltip2],
    'Sanctified Stalker': [classImages.sacred_flame, classImages.sacred_flame_tooltip]
  }

  const sorcererCantrips = [
    { id: 1, name: 'Acid Splash', image: cantripImages.acid_splash, tooltip: classImages.acid_splash_tooltip2 },
    { id: 2, name: 'Blade Ward', image: cantripImages.blade_ward, tooltip: classImages.blade_ward_tooltip2 },
    { id: 3, name: 'Poison Spray', image: cantripImages.poison_spray, tooltip: classImages.poison_spray_tooltip2 },
    { id: 4, name: 'Dancing Lights', image: cantripImages.dancing_lights, tooltip: classImages.dancing_lights_tooltip2 },
    { id: 6, name: 'Friends', image: cantripImages.friends, tooltip: classImages.friends_tooltip2 },
    { id: 7, name: 'Light', image: cantripImages.light, tooltip: classImages.light_tooltip2 },
    { id: 8, name: 'Mage Hand', image: cantripImages.mage_hand, tooltip: classImages.mage_hand_tooltip2 },
    { id: 9, name: 'Minor Illusion', image: cantripImages.minor_illusion, tooltip: classImages.minor_illusion_tooltip2 },
    { id: 10, name: 'Fire Bolt', image: cantripImages.fire_bolt, tooltip: classImages.fire_bolt_tooltip2 },
    { id: 11, name: 'Ray of Frost', image: cantripImages.ray_of_frost, tooltip: classImages.ray_of_frost_tooltip2 },
    { id: 12, name: 'Shocking Grasp', image: cantripImages.shocking_grasp, tooltip: classImages.shocking_grasp_tooltip2 },
    { id: 13, name: 'True Strike', image: cantripImages.true_strike, tooltip: classImages.true_strike_tooltip2 },
    { id: 14, name: 'Chill Touch', image: cantripImages.chill_touch, tooltip: classImages.chill_touch_tooltip2 }
  ];

  const sorcererSpells = [
    { id: 1, name: 'Chromatic Orb', image: classImages.chromatic_orb, tooltip: classImages.chromatic_orb_tooltip },
    { id: 2, name: 'Fog Cloud', image: classImages.fog_cloud, tooltip: classImages.fog_cloud_tooltip },
    { id: 3, name: 'Charm Person', image: classImages.charm_person, tooltip: classImages.charm_person_tooltip },
    { id: 4, name: 'Sleep', image: classImages.sleep, tooltip: classImages.sleep_tooltip },
    { id: 5, name: 'Burning Hands', image: classImages.burning_hands, tooltip: classImages.burning_hands_tooltip },
    { id: 6, name: 'Magic Missile', image: classImages.magic_missile, tooltip: classImages.magic_missile_tooltip },
    { id: 7, name: 'Mage Armour', image: classImages.mage_armour, tooltip: classImages.mage_armour_tooltip },
    { id: 8, name: 'Thunderwave', image: classImages.thunderwave, tooltip: classImages.thunderwave_tooltip },
    { id: 9, name: 'Witch Bolt', image: classImages.witch_bolt, tooltip: classImages.witch_bolt_tooltip },
    { id: 10, name: 'Colour Spray', image: classImages.colour_spray, tooltip: classImages.colour_spray_tooltip },
    { id: 11, name: 'Disguise Self', image: classImages.disguise_self, tooltip: classImages.disguise_self_tooltip },
    { id: 12, name: 'False Life', image: classImages.false_life, tooltip: classImages.false_life_tooltip },
    { id: 13, name: 'Ray of Sickness', image: classImages.ray_of_sickness, tooltip: classImages.ray_of_sickness_tooltip },
    { id: 14, name: 'Expeditious Retreat', image: classImages.expeditious_retreat, tooltip: classImages.expeditious_retreat_tooltip },
    { id: 15, name: 'Feather Fall', image: classImages.feather_fall, tooltip: classImages.feather_fall_tooltip },
    { id: 16, name: 'Jump', image: classImages.jump, tooltip: classImages.jump_tooltip },
    { id: 16, name: 'Ice Knife', image: classImages.ice_knife, tooltip: classImages.ice_knife_tooltip },
    { id: 16, name: 'Shield', image: classImages.shield, tooltip: classImages.shield_tooltip }
  ];

  const sorcererBloodlinesImages = {
    'Burning Hands': [classImages.burning_hands, classImages.burning_hands_tooltip],
    'Grease': [classImages.grease, classImages.grease_tooltip],
    'Witch Bolt': [classImages.witch_bolt, classImages.witch_bolt_tooltip],
    'Armor of Agathys': [classImages.armor_of_agathys, classImages.armor_of_agathys_tooltip],
    'Ray of Sickness': [classImages.ray_of_sickness, classImages.ray_of_sickness_tooltip],
    'Disguise Self': [classImages.disguise_self, classImages.disguise_self_tooltip],
    'Feather Fall': [classImages.feather_fall, classImages.feather_fall_tooltip],
    'Fog Cloud': [classImages.fog_cloud, classImages.fog_cloud_tooltip],
    "Tasha's Hideous Laughter": [classImages.tashas_hideous_laughter, classImages.tashas_hideous_laughter_tooltip],
    'Sleep': [classImages.sleep, classImages.sleep_tooltip]
  }

  const warlockCantrips = [
    { id: 2, name: 'Blade Ward', image: cantripImages.blade_ward, tooltip: classImages.blade_ward_tooltip2 },
    { id: 3, name: 'Poison Spray', image: cantripImages.poison_spray, tooltip: classImages.poison_spray_tooltip2 },
    { id: 6, name: 'Friends', image: cantripImages.friends, tooltip: classImages.friends_tooltip2 },
    { id: 8, name: 'Mage Hand', image: cantripImages.mage_hand, tooltip: classImages.mage_hand_tooltip2 },
    { id: 9, name: 'Minor Illusion', image: cantripImages.minor_illusion, tooltip: classImages.minor_illusion_tooltip2 },
    { id: 12, name: 'Eldritch Blast', image: classImages.eldritch_blast, tooltip: classImages.eldritch_blast_tooltip },
    { id: 13, name: 'True Strike', image: cantripImages.true_strike, tooltip: classImages.true_strike_tooltip2 },
    { id: 14, name: 'Chill Touch', image: cantripImages.chill_touch, tooltip: classImages.chill_touch_tooltip2 }
  ];

  const warlockFiendSpells = [
    { id: 1, name: 'Armor of Agathys(Warlock)', image: classImages.armor_of_agathys, tooltip: classImages.armor_of_agathys_tooltipw },
    { id: 2, name: 'Arms of Hadar(Warlock)', image: classImages.arms_of_hadar, tooltip: classImages.arms_of_hadar_tooltipw },
    { id: 3, name: 'Burning Hands(Warlock)', image: classImages.burning_hands, tooltip: classImages.burning_hands_tooltipw },
    { id: 4, name: 'Charm Person(Warlock)', image: classImages.charm_person, tooltip: classImages.charm_person_tooltipw },
    { id: 6, name: 'Command(Warlock)', image: classImages.command, tooltip: classImages.command_tooltipw },
    { id: 7, name: 'Expeditious Retreat(Warlock)', image: classImages.expeditious_retreat, tooltip: classImages.expeditious_retreat_tooltipw },
    { id: 8, name: 'Hellish Rebuke(Warlock)', image: classImages.hellish_rebuke, tooltip: classImages.hellish_rebuke_tooltipw },
    { id: 9, name: 'Hex(Warlock)', image: classImages.hex, tooltip: classImages.hex_tooltipw },
    { id: 10, name: 'Protection from Evil and Good(Warlock)', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltipw },
    { id: 11, name: 'Witch Bolt(Warlock)', image: classImages.witch_bolt, tooltip: classImages.witch_bolt_tooltipw },
  ];

  const warlockGreatSpells = [
    { id: 1, name: 'Armor of Agathys(Warlock)', image: classImages.armor_of_agathys, tooltip: classImages.armor_of_agathys_tooltipw },
    { id: 2, name: 'Arms of Hadar(Warlock)', image: classImages.arms_of_hadar, tooltip: classImages.arms_of_hadar_tooltipw },
    { id: 3, name: 'Dissonant Whispers(Warlock)', image: classImages.dissonant_whispers, tooltip: classImages.dissonant_whispers_tooltipw },
    { id: 4, name: 'Charm Person(Warlock)', image: classImages.charm_person, tooltip: classImages.charm_person_tooltipw },
    { id: 6, name: "Tasha's Hideous Laughter(Warlock)", image: classImages.tashas_hideous_laughter, tooltip: classImages.tashas_hideous_laughter_tooltipw },
    { id: 7, name: 'Expeditious Retreat(Warlock)', image: classImages.expeditious_retreat, tooltip: classImages.expeditious_retreat_tooltipw },
    { id: 8, name: 'Hellish Rebuke(Warlock)', image: classImages.hellish_rebuke, tooltip: classImages.hellish_rebuke_tooltipw },
    { id: 9, name: 'Hex(Warlock)', image: classImages.hex, tooltip: classImages.hex_tooltipw },
    { id: 10, name: 'Protection from Evil and Good(Warlock)', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltipw },
    { id: 11, name: 'Witch Bolt(Warlock)', image: classImages.witch_bolt, tooltip: classImages.witch_bolt_tooltipw },
  ];

  const warlockFeySpells = [
    { id: 1, name: 'Armor of Agathys(Warlock)', image: classImages.armor_of_agathys, tooltip: classImages.armor_of_agathys_tooltipw },
    { id: 2, name: 'Arms of Hadar(Warlock)', image: classImages.arms_of_hadar, tooltip: classImages.arms_of_hadar_tooltipw },
    { id: 3, name: 'Faerie Fire(Warlock)', image: classImages.faerie_fire, tooltip: classImages.faerie_fire_tooltipw },
    { id: 4, name: 'Charm Person(Warlock)', image: classImages.charm_person, tooltip: classImages.charm_person_tooltipw },
    { id: 6, name: 'Sleep(Warlock)', image: classImages.sleep, tooltip: classImages.sleep_tooltipw },
    { id: 7, name: 'Expeditious Retreat(Warlock)', image: classImages.expeditious_retreat, tooltip: classImages.expeditious_retreat_tooltipw },
    { id: 8, name: 'Hellish Rebuke(Warlock)', image: classImages.hellish_rebuke, tooltip: classImages.hellish_rebuke_tooltipw },
    { id: 9, name: 'Hex(Warlock)', image: classImages.hex, tooltip: classImages.hex_tooltipw },
    { id: 10, name: 'Protection from Evil and Good(Warlock)', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltipw },
    { id: 11, name: 'Witch Bolt(Warlock)', image: classImages.witch_bolt, tooltip: classImages.witch_bolt_tooltipw },
  ];

  const wizardSpells = [
    { id: 1, name: 'Chromatic Orb', image: classImages.chromatic_orb, tooltip: classImages.chromatic_orb_tooltip },
    { id: 2, name: 'Fog Cloud', image: classImages.fog_cloud, tooltip: classImages.fog_cloud_tooltip },
    { id: 3, name: 'Charm Person', image: classImages.charm_person, tooltip: classImages.charm_person_tooltip },
    { id: 4, name: 'Sleep', image: classImages.sleep, tooltip: classImages.sleep_tooltip },
    { id: 6, name: 'Burning Hands', image: classImages.burning_hands, tooltip: classImages.burning_hands_tooltip },
    { id: 7, name: 'Magic Missile', image: classImages.magic_missile, tooltip: classImages.magic_missile_tooltip },
    { id: 8, name: 'Mage Armour', image: classImages.mage_armour, tooltip: classImages.mage_armour_tooltip },
    { id: 9, name: 'Thunderwave', image: classImages.thunderwave, tooltip: classImages.thunderwave_tooltip },
    { id: 10, name: 'Witch Bolt', image: classImages.witch_bolt, tooltip: classImages.witch_bolt_tooltip },
    { id: 11, name: 'Colour Spray', image: classImages.colour_spray, tooltip: classImages.colour_spray_tooltip },
    { id: 12, name: 'Disguise Self', image: classImages.disguise_self, tooltip: classImages.disguise_self_tooltip },
    { id: 13, name: 'False Life', image: classImages.false_life, tooltip: classImages.false_life_tooltip },
    { id: 14, name: 'Ray of Sickness', image: classImages.ray_of_sickness, tooltip: classImages.ray_of_sickness_tooltip },
    { id: 15, name: 'Expeditious Retreat', image: classImages.expeditious_retreat, tooltip: classImages.expeditious_retreat_tooltip },
    { id: 16, name: 'Feather Fall', image: classImages.feather_fall, tooltip: classImages.feather_fall_tooltip },
    { id: 17, name: 'Jump', image: classImages.jump, tooltip: classImages.jump_tooltip },
    { id: 18, name: 'Find Familiar', image: classImages.find_familiar, tooltip: classImages.find_familiar_tooltip2 },
    { id: 19, name: 'Grease', image: classImages.grease, tooltip: classImages.grease_tooltip },
    { id: 20, name: "Tasha's Hideous Laughter", image: classImages.tashas_hideous_laughter, tooltip: classImages.tashas_hideous_laughter_tooltip },
    { id: 21, name: 'Longstrider', image: classImages.longstrider, tooltip: classImages.longstrider_tooltip },
    { id: 22, name: 'Protection from Evil and Good', image: classImages.protection_from_good_and_evil, tooltip: classImages.protection_from_good_and_evil_tooltip },
    { id: 23, name: 'Shield', image: classImages.shield, tooltip: classImages.shield_tooltip },
    { id: 24, name: 'Ice Knife', image: classImages.ice_knife, tooltip: classImages.ice_knife_tooltip },
  ];


  const classButtonStyle = {
    background: '#8B4513',
    color: '#FDF5E6', // Light beige color that works well on the dark background
    padding: '10px 5px', // Smaller padding
    border: '2px solid #CD853F',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: '16px', // Smaller font size
    cursor: 'pointer',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
    fontFamily: generalFont, // Medieval-like font
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    width: "110px",
    height: "90px"
  };

  const highlightedClassButtonStyle = {
    ...classButtonStyle,
    background: '#CD853F', // Change the background color for the highlighted button
    color: 'white', // Change the text color for the highlighted button
  };

  const subclassButtonStyle = {
    background: '#8B4513',
    color: '#FDF5E6', // Light beige color that works well on the dark background
    padding: '10px 5px', // Smaller padding
    border: '2px solid #CD853F',
    borderRadius: '10px',
    fontSize: '16px', // Smaller font size
    cursor: 'pointer',
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
    fontFamily: generalFont, // Medieval-like font
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    width: "110px",
    height: "60px",
    marginLeft: "40px",
  };

  const highlightedSubclassButtonStyle = {
    ...subclassButtonStyle,
    background: '#CD853F', // Change the background color for the highlighted button
    color: 'white', // Change the text color for the highlighted button
  };

  const cantripModalContentMultiple = (isCantripModalOpen, handleCloseCantripModal, raceCantrips, handleCantripButtonClick, clickedCantripButtons, numberOfCantrips) => (
    <div>
      <Modal isOpen={isCantripModalOpen} onRequestClose={handleCloseCantripModal} style={raceModalStyle}>
        <h2 style={{ color: 'white' }}>Select {numberOfCantrips} cantrips:</h2>
        <div className="button-container" style={buttonContainerStyle}>
          {raceCantrips.map((button) => {
            const isButtonClicked = clickedCantripButtons.includes(button.name);
            return (
              <div key={button.id}>
                <div
                  className={`cantrip-button-wrapper ${isButtonClicked ? 'clicked' : ''}`}
                  onClick={() => handleCantripButtonClick(button.name, numberOfCantrips, button.image, button.tooltip)}
                >
                  <button
                    style={{
                      background: 'transparent',
                      border: 'none',
                      padding: 0,
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '50px',
                    }}
                  >
                    <img
                      src={button.image}
                      alt={button.name}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
                  </button>
                  <div className="tooltip-content" id={`tooltip-${button.id}`}>
                    <img src={button.tooltip} alt={button.name} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="close-race-button-container">
          <button onClick={handleCloseCantripModal} style={raceButtonStyle}>
            Close cantrip selection
          </button>
          {clickedCantripButtons.length >= numberOfCantrips ? (
            <button onClick={handleCloseCantripModalAccept} style={{ ...raceButtonStyle, marginLeft: "30px" }}>
              Accept
            </button>
          ) : null}
        </div>
      </Modal>
    </div>
  );

  const cantripModalContentMultipleWizard = (isCantripModalOpen, handleCloseCantripModal, raceCantrips, handleCantripButtonClick, clickedCantripButtons, numberOfCantrips) => {
    const queryParams = new URLSearchParams(window.location.search);
    let raceCantripSelected = null;
    if (queryParams.has("rc")) {
      raceCantripSelected = queryParams.get("rc");
    }

    const modifiedRaceCantrips = raceCantripSelected === null
      ? raceCantrips
      : raceCantrips.filter((spell) => spell.name !== raceCantripSelected);

    return (
      <div>
        <Modal isOpen={isCantripModalOpen} onRequestClose={handleCloseCantripModal} style={raceModalStyle}>
          <h2 style={{ color: 'white' }}>Select {numberOfCantrips} cantrips:</h2>
          <div className="button-container" style={buttonContainerStyle}>
            {modifiedRaceCantrips.map((button) => {
              const isButtonClicked = clickedCantripButtons.includes(button.name);
              return (
                <div key={button.id}>
                  <div
                    className={`cantrip-button-wrapper ${isButtonClicked ? 'clicked' : ''}`}
                    onClick={() => handleCantripButtonClick(button.name, numberOfCantrips, button.image, button.tooltip)}
                  >
                    <button
                      style={{
                        background: 'transparent',
                        border: 'none',
                        padding: 0,
                        width: '150px',
                        height: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px',
                      }}
                    >
                      <img
                        src={button.image}
                        alt={button.name}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                      <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
                    </button>
                    <div className="tooltip-content" id={`tooltip-${button.id}`}>
                      <img src={button.tooltip} alt={button.name} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="close-race-button-container">
            <button onClick={handleCloseCantripModal} style={raceButtonStyle}>
              Close cantrip selection
            </button>
            {clickedCantripButtons.length >= numberOfCantrips ? (
              <button onClick={handleCloseCantripModalAccept} style={{ ...raceButtonStyle, marginLeft: "30px" }}>
                Accept
              </button>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  };

  const spellModalContentMultiple = (isSpellModalOpen, handleCloseSpellModal, classSpells, handleSpellButtonClick, clickedSpellButtons, numberOfSpells) => (
    <div>
      <Modal isOpen={isSpellModalOpen} onRequestClose={handleCloseSpellModal} style={raceModalStyle}>
        <h2 style={{ color: 'white' }}>Select {numberOfSpells} spells:</h2>
        <div className="button-container" style={buttonContainerStyle}>
          {classSpells.map((button) => {
            const isButtonClicked = clickedSpellButtons.includes(button.name);
            return (
              <div key={button.id}>
                <div
                  className={`cantrip-button-wrapper ${isButtonClicked ? 'clicked' : ''}`}
                  onClick={() => handleSpellButtonClick(button.name, numberOfSpells, button.image, button.tooltip)}
                >
                  <button
                    style={{
                      background: 'transparent',
                      border: 'none',
                      padding: 0,
                      width: '150px',
                      height: '150px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '50px',
                    }}
                  >
                    <img
                      src={button.image}
                      alt={button.name}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
                  </button>
                  <div className="tooltip-content" id={`tooltip-${button.id}`}>
                    <img src={button.tooltip} alt={button.name} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="close-race-button-container">
          <button onClick={handleCloseSpellModal} style={raceButtonStyle}>
            Close spells selection
          </button>
          {clickedSpellButtons.length >= numberOfSpells ? (
            <button onClick={handleCloseSpellModalAccept} style={{ ...raceButtonStyle, marginLeft: "30px" }}>
              Accept
            </button>
          ) : null}
        </div>
      </Modal>
    </div>
  );

  const spellModalContentMultipleSorcerer = (isSpellModalOpen, handleCloseSpellModal, classSpells, handleSpellButtonClick, clickedSpellButtons, numberOfSpells, selectedBloodline) => {
    // Create a modified version of classSpells by excluding the spell based on selectedBloodline
    const spellToExclude = sorcererBloodlines[sorcererBloodlines.indexOf(selectedBloodline) + 1];
    const modifiedClassSpells = classSpells.filter((spell) => spell.name !== spellToExclude);

    return (
      <div>
        <Modal isOpen={isSpellModalOpen} onRequestClose={handleCloseSpellModal} style={raceModalStyle}>
          <h2 style={{ color: 'white' }}>Select {numberOfSpells} spells:</h2>
          <div className="button-container" style={buttonContainerStyle}>
            {modifiedClassSpells.map((button) => {
              const isButtonClicked = clickedSpellButtons.includes(button.name);
              return (
                <div key={button.id}>
                  <div
                    className={`cantrip-button-wrapper ${isButtonClicked ? 'clicked' : ''}`}
                    onClick={() => handleSpellButtonClick(button.name, numberOfSpells, button.image, button.tooltip)}
                  >
                    <button
                      style={{
                        background: 'transparent',
                        border: 'none',
                        padding: 0,
                        width: '150px',
                        height: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px',
                      }}
                    >
                      <img
                        src={button.image}
                        alt={button.name}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                      <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
                    </button>
                    <div className="tooltip-content" id={`tooltip-${button.id}`}>
                      <img src={button.tooltip} alt={button.name} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="close-race-button-container">
            <button onClick={handleCloseSpellModal} style={raceButtonStyle}>
              Close spells selection
            </button>
            {clickedSpellButtons.length >= numberOfSpells ? (
              <button onClick={handleCloseSpellModalAccept} style={{ ...raceButtonStyle, marginLeft: "30px" }}>
                Accept
              </button>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  };

  const preparedSpellModalContentMultiple = (isPreparedSpellModalOpen, handleClosePreparedSpellModal, classPreparedSpells, handlePreparedSpellButtonClick, clickedPreparedSpellButtons, numberOfPreparedSpells) => {
    // Create a modified version of classSpells by excluding the spell based on selectedBloodline
    const queryParams = new URLSearchParams(window.location.search);
    let raceSpellsSelected = null;
    if (queryParams.get("c") === "Wizard" && queryParams.has("cs")) {
      raceSpellsSelected = queryParams.get("cs").split(",");
    }

    const modifiedPreparedSpells = raceSpellsSelected === null
      ? classPreparedSpells
      : classPreparedSpells.filter((spell) => raceSpellsSelected.includes(spell.name));


    return (
      <div>
        <Modal isOpen={isPreparedSpellModalOpen} onRequestClose={handleClosePreparedSpellModal} style={raceModalStyle}>
          <h2 style={{ color: 'white' }}>Select {numberOfPreparedSpells} prepared spells (can be changed when resting):</h2>
          <div className="button-container" style={buttonContainerStyle}>
            {modifiedPreparedSpells.map((button) => {
              const isButtonClicked = clickedPreparedSpellButtons.includes(button.name);
              return (
                <div key={button.id}>
                  <div
                    className={`cantrip-button-wrapper ${isButtonClicked ? 'clicked' : ''}`}
                    onClick={() => handlePreparedSpellButtonClick(button.name, numberOfPreparedSpells, button.image, button.tooltip)}
                  >
                    <button
                      style={{
                        background: 'transparent',
                        border: 'none',
                        padding: 0,
                        width: '150px',
                        height: '150px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px',
                      }}
                    >
                      <img
                        src={button.image}
                        alt={button.name}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                      <span style={{ marginTop: '5px', color: 'white', fontSize: '20px' }}>{button.name}</span>
                    </button>
                    <div className="tooltip-content" id={`tooltip-${button.id}`}>
                      <img src={button.tooltip} alt={button.name} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="close-race-button-container">
            <button onClick={handleClosePreparedSpellModal} style={raceButtonStyle}>
              Close prepared spells selection
            </button>
            {clickedPreparedSpellButtons.length >= numberOfPreparedSpells ? (
              <button onClick={handleClosePreparedSpellModalAccept} style={{ ...raceButtonStyle, marginLeft: "30px" }}>
                Accept
              </button>
            ) : null}
          </div>
        </Modal>
      </div>
    );
  };

  const ClassesFrame = (
    isBackgroundSelected === true && (
      <div style={frameStyle2}>
        <div style={{ alignItems: 'center' }}>
          {/* "Background" text box */}
          <div
            style={{
              width: '140px', // Adjust the width as needed
              margin: '0 auto', // Center the text horizontally
              fontFamily: generalFont, // Medieval-like font
              marginTop: "20px",
              marginBottom: "20px",
              background: '#444444',
              color: '#FDF5E6',
              padding: '35px 35px', // Smaller padding
              border: '2px solid #CD853F',
              borderRadius: '50px',
              fontWeight: 'bold',
              fontSize: '24px', // Smaller font size
              boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            }}
          >
            Class
          </div>

          {/* Buttons container */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)', // 4 columns
              gap: '10px', // Gap between buttons
            }}
          >
            {/* First row of buttons */}
            <button style={selectedClass === 'Barbarian' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Barbarian')}>
              Barbarian
            </button>
            <button style={selectedClass === 'Bard' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Bard')}>
              Bard
            </button>
            <button style={selectedClass === 'Cleric' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Cleric')}>
              Cleric
            </button>
            <button style={selectedClass === 'Druid' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Druid')}>
              Druid
            </button>

            {/* Second row of buttons */}
            <button style={selectedClass === 'Fighter' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Fighter')}>
              Fighter
            </button>
            <button style={selectedClass === 'Monk' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Monk')}>
              Monk
            </button>
            <button style={selectedClass === 'Paladin' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Paladin')}>
              Paladin
            </button>
            <button style={selectedClass === 'Ranger' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Ranger')}>
              Ranger
            </button>

            {/* Third row of buttons */}
            <button style={selectedClass === 'Rogue' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Rogue')}>
              Rogue
            </button>
            <button style={selectedClass === 'Sorcerer' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Sorcerer')}>
              Sorcerer
            </button>
            <button style={selectedClass === 'Warlock' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Warlock')}>
              Warlock
            </button>
            <button style={selectedClass === 'Wizard' ? highlightedClassButtonStyle : classButtonStyle} onClick={() => handleClassButtonClick('Wizard')}>
              Wizard
            </button>
          </div>
        </div>
        <div style={{ marginLeft: '50px', color: 'white' }}>
          {(isBackgroundSelected && isClassSelected) && (
            <>
              <h3>
                <span style={{ color: '#FFBF00' }}>Description:</span> <span style={{ fontSize: "17px" }}>{classData[className].Description}</span>
              </h3>
              <h3 style={{ color: '#FFBF00' }}>Features:</h3>
              <ul>
                {Object.entries(classData[className].Features).map(([key, value]) => (
                  <li key={key}>
                    <span style={{ color: '#D2B48C' }}>{key}: </span>
                    {typeof value === 'object' ? Object.values(value).join(', ') : value}
                  </li>
                ))}
              </ul>
            </>
          )}
          {(isClassSelected && className == "Barbarian") && (
            <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "10px" }}>Class Action:</span>
              <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                <img
                  src={classActions["Rage"][0]}
                  style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                  alt="Cantrip Image"
                />
                <div className="tooltip-content">
                  <img src={classActions["Rage"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                </div>
              </div>
            </div>
          )}

          {(isClassSelected && className === "Bard") && (
            <div className="instrument-dropdown-container">
              <label htmlFor="instrumentDropdown">Select an Instrument:</label>
              <select style={dropdownStyle} id="instrumentDropdown" value={selectedInstrument} onChange={handleInstrumentChange}>
                <option value="">Select an option</option>
                <option value="Hand Drum">Hand Drum</option>
                <option value="Flute">Flute</option>
                <option value="Lute">Lute</option>
                <option value="Lyre">Lyre</option>
                <option value="Violin">Violin</option>
              </select>
              <div style={{ font: generalFont, fontSize: "18px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Class Action:</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={classActions["Combat_Inspiration"][0]}
                    style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={classActions["Combat_Inspiration"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
              {selectedClass === 'Bard' && (
                <button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Cantrips
                </button>
              )}
              {cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, bardCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 2)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {selectedClass === 'Bard' && (
                <button
                  onClick={handleOpenClassSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 4 Spells
                </button>
              )}
              {spellModalContentMultiple(isClassSpellModalOpen, handleCloseClassSpellModal, bardSpells, handleClassSpellButtonsClick, clickedSpellButtons, 4)}
              {isClassSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {(isClassSelected && className === "Cleric") && (
            <div className="instrument-dropdown-container">
              <label htmlFor="instrumentDropdown">Select a Deity:</label>
              <select style={dropdownStyle} id="instrumentDropdown" value={selectedDeity} onChange={handleDeityChange}>
                <option value="">Select an option</option>
                {deityNames.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
              {selectedDeityDescription && (
                <div style={{ border: '2px solid #ccc', padding: '5px', borderRadius: '5px', backgroundColor: 'black', font: generalFont }}>
                  <p>{selectedDeityDescription}</p>
                </div>
              )}

              {selectedClass === 'Cleric' && (
                <div><button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 3 Cantrips
                </button></div>
              )}
              {selectedSubclass === "Life Domain" && cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, clericLifeCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {selectedSubclass === "Trickery Domain" && cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, clericTrickeryCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {selectedSubclass === "Light Domain" && cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, clericLightCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {selectedSubclass === "Knowledge Domain" && cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, clericLifeCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {selectedSubclass === "Nature Domain" && cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, clericLifeCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {selectedSubclass === "Tempest Domain" && cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, clericLifeCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {selectedSubclass === "War Domain" && cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, clericLifeCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedClass === 'Cleric' && (
                <button
                  onClick={handleOpenClassPreparedSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 4 Prepared Spells (can be changed when resting)
                </button>
              )}

              {selectedSubclass === "Life Domain" && preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, clericLifePreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {selectedSubclass === "Trickery Domain" && preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, clericTrickeryPreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {selectedSubclass === "Light Domain" && preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, clericLightPreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {selectedSubclass === "Knowledge Domain" && preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, clericKnowledgePreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {selectedSubclass === "Nature Domain" && preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, clericNaturePreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {selectedSubclass === "Tempest Domain" && preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, clericTempestPreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {selectedSubclass === "War Domain" && preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, clericWarPreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {isClassPreparedSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classPreparedSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classPreparedSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {(isClassSelected && className === "Druid") && (
            <div className="instrument-dropdown-container">
              {selectedClass === 'Druid' && (
                <div><button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Cantrips
                </button></div>
              )}
              {cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, druidCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 2)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedClass === 'Druid' && (
                <button
                  onClick={handleOpenClassPreparedSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 4 Prepared Spells (can be changed when resting)
                </button>
              )}

              {preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, druidPreparedSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {isClassPreparedSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classPreparedSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classPreparedSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {(isClassSelected && className == "Fighter") && (
            <div>
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Class Action:</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={classActions["Second Wind"][0]}
                    style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={classActions["Second Wind"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: '200px', font: 'generalFont', fontSize: '20px', marginTop: '60px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: '40px' }}>Fighting Style (adopt a specific style of fighting as your specialty)</div>
                {fighterStyles.map((buttonText, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <button
                      style={{
                        width: '40px',
                        height: '40px',
                        border: '1px solid white',
                        borderRadius: '50%',
                        backgroundColor: selectedFighterIndex === index ? 'white' : 'transparent',
                        marginRight: '15px',
                      }}
                      onClick={() => handleFightingStyleChange(index)}
                    />
                    <span>{buttonText}</span>
                  </div>
                ))}
                {selectedFighterStyle !== ('') && (
                  <div style={{ marginRight: "150px", border: '2px solid #ccc', padding: '5px', borderRadius: '5px', backgroundColor: 'black', font: generalFont, marginTop: "20px" }}>
                    {fighterStylesDescription[selectedFighterIndex]}
                  </div>
                )}
              </div>
            </div>
          )}

          {(isClassSelected && className == "Monk") && (
            <div>
              <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Class Action:</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={classActions["Flurry of Blows"][0]}
                    style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={classActions["Flurry of Blows"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedSubclass === "Oath of the Ancients" && (
            <div style={{ display: 'flex', font: generalFont, fontSize: "17px", alignItems: 'center', fontWeight: "bold" }}>Class Actions:
              {Object.entries(paladinAncientsSpecificActions).map(([spellName, [spellImage, spellTooltip]], index) => (
                <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                  <img
                    src={spellImage}
                    style={{
                      width: '60%',
                      height: 'auto',
                      background: 'black',
                      padding: '5px',
                      border: '3px solid gray',
                      marginRight: '20px',
                      marginLeft: '5px',
                    }}
                    alt={`Action Image ${index + 1}`}
                  />
                  <div className="tooltip-content">
                    <img
                      src={spellTooltip}
                      style={{ width: '175%', height: '175%' }}
                      alt={`Action Tooltip ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          {selectedSubclass === "Oath of Devotion" && (
            <div style={{ display: 'flex', font: generalFont, fontSize: "17px", alignItems: 'center', fontWeight: "bold" }}>Class Actions:
              {Object.entries(paladinDevotionSpecificActions).map(([spellName, [spellImage, spellTooltip]], index) => (
                <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                  <img
                    src={spellImage}
                    style={{
                      width: '60%',
                      height: 'auto',
                      background: 'black',
                      padding: '5px',
                      border: '3px solid gray',
                      marginRight: '20px',
                      marginLeft: '5px',
                    }}
                    alt={`Action Image ${index + 1}`}
                  />
                  <div className="tooltip-content">
                    <img
                      src={spellTooltip}
                      style={{ width: '175%', height: '175%' }}
                      alt={`Action Tooltip ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {selectedSubclass === "Oath of Vengeance" && (
            <div style={{ display: 'flex', font: generalFont, fontSize: "17px", alignItems: 'center', fontWeight: "bold" }}>Class Actions:
              {Object.entries(paladinVengeanceSpecificActions).map(([spellName, [spellImage, spellTooltip]], index) => (
                <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                  <img
                    src={spellImage}
                    style={{
                      width: '60%',
                      height: 'auto',
                      background: 'black',
                      padding: '5px',
                      border: '3px solid gray',
                      marginRight: '20px',
                      marginLeft: '5px',
                    }}
                    alt={`Action Image ${index + 1}`}
                  />
                  <div className="tooltip-content">
                    <img
                      src={spellTooltip}
                      style={{ width: '175%', height: '175%' }}
                      alt={`Action Tooltip ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {selectedSubclass === "Oathbreaker" && (
            <div style={{ display: 'flex', font: generalFont, fontSize: "17px", alignItems: 'center', fontWeight: "bold" }}>Class Actions:
              {Object.entries(paladinDevotionSpecificActions).map(([spellName, [spellImage, spellTooltip]], index) => (
                <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                  <img
                    src={spellImage}
                    style={{
                      width: '60%',
                      height: 'auto',
                      background: 'black',
                      padding: '5px',
                      border: '3px solid gray',
                      marginRight: '20px',
                      marginLeft: '5px',
                    }}
                    alt={`Action Image ${index + 1}`}
                  />
                  <div className="tooltip-content">
                    <img
                      src={spellTooltip}
                      style={{ width: '175%', height: '175%' }}
                      alt={`Action Tooltip ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {(isClassSelected && className == "Ranger") && (
            <div style={{ font: generalFont, fontSize: '20px', marginTop: '60px', display: 'flex', flexDirection: 'column' }}>
              <div className='' style={{ marginBottom: '40px' }}><span style={{ color: '#FFBF00' }}>Favoured Enemy</span> &nbsp;(Studying the tactics and abilities of certain creatures has granted you a set of abilities that is useful in a variety of situations).</div>
              {favouredEnemy.map((buttonText, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <button
                    style={{
                      width: '40px',
                      height: '40px',
                      border: '1px solid white',
                      borderRadius: '50%',
                      backgroundColor: selectedFavouredEnemyIndex === index ? 'white' : 'transparent',
                      marginRight: '15px',
                      marginLeft: "150px"
                    }}
                    onClick={() => handleFavouredEnemyChange(index)}
                  />
                  <span>{buttonText}</span>
                </div>
              ))}
              {selectedFavouredEnemy !== ('') && (
                <div>
                  <div style={{ marginRight: "150px", border: '2px solid #ccc', padding: '5px', borderRadius: '5px', backgroundColor: 'black', font: generalFont, marginTop: "20px" }}>
                    {favouredEnemyDescription[selectedFavouredEnemyIndex]}
                  </div>
                  {(selectedFavouredEnemy === "Keeper of the Veil" || selectedFavouredEnemy === "Mage Breaker" || selectedFavouredEnemy === "Sanctified Stalker") && (
                    <div className="cantrip-button-wrapper" style={{ position: 'relative', marginTop: "40px" }}>
                      <img
                        src={rangerCantripsSpells[selectedFavouredEnemy][0]}
                        style={{
                          width: '12%',
                          height: '12%',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={rangerCantripsSpells[selectedFavouredEnemy][1]}
                          style={{ width: '80%', height: '80%' }}
                          alt={`Spell Tooltip`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div style={{ marginBottom: '40px', marginTop: "50px" }}><span style={{ color: '#FFBF00' }}>Natural Explorer</span> &nbsp;(Years of travelling in the wild have made you particularly attuned to beasts or adept at surviving in certain environments).</div>
              {naturalExplorer.map((buttonText, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                  <button
                    style={{
                      width: '40px',
                      height: '40px',
                      border: '1px solid white',
                      borderRadius: '50%',
                      backgroundColor: selectedNaturalExplorerIndex === index ? 'white' : 'transparent',
                      marginRight: '15px',
                      marginLeft: "150px"
                    }}
                    onClick={() => handleNaturalExplorerChange(index)}
                  />
                  <span>{buttonText}</span>
                </div>
              ))}

              {selectedNaturalExplorer !== ('') && (
                <div>
                  <div style={{ marginRight: "150px", border: '2px solid #ccc', padding: '5px', borderRadius: '5px', backgroundColor: 'black', font: generalFont, marginTop: "20px" }}>
                    {naturalExplorerDescription[selectedNaturalExplorerIndex]}
                  </div>
                  {selectedNaturalExplorer === "Beast Tamer" && (
                    <div className="cantrip-button-wrapper" style={{ position: 'relative', marginTop: "40px" }}>
                      <img
                        src={rangerCantripsSpells[selectedNaturalExplorer][0]}
                        style={{
                          width: '12%',
                          height: '12%',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={rangerCantripsSpells[selectedNaturalExplorer][1]}
                          style={{ width: '80%', height: '80%' }}
                          alt={`Spell Tooltip`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {(isClassSelected && className == "Rogue") && (
            <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "10px" }}>Class Actions:</span>
              <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                <img
                  src={classActions["Sneak Attack (Melee)"][0]}
                  style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                  alt="Cantrip Image"
                />
                <div className="tooltip-content">
                  <img src={classActions["Sneak Attack (Melee)"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                </div>
              </div>
              <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                <img
                  src={classActions["Sneak Attack (Range)"][0]}
                  style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                  alt="Cantrip Image"
                />
                <div className="tooltip-content">
                  <img src={classActions["Sneak Attack (Range)"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                </div>
              </div>
            </div>
          )}

          {(isClassSelected && className === "Sorcerer") && (
            <div className="instrument-dropdown-container">
              {selectedClass === 'Sorcerer' && (
                <button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 4 Cantrips
                </button>
              )}
              {cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, sorcererCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 4)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {selectedClass === 'Sorcerer' && (
                <button
                  onClick={handleOpenClassSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Spells
                </button>
              )}

              {spellModalContentMultipleSorcerer(isClassSpellModalOpen, handleCloseClassSpellModal, sorcererSpells, handleClassSpellButtonsClick, clickedSpellButtons, 2, selectedBloodline)}
              {isClassSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {(isClassSelected && className === "Warlock" && selectedSubclass === "The Fiend") && (
            <div className="instrument-dropdown-container">
              {selectedClass === 'Warlock' && (
                <div><button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Cantrips
                </button></div>
              )}
              {cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, warlockCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 2)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedClass === 'Warlock' && (
                <button
                  onClick={handleOpenClassSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Spells
                </button>
              )}

              {spellModalContentMultiple(isClassSpellModalOpen, handleCloseClassSpellModal, warlockFiendSpells, handleClassSpellButtonsClick, clickedSpellButtons, 2)}
              {isClassSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {(isClassSelected && className === "Warlock" && subclassName === "The Great Old One") && (
            <div className="instrument-dropdown-container">
              {selectedClass === 'Warlock' && (
                <div><button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Cantrips
                </button></div>
              )}
              {cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, warlockCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 2)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedClass === 'Warlock' && (
                <button
                  onClick={handleOpenClassSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Spells
                </button>
              )}

              {spellModalContentMultiple(isClassSpellModalOpen, handleCloseClassSpellModal, warlockGreatSpells, handleClassSpellButtonsClick, clickedSpellButtons, 2)}
              {isClassSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {(isClassSelected && className === "Warlock" && subclassName === "Archfey") && (
            <div className="instrument-dropdown-container">
              {selectedClass === 'Warlock' && (
                <div><button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Cantrips
                </button></div>
              )}
              {cantripModalContentMultiple(isClassCantripModalOpen, handleCloseClassCantripModal, warlockCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 2)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedClass === 'Warlock' && (
                <button
                  onClick={handleOpenClassSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 2 Spells
                </button>
              )}

              {spellModalContentMultiple(isClassSpellModalOpen, handleCloseClassSpellModal, warlockFeySpells, handleClassSpellButtonsClick, clickedSpellButtons, 2)}
              {isClassSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          {isClassSelected && className === "Wizard" && (
            <div className="instrument-dropdown-container">
              <div style={{ font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px" }}>Class Action:</span>
                <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                  <img
                    src={classActions["Arcane Recovery"][0]}
                    style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                    alt="Cantrip Image"
                  />
                  <div className="tooltip-content">
                    <img src={classActions["Arcane Recovery"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                  </div>
                </div>
              </div>
              {selectedClass === 'Wizard' && (
                <div><button
                  onClick={handleOpenClassCantripModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 3 Cantrips
                </button></div>
              )}
              {cantripModalContentMultipleWizard(isClassCantripModalOpen, handleCloseClassCantripModal, raceCantrips, handleClassCantripButtonsClick, clickedCantripButtons, 3)}
              {isClassCantripButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classCantripImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classCantripImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedClass === 'Wizard' && (
                <button
                  onClick={handleOpenClassSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 6 Spells
                </button>
              )}

              {spellModalContentMultiple(isClassSpellModalOpen, handleCloseClassSpellModal, wizardSpells, handleClassSpellButtonsClick, clickedSpellButtons, 6)}
              {isClassSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {(selectedClass === 'Wizard' && classSpellImages.length > 0) && (
                <button
                  onClick={handleOpenClassPreparedSpellModal}
                  style={{
                    background: '#8B4513',
                    color: '#FDF5E6',
                    padding: '10px 20px',
                    border: '2px solid #CD853F',
                    borderRadius: '20px',
                    fontWeight: 'bold',
                    fontSize: '18px',
                    cursor: 'pointer',
                    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    fontFamily: generalFont,
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                    marginBottom: "10px",
                    marginTop: "70px"

                  }}
                >
                  Select 4 Prepared Spells (can be changed when resting)
                </button>
              )}

              {preparedSpellModalContentMultiple(isClassPreparedSpellModalOpen, handleCloseClassPreparedSpellModal, wizardSpells, handleClassPreparedSpellButtonsClick, clickedPreparedSpellButtons, 4)}
              {isClassPreparedSpellButtonClicked === true && (
                <div style={{ display: 'flex' }}>
                  {classPreparedSpellImages.map((item, index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={item}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Spell Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={classPreparedSpellImageTooltips[index]}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Spell Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px", marginLeft: "170px" }}>
            {isClassSelected && (
              <button
                style={{
                  fontFamily: generalFont,
                  marginBottom: "10px",
                  background: "#444444",
                  color: "#FDF5E6",
                  width: "200px",
                  height: "60px",
                  border: "2px solid #CD853F",
                  borderRadius: "50px",
                  fontWeight: "bold",
                  fontSize: "18px",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                disabled
              >
                Select subclass
              </button>
            )}

            <div style={{
              display: "grid",
              gridTemplateColumns: `repeat(${Object.keys(classData[className]?.Subclass || {}).length === 3 ? 3 : 4}, 1fr)`,
              gap: "5px",
              justifyContent: "center",
              flexWrap: "wrap", // Ensures responsiveness on smaller screens
              marginLeft: "-30px"
            }}>
              {Object.keys(classData[className]?.Subclass || {}).map((subclass) => (
                <button
                  key={subclass}
                  style={
                    selectedSubclass === subclass
                      ? {
                        ...highlightedSubclassButtonStyle,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "120px",
                        height: "90px"
                      }
                      : {
                        ...subclassButtonStyle,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "110px",
                        height: "80px"
                      }
                  }
                  onClick={() => handleSubclassButtonClick(subclass)}
                >
                  {subclass}
                </button>
              ))}
            </div>
          </div>


          {(isClassSelected && isSubclassSelected) && (
            <div style={{ marginTop: "50px" }}>
              {selectedSubclass === "Life Domain" && (
                <div style={{ display: 'flex', font: generalFont, fontSize: "17px", alignItems: 'center', fontWeight: "bold" }}>Subclass Prepared Spells:
                  {Object.entries(clericLifeSpecificSpells).map(([spellName, [spellImage, spellTooltip]], index) => (
                    <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                      <img
                        src={spellImage}
                        style={{
                          width: '60%',
                          height: 'auto',
                          background: 'black',
                          padding: '5px',
                          border: '3px solid gray',
                          marginRight: '20px',
                          marginLeft: '5px',
                        }}
                        alt={`Cantrip Image ${index + 1}`}
                      />
                      <div className="tooltip-content">
                        <img
                          src={spellTooltip}
                          style={{ width: '175%', height: '175%' }}
                          alt={`Cantrip Tooltip ${index + 1}`}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {selectedSubclass === "Light Domain" && (
                <div style={{ display: 'flex', flexDirection: 'column', font: generalFont, fontSize: "17px", fontWeight: "bold" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Subclass Prepared Spells:
                    {Object.entries(clericLightSpecificSpells).map(([spellName, [spellImage, spellTooltip]], index) => (
                      <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                        <img
                          src={spellImage}
                          style={{
                            width: '60%',
                            height: 'auto',
                            background: 'black',
                            padding: '5px',
                            border: '3px solid gray',
                            marginRight: '20px',
                            marginLeft: '5px',
                          }}
                          alt={`Cantrip Image ${index + 1}`}
                        />
                        <div className="tooltip-content">
                          <img
                            src={spellTooltip}
                            style={{ width: '175%', height: '175%' }}
                            alt={`Cantrip Tooltip ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    <span style={{ marginRight: "10px" }}>Subclass Cantrip:</span>
                    <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                      <img
                        src={classCantrips["Light"][0]}
                        style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                        alt="Cantrip Image"
                      />
                      <div className="tooltip-content">
                        <img src={classCantrips["Light"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedSubclass === "Trickery Domain" && (
                <div style={{ display: 'flex', flexDirection: 'column', font: generalFont, fontSize: "17px", fontWeight: "bold" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Subclass Prepared Spells:
                    {Object.entries(clericTrickerySpecificSpells).map(([spellName, [spellImage, spellTooltip]], index) => (
                      <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                        <img
                          src={spellImage}
                          style={{
                            width: '60%',
                            height: 'auto',
                            background: 'black',
                            padding: '5px',
                            border: '3px solid gray',
                            marginRight: '20px',
                            marginLeft: '5px',
                          }}
                          alt={`Cantrip Image ${index + 1}`}
                        />
                        <div className="tooltip-content">
                          <img
                            src={spellTooltip}
                            style={{ width: '175%', height: '175%' }}
                            alt={`Cantrip Tooltip ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                    <span style={{ marginRight: "10px" }}>Subclass Action:</span>
                    <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                      <img
                        src={subclassActions["Blessing of the Trickster"][0]}
                        style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                        alt="Cantrip Image"
                      />
                      <div className="tooltip-content">
                        <img src={subclassActions["Blessing of the Trickster"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedSubclass === "Knowledge Domain" && (
                <div style={{ display: 'flex', flexDirection: 'column', font: generalFont, fontSize: "17px", fontWeight: "bold" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Subclass Prepared Spells:
                    {Object.entries(clericKnowledgeSpecificSpells).map(([spellName, [spellImage, spellTooltip]], index) => (
                      <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                        <img
                          src={spellImage}
                          style={{
                            width: '60%',
                            height: 'auto',
                            background: 'black',
                            padding: '5px',
                            border: '3px solid gray',
                            marginRight: '20px',
                            marginLeft: '5px',
                          }}
                          alt={`Cantrip Image ${index + 1}`}
                        />
                        <div className="tooltip-content">
                          <img
                            src={spellTooltip}
                            style={{ width: '175%', height: '175%' }}
                            alt={`Cantrip Tooltip ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {selectedSubclass === "Nature Domain" && (
                <div style={{ display: 'flex', flexDirection: 'column', font: generalFont, fontSize: "17px", fontWeight: "bold" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Subclass Prepared Spells:
                    {Object.entries(clericNatureSpecificSpells).map(([spellName, [spellImage, spellTooltip]], index) => (
                      <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                        <img
                          src={spellImage}
                          style={{
                            width: '60%',
                            height: 'auto',
                            background: 'black',
                            padding: '5px',
                            border: '3px solid gray',
                            marginRight: '20px',
                            marginLeft: '5px',
                          }}
                          alt={`Cantrip Image ${index + 1}`}
                        />
                        <div className="tooltip-content">
                          <img
                            src={spellTooltip}
                            style={{ width: '175%', height: '175%' }}
                            alt={`Cantrip Tooltip ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div style={{ marginLeft: "100px" }}>
                    <button
                      onClick={handleOpenClassCantripSpecificModal}
                      style={{
                        background: '#8B4513',
                        color: '#FDF5E6',
                        padding: '10px 20px',
                        border: '2px solid #CD853F',
                        borderRadius: '20px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        cursor: 'pointer',
                        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                        fontFamily: generalFont,
                        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
                        marginBottom: "10px",
                        marginTop: "50px"
                      }}
                    >
                      Select Nature Cantrip
                    </button>
                  </div>
                  {isClassCantripSpecificModalOpen && cantripModalContentClassSpecific(isClassCantripSpecificModalOpen, handleCloseClassCantripSpecificModalWithClick, clericNatureCantripsSpecific, handleCantripSpecificButtonClick)}
                  {isClassCantripSpecificButtonClicked === true && (
                    <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: "10px" }}>Nature Cantrip:</span>
                      <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                        <img
                          src={classCantripSpecificImage}
                          style={{ width: "55%", height: "55%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                          alt="Cantrip Image"
                        />
                        <div className="tooltip-content">
                          <img src={classCantripSpecificImageTooltip} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {selectedSubclass === "Tempest Domain" && (
                <div style={{ display: 'flex', flexDirection: 'column', font: generalFont, fontSize: "17px", fontWeight: "bold" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Subclass Prepared Spells:
                    {Object.entries(clericTempestSpecificSpells).map(([spellName, [spellImage, spellTooltip]], index) => (
                      <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                        <img
                          src={spellImage}
                          style={{
                            width: '60%',
                            height: 'auto',
                            background: 'black',
                            padding: '5px',
                            border: '3px solid gray',
                            marginRight: '20px',
                            marginLeft: '5px',
                          }}
                          alt={`Cantrip Image ${index + 1}`}
                        />
                        <div className="tooltip-content">
                          <img
                            src={spellTooltip}
                            style={{ width: '175%', height: '175%' }}
                            alt={`Cantrip Tooltip ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {selectedSubclass === "War Domain" && (
                <div style={{ display: 'flex', flexDirection: 'column', font: generalFont, fontSize: "17px", fontWeight: "bold" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Subclass Prepared Spells:
                    {Object.entries(clericWarSpecificSpells).map(([spellName, [spellImage, spellTooltip]], index) => (
                      <div className="cantrip-button-wrapper" key={index} style={{ position: 'relative', marginRight: '20px' }}>
                        <img
                          src={spellImage}
                          style={{
                            width: '60%',
                            height: 'auto',
                            background: 'black',
                            padding: '5px',
                            border: '3px solid gray',
                            marginRight: '20px',
                            marginLeft: '5px',
                          }}
                          alt={`Cantrip Image ${index + 1}`}
                        />
                        <div className="tooltip-content">
                          <img
                            src={spellTooltip}
                            style={{ width: '175%', height: '175%' }}
                            alt={`Cantrip Tooltip ${index + 1}`}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {selectedSubclass === "Oath of the Ancients" && (
                <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Subclass Action:</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={paladinSubclassActions["Healing Radiance"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={paladinSubclassActions["Healing Radiance"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              )}
              {selectedSubclass === "Oath of Devotion" && (
                <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Subclass Action:</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={paladinSubclassActions["Holy Rebuke"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={paladinSubclassActions["Holy Rebuke"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              )}{selectedSubclass === "Oath of Vengeance" && (
                <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Subclass Action:</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={paladinSubclassActions["Inquisitor Might"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={paladinSubclassActions["Inquisitor Might"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              )}

              {selectedSubclass === "Oathbreaker" && (
                <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Subclass Action:</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={paladinSubclassActions["Spiteful Suffering"][0]}
                      style={{ width: "60%", height: "60%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "5px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={paladinSubclassActions["Spiteful Suffering"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              )}

              {selectedSubclass === "Draconic Bloodline" && (
                <div>
                  <label htmlFor="instrumentDropdown">Dragon Ancestor:</label>
                  <select style={dropdownStyle} id="instrumentDropdown" value={selectedBloodline} onChange={handleBloodlineChange}>
                    <option value="">Select an option</option>
                    <option value="Red (Fire)">Red (Fire)</option>
                    <option value="Black (Acid)">Black (Acid)</option>
                    <option value="Blue (Lightning)">Blue (Lightning)</option>
                    <option value="White (Cold)">White (Cold)</option>
                    <option value="Green (Poison)">Green (Poison)</option>
                    <option value="Gold (Fire)">Gold (Fire)</option>
                    <option value="Silver (Cold)">Silver (Cold)</option>
                    <option value="Bronze (Lightning)">Bronze (Lightning)</option>
                    <option value="Copper (Acid)">Copper (Acid)</option>
                    <option value="Brass (Fire)">Brass (Fire)</option>
                  </select>
                  {selectedBloodline && (
                    <div style={{ font: generalFont, fontSize: "18px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: "10px" }}>Spell from Bloodline:</span>
                      <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                        <img
                          src={sorcererBloodlinesImages[sorcererBloodlines[sorcererBloodlines.indexOf(selectedBloodline) + 1]][0]}
                          style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                          alt="Cantrip Image"
                        />
                        <div className="tooltip-content">
                          <img src={sorcererBloodlinesImages[sorcererBloodlines[sorcererBloodlines.indexOf(selectedBloodline) + 1]][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(isClassSelected && className == "Warlock" && subclassName == "Archfey") && (
                <div style={{ marginLeft: "100px", font: generalFont, fontSize: "20px", marginTop: "20px", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "10px" }}>Subclass Action:</span>
                  <div className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                    <img
                      src={subclassActions["Fey Presence"][0]}
                      style={{ width: "50%", height: "50%", background: "black", padding: "5px", border: "3px solid gray", marginRight: "20px", marginLeft: "25px" }}
                      alt="Cantrip Image"
                    />
                    <div className="tooltip-content">
                      <img src={subclassActions["Fey Presence"][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                    </div>
                  </div>
                </div>
              )}

              {classData[className].MulticlassLevel > 1 && (
                <h3 style={{ marginTop: "50px" }}>
                  <span style={{ color: 'red' }}>Note:</span> <u><span style={{ fontSize: "17px" }}>{classData[className].SubclassText}</span></u>
                </h3>
              )}
              <h3>
                <span style={{ color: '#FFBF00' }}>Description:</span> <span style={{ fontSize: "17px" }}>{classData[className].Subclass[selectedSubclass]?.Description}</span>
              </h3>
              <h3 style={{ color: '#FFBF00' }}>Subclass Features when picking the subclass:</h3>
              <ul>
                {Object.entries(classData[className].Subclass[selectedSubclass]?.Features || {}).map(([key, value]) => (
                  <li key={key}>
                    <span style={{ color: '#D2B48C' }}>{key}: </span>
                    {Array.isArray(value) ? value.join(', ') : value}
                  </li>
                ))}
              </ul>

            </div>
          )}
        </div>
      </div>
    )
  );

  /* CLASSES END */

  /* SKILLS START AND ATTRIBUTES */
  const [abilityPointsRemaining, setAbilityPointsRemaining] = useState(27);

  const [firstDropdownValue, setFirstDropdownValue] = useState(''); // State for the first dropdown
  const [secondDropdownValue, setSecondDropdownValue] = useState(''); // State for the second dropdown

  const abilityScoresData = [
    { name: "Strength", state: useState(8), withoutBonus: useState(8), proficiencyScore: useState(-1), numberPressed: useState(0) },
    { name: "Dexterity", state: useState(8), withoutBonus: useState(8), proficiencyScore: useState(-1), numberPressed: useState(0) },
    { name: "Constitution", state: useState(8), withoutBonus: useState(8), proficiencyScore: useState(-1), numberPressed: useState(0) },
    { name: "Intelligence", state: useState(8), withoutBonus: useState(8), proficiencyScore: useState(-1), numberPressed: useState(0) },
    { name: "Wisdom", state: useState(8), withoutBonus: useState(8), proficiencyScore: useState(-1), numberPressed: useState(0) },
    { name: "Charisma", state: useState(8), withoutBonus: useState(8), proficiencyScore: useState(-1), numberPressed: useState(0) },
  ];

  const abilityScoreNamesAndImages = {
    Strength: abilityScores.strength,
    Dexterity: abilityScores.dexterity,
    Constitution: abilityScores.constitution,
    Intelligence: abilityScores.intelligence,
    Wisdom: abilityScores.wisdom,
    Charisma: abilityScores.charisma,
  };

  const attributesQuery = ["abs", "abd", "abc", "abi", "abw", "abch"]

  const handleAttributes = () => {
    const queryParams = new URLSearchParams(window.location.search);
    var nr_of_attr = 0;
    for (const element of attributesQuery) {
      if (!queryParams.has(element)) {
        queryParams.set(element, "8")
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);
        nr_of_attr++;
      }
    }
    if (nr_of_attr === 6) {
      queryParams.set("rap", "27")
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }
  }

  function calculateProficiency(newAbilityPoints) {
    if (newAbilityPoints === 8 || newAbilityPoints === 9) {
      return -1;
    } else if (newAbilityPoints === 10 || newAbilityPoints === 11) {
      return 0;
    } else if (newAbilityPoints === 12 || newAbilityPoints === 13) {
      return 1;
    } else if (newAbilityPoints === 14 || newAbilityPoints === 15) {
      return 2;
    } else if (newAbilityPoints === 16 || newAbilityPoints === 17) {
      return 3;
    } else {
      return 0;
    }
  }

  const getIndexByName = (name) => {
    return abilityScoresData.findIndex((abilityScore) => abilityScore.name === name);
  };

  const handleIncreaseAbilityPoints = (name, abilityPoints, setAbilityPoints, abilityPointsWithoutBonus, setAbilityPointsWithoutBonus, proficiency, setProficiency, presses, setPresses) => {
    if (abilityPointsRemaining == 0) {
      return;
    }
    if (presses < 5) {
      if (abilityPoints < abilityPointsWithoutBonus) {
        setAbilityPoints(abilityPointsWithoutBonus);
      }
      setAbilityPointsRemaining((prevAbilityPointsRemaining) => prevAbilityPointsRemaining - 1)
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("rap", abilityPointsRemaining - 1)
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
      setPresses((prevPresses) => prevPresses + 1);
      setAbilityPoints((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints + 1;

        // Calculate the new proficiency score based on the new ability points
        setProficiency(calculateProficiency(newAbilityPoints));
        return newAbilityPoints;
      });

      setAbilityPointsWithoutBonus((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints + 1;

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(attributesQuery[getIndexByName(name)], newAbilityPoints)
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return newAbilityPoints;
      });
    }
    else if (presses < 7 && abilityPointsRemaining >= 2) {
      if (abilityPoints < abilityPointsWithoutBonus) {
        setAbilityPoints(abilityPointsWithoutBonus);
      }
      setAbilityPointsRemaining((prevAbilityPointsRemaining) => prevAbilityPointsRemaining - 2)
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("rap", abilityPointsRemaining - 2)
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
      setPresses((prevPresses) => prevPresses + 1);
      setAbilityPoints((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints + 1;

        // Calculate the new proficiency score based on the new ability points
        setProficiency(calculateProficiency(newAbilityPoints));

        return newAbilityPoints;
      });

      setAbilityPointsWithoutBonus((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints + 1;

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(attributesQuery[getIndexByName(name)], newAbilityPoints)
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return newAbilityPoints;
      });
    }
  };

  const handleDecreaseAbilityPoints = (name, abilityPoints, setAbilityPoints, abilityPointsWithoutBonus, setAbilityPointsWithoutBonus, proficiency, setProficiency, presses, setPresses) => {
    if (abilityPointsRemaining == 27) {
      return;
    }
    if (presses > 5 && abilityPointsRemaining <= 25) {
      if (abilityPoints < abilityPointsWithoutBonus) {
        setAbilityPoints(abilityPointsWithoutBonus);
      }
      setAbilityPointsRemaining((prevAbilityPointsRemaining) => prevAbilityPointsRemaining + 2)
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("rap", abilityPointsRemaining + 2)
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
      setPresses((prevPresses) => prevPresses - 1);
      setAbilityPoints((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints - 1;

        // Calculate the new proficiency score based on the new ability points
        setProficiency(calculateProficiency(newAbilityPoints));
        return newAbilityPoints;
      });

      setAbilityPointsWithoutBonus((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints - 1;

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(attributesQuery[getIndexByName(name)], newAbilityPoints)
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return newAbilityPoints;
      });
    }
    else if (presses > 0) {
      if (abilityPoints < abilityPointsWithoutBonus) {
        setAbilityPoints(abilityPointsWithoutBonus);
      }
      setAbilityPointsRemaining((prevAbilityPointsRemaining) => prevAbilityPointsRemaining + 1)
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("rap", abilityPointsRemaining + 1)
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
      setPresses((prevPresses) => prevPresses - 1);
      setAbilityPoints((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints - 1;

        // Calculate the new proficiency score based on the new ability points
        setProficiency(calculateProficiency(newAbilityPoints));
        return newAbilityPoints;
      });

      setAbilityPointsWithoutBonus((prevAbilityPoints) => {
        // Calculate the new ability points
        const newAbilityPoints = prevAbilityPoints - 1;

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(attributesQuery[getIndexByName(name)], newAbilityPoints)
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return newAbilityPoints;
      });
    }
  };

  const handleFirstDropdownChange = (event) => {
    const selectedValue = event.target.value;

    // Find the previously selected option's ability score and subtract 2 if it exists
    const prevSelectedAbility = abilityScoresData.find(
      (ability) => ability.name === firstDropdownValue
    );

    if (prevSelectedAbility) {
      const [, setPrevAbilityScore] = prevSelectedAbility.state;
      setPrevAbilityScore((prevScore) => {
        const oldAbilityPoints = prevScore - 2;
        const [, setProficiency] = prevSelectedAbility.proficiencyScore;
        setProficiency(calculateProficiency(oldAbilityPoints));
        return prevScore - 2;
      });
    }

    // Increase the selected ability score by +2
    const selectedAbility = abilityScoresData.find(
      (ability) => ability.name === selectedValue
    );

    if (selectedAbility) {
      const [, setAbilityScore] = selectedAbility.state;
      setAbilityScore((prevScore) => {
        // Calculate and set the proficiency score based on the new ability score value
        const newAbilityPoints = prevScore + 2;
        const [, setProficiency] = selectedAbility.proficiencyScore;
        setProficiency(calculateProficiency(newAbilityPoints));

        return prevScore + 2;
      });
    }

    // Check if the selected value from the first dropdown is the same as the current value in the second dropdown
    if (secondDropdownValue === selectedValue) {
      // Find the currently selected option's ability score and subtract 1
      const selectedAbilityInSecondDropdown = abilityScoresData.find(
        (ability) => ability.name === secondDropdownValue
      );

      if (selectedAbilityInSecondDropdown) {
        const [, setAbilityScore] = selectedAbilityInSecondDropdown.state;
        setAbilityScore((prevScore) => {
          // Calculate and set the proficiency score based on the new ability score value
          const newAbilityPoints = prevScore - 1;
          const [, setProficiency] = selectedAbilityInSecondDropdown.proficiencyScore;
          setProficiency(calculateProficiency(newAbilityPoints));

          const queryParams = new URLSearchParams(window.location.search);
          queryParams.set("bt", selectedValue)
          queryParams.set("bo", "")
          const baseUrl = window.location.href.split('?')[0];
          const newUrl = `${baseUrl}?${queryParams.toString()}`;
          window.history.pushState(null, '', newUrl);

          return prevScore - 1;
        });
      }
    }
    else {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("bt", selectedValue)
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }

    if (selectedValue == "") {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("bt", "")
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }

    setFirstDropdownValue(selectedValue);
    setSecondDropdownValue((prevValue) => (prevValue === selectedValue ? '' : prevValue));

  };

  const handleSecondDropdownChange = (event) => {
    const selectedValue = event.target.value;

    // Find the previously selected option's ability score and subtract 1 if it exists
    const prevSelectedAbility = abilityScoresData.find(
      (ability) => ability.name === secondDropdownValue
    );

    if (prevSelectedAbility) {
      const [, setPrevAbilityScore] = prevSelectedAbility.state;
      setPrevAbilityScore((prevScore) => {
        const oldAbilityPoints = prevScore - 1;
        const [, setProficiency] = prevSelectedAbility.proficiencyScore;
        setProficiency(calculateProficiency(oldAbilityPoints));
        return prevScore - 1;
      });
    }

    // Increase the selected ability score by +1
    const selectedAbility = abilityScoresData.find(
      (ability) => ability.name === selectedValue
    );

    if (selectedAbility) {
      const [, setAbilityScore] = selectedAbility.state;
      setAbilityScore((prevScore) => {
        // Calculate and set the proficiency score based on the new ability score value
        const newAbilityPoints = prevScore + 1;
        const [, setProficiency] = selectedAbility.proficiencyScore;
        setProficiency(calculateProficiency(newAbilityPoints));

        return prevScore + 1;
      });
    }

    // Check if the selected value from the second dropdown is the same as the current value in the first dropdown
    if (firstDropdownValue === selectedValue) {
      // Find the currently selected option's ability score and subtract 1
      const selectedAbilityInFirstDropdown = abilityScoresData.find(
        (ability) => ability.name === firstDropdownValue
      );

      if (selectedAbilityInFirstDropdown) {
        const [, setAbilityScore] = selectedAbilityInFirstDropdown.state;
        setAbilityScore((prevScore) => {
          // Calculate and set the proficiency score based on the new ability score value
          const newAbilityPoints = prevScore - 2;
          const [, setProficiency] = selectedAbilityInFirstDropdown.proficiencyScore;
          setProficiency(calculateProficiency(newAbilityPoints));

          const queryParams = new URLSearchParams(window.location.search);
          queryParams.set("bo", selectedValue)
          queryParams.set("bt", "")
          const baseUrl = window.location.href.split('?')[0];
          const newUrl = `${baseUrl}?${queryParams.toString()}`;
          window.history.pushState(null, '', newUrl);

          return prevScore - 2;
        });
      }
    }
    else {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("bo", selectedValue)
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }

    if (selectedValue == "") {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("bo", "")
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }

    setSecondDropdownValue(selectedValue);
    setFirstDropdownValue((prevValue) => (prevValue === selectedValue ? '' : prevValue));
  };

  const getUrlParameter = (name) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(name);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const abilityScoresToUpdate = [];
    let overTwo = 0;

    // Helper function to update ability score data
    const updateAbilityScore = (attributeIndex, value, bonusValue = 0) => {
      const [attributeState, setAttributeState] = abilityScoresData[attributeIndex].state;
      setAttributeState(value + bonusValue);

      const [attributeStateWithoutBonus, setAttributeStateWithoutBonus] = abilityScoresData[attributeIndex].withoutBonus;
      setAttributeStateWithoutBonus(value);

      const [numberPressed, setNumberPressed] = abilityScoresData[attributeIndex].numberPressed;
      setNumberPressed(value - 8);

      // Update proficiency score based on newAbilityPoints
      const proficiencyScore = calculateProficiency(value + bonusValue);
      const [, setProficiencyScore] = abilityScoresData[attributeIndex].proficiencyScore;
      setProficiencyScore(proficiencyScore);
    };

    // Update firstDropdownValue and secondDropdownValue
    if (queryParams.has("bt")) {
      setFirstDropdownValue(queryParams.get("bt"));
    }
    if (queryParams.has("bo")) {
      setSecondDropdownValue(queryParams.get("bo"));
    }

    for (const element of attributesQuery) {
      if (queryParams.has(element)) {
        let value = parseInt(getUrlParameter(element));
        if (value > 13) {
          overTwo += value - 13;
        }

        // Check if the value is outside the valid range (8 to 15)
        if (value < 8 || value > 15 || isNaN(value)) {
          value = 8; // Reset the value to 8 if it's invalid
          // Update the URL with the new value of 8
          const url = new URL(window.location.href);
          url.searchParams.set(element, value);
          window.history.replaceState({}, "", url);
        }

        const attributeIndex = attributesQuery.indexOf(element);
        if (attributeIndex !== -1) {
          if (queryParams.has("bt") && queryParams.get("bt") === abilityScoresData[attributeIndex].name) {
            abilityScoresToUpdate.push({ attributeIndex, value, bonusValue: 2 });
          } else if (queryParams.has("bo") && queryParams.get("bo") === abilityScoresData[attributeIndex].name) {
            abilityScoresToUpdate.push({ attributeIndex, value, bonusValue: 1 });
          } else {
            abilityScoresToUpdate.push({ attributeIndex, value, bonusValue: 0 });
          }
        }
      }
    }

    // Update ability scores based on queryParams
    abilityScoresToUpdate.forEach(({ attributeIndex, value, bonusValue }) => {
      updateAbilityScore(attributeIndex, value, bonusValue);
    });

    // Update abilityPointsRemaining
    const sum = parseInt(getUrlParameter("abs")) + parseInt(getUrlParameter("abd")) + parseInt(getUrlParameter("abc")) + parseInt(getUrlParameter("abi")) + parseInt(getUrlParameter("abw")) + parseInt(getUrlParameter("abch"));
    let value = parseInt(getUrlParameter("rap"));
    if (queryParams.has("rap") && isNaN(value)) {
      setAbilityPointsRemaining(sum - 21);
      const url = new URL(window.location.href);
      url.searchParams.set("rap", sum - 21);
      window.history.replaceState({}, "", url);
    } else if (queryParams.has("rap") && sum - value !== 21) {
      setAbilityPointsRemaining(75 - overTwo - sum);
      const url = new URL(window.location.href);
      url.searchParams.set("rap", 75 - sum);
      window.history.replaceState({}, "", url);
    }

    // Update the URL with the selected dropdown values if isClassSelected
    if (isClassSelected && queryParams.has("c")) {
      queryParams.set("bt", firstDropdownValue);
      queryParams.set("bo", secondDropdownValue);
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }

  }, [location.search]);

  const classMainStat = {
    "Barbarian": "Strength",
    "Bard": "Charisma",
    "Cleric": "Wisdom",
    "Druid": "Wisdom",
    "Fighter": "Strength",
    "Monk": "Dexterity",
    "Paladin": "Strength",
    "Ranger": "Dexterity",
    "Rogue": "Dexterity",
    "Sorcerer": "Charisma",
    "Warlock": "Charisma",
    "Wizard": "Intelligence"
  };

  const frameStyle3 = {
    border: '2px solid white',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    backdropFilter: 'blur(5px)', // Apply the blur effect to the background
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Add a translucent background color,
    marginTop: "20px",
    marginRight: "120px",
    flex: 1,
  };

  const containerStyle2 = {
    position: 'relative',
    left: '5%',
    display: 'flex',
    flexDirection: 'row', // Update to 'row' to place the frames side by side
    alignItems: 'flex-start',
    justifyContent: 'space-between', // Update to position the frames to the left and right
    padding: '20px',
    maxWidth: '100vw', // Set the maximum width to the viewport width
    paddingBottom: "70px"
  };

  const SkillsFrame =
    isClassSelected === true && (
      <div style={{ ...frameStyle3, display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: '10px', padding: '5px', color: 'white', font: generalFont, fontSize: '24px', color: '#FFBF00' }}>
            Assign the +2/+1 ability scores and the rest of the ability points
          </div>
          <select style={dropdownStyle} value={firstDropdownValue} onChange={handleFirstDropdownChange}>
            <option value="">Select +2 ability score</option>
            <option value="Strength">Strength</option>
            <option value="Dexterity">Dexterity</option>
            <option value="Constitution">Constitution</option>
            <option value="Intelligence">Intelligence</option>
            <option value="Wisdom">Wisdom</option>
            <option value="Charisma">Charisma</option>
          </select>
          <select style={dropdownStyle} value={secondDropdownValue} onChange={handleSecondDropdownChange}>
            <option value="">Select +1 ability score</option>
            <option value="Strength">Strength</option>
            <option value="Dexterity">Dexterity</option>
            <option value="Constitution">Constitution</option>
            <option value="Intelligence">Intelligence</option>
            <option value="Wisdom">Wisdom</option>
            <option value="Charisma">Charisma</option>
          </select>
          <div style={{ marginBottom: '10px', padding: '5px', color: '#FFBF00', font: generalFont, fontSize: '24px', marginTop: '50px' }}>
            Points remaining:
          </div>
          <div style={{ padding: '5px', color: 'white', font: generalFont, fontSize: '24px' }}>
            {abilityPointsRemaining}/27
          </div>
        </div>
        {abilityScoresData.map(({ name, state: [abilityPoints, setAbilityPoints], withoutBonus: [abilityPointsWithoutBonus, setAbilityPointsWithoutBonus], proficiencyScore: [proficiency, setProficiency], numberPressed: [presses, setPresses] }) => (
          <div key={name} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {/* Picture on the left */}
            <div style={{ marginRight: '20px' }}>
              <img src={abilityScoreNamesAndImages[name]} alt="Your Image" style={{ width: '100px', height: '100px', marginLeft: "100px" }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
              {classMainStat[selectedClass] === name && (
                <span style={{ marginRight: '5px', fontSize: '24px', fontWeight: 'bold', font: generalFont, color: 'white' }}>
                  ★
                </span>
              )}
              <p style={{ margin: '0', fontSize: '24px', font: generalFont, color: 'white' }}>
                {name}
              </p>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginRight: '20px' }}>
              {/* Number on the right */}
              <div style={{ fontSize: '19px', color: '#FFBF00' }}>
                {proficiency}
              </div>
              {/* Another Number on the right */}
              <div style={{ fontSize: '23px', color: 'white' }}>
                {abilityPoints}
              </div>
            </div>
            <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
              {/* Increase Ability Points Button */}
              <button style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: 'transparent',
                marginRight: '20px',
                backgroundImage: `url(${miscButtons.plus_button})`, // Set the background image
                backgroundSize: 'cover', // Optional: Adjusts the size of the background image
              }}
                onClick={() => handleIncreaseAbilityPoints(name, abilityPoints, setAbilityPoints, abilityPointsWithoutBonus, setAbilityPointsWithoutBonus, proficiency, setProficiency, presses, setPresses)}
              ></button>
              {/* Decrease Ability Points Button */}
              <button
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  backgroundColor: 'transparent',
                  marginRight: '20px',
                  backgroundImage: `url(${miscButtons.minus_button})`, // Set the background image
                  backgroundSize: 'cover', // Optional: Adjusts the size of the background image
                }}
                onClick={() => handleDecreaseAbilityPoints(name, abilityPoints, setAbilityPoints, abilityPointsWithoutBonus, setAbilityPointsWithoutBonus, proficiency, setProficiency, presses, setPresses)}
              ></button>
            </div>
          </div>
        ))}
      </div>
    );

  /* SKILLS END */

  /* ATTRIBUTES START */

  const [selectedBaseSkill, setSelectedBaseSkill] = useState(null);
  const [baseSkillPresses, setBaseSkillPresses] = useState(1);
  const [disabledBaseSkills, setDisabledBaseSkills] = useState([]);
  const [selectedExpertiseSkills, setSelectedExpertiseSkills] = useState([]);
  const [expertiseSkillPresses, setExpertiseSkillPresses] = useState(2);
  const [disabledExpertiseSkills, setDisabledExpertiseSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillPresses, setSkillPresses] = useState(classData[selectedClass]?.Skills_to_choose || 0);
  const [disabledSkills, setDisabledSkills] = useState([]);


  const [mergedSkills, setMergedSkills] = useState({});
  const [filteredSkills, setFilteredSkills] = useState({});
  const [classSkills, setClassSkills] = useState({});

  const allSkills = {
    "Acrobatics": ["Dexterity", "Dex"],
    "Animal Handling": ["Wisdom", "Wis"],
    "Arcana": ["Intelligence", "Int"],
    "Athletics": ["Strength", "Str"],
    "Deception": ["Charisma", "Cha"],
    "History": ["Intelligence", "Int"],
    "Insight": ["Wisdom", "Wis"],
    "Intimidation": ["Charisma", "Cha"],
    "Investigation": ["Intelligence", "Int"],
    "Medicine": ["Wisdom", "Wis"],
    "Nature": ["Intelligence", "Int"],
    "Perception": ["Wisdom", "Wis"],
    "Performance": ["Charisma", "Cha"],
    "Persuasion": ["Charisma", "Cha"],
    "Religion": ["Intelligence", "Int"],
    "Sleight of Hand": ["Dexterity", "Dex"],
    "Stealth": ["Dexterity", "Dex"],
    "Survival": ["Wisdom", "Wis"]
  }

  const handleSkills = (selectedBackground, selectedClass) => {

    const raceSkills = Array.isArray(raceData[selectedRaceData]?.Skills)
      ? raceData[selectedRaceData]?.Skills.join(', ')
      : '';

    const newMergedSkills = {
      [backgroundData[selectedBackground]?.Proficiencies_unlocked[0]]: [`Granted by ${selectedBackground}`, allSkills[backgroundData[selectedBackground]?.Proficiencies_unlocked[0]]?.[1], allSkills[backgroundData[selectedBackground]?.Proficiencies_unlocked[0]]?.[0]],
      [backgroundData[selectedBackground]?.Proficiencies_unlocked[1]]: [`Granted by ${selectedBackground}`, allSkills[backgroundData[selectedBackground]?.Proficiencies_unlocked[1]]?.[1], allSkills[backgroundData[selectedBackground]?.Proficiencies_unlocked[1]]?.[0]],
    };

    if (Array.isArray(raceData[selectedRaceData]?.Skills)) {
      const raceSkillsArray = raceData[selectedRaceData].Skills;

      raceSkillsArray.forEach(skill => {
        if (skill !== 'Any' && skill !== 'None') {
          newMergedSkills[skill] = [`Granted by ${selectedRaceData}`, allSkills[skill]?.[1], allSkills[skill]?.[0]];
        }
      });
    }

    setMergedSkills(newMergedSkills);
    const newFilteredSkills = Object.keys(allSkills).reduce((result, skill) => {
      if (!(skill in newMergedSkills)) {
        result[skill] = allSkills[skill];
      }
      return result;
    }, {});

    if (selectedClass in classData && classData[selectedClass].Proficiencies_to_choose) {
      const extractedSkills = classData[selectedClass].Proficiencies_to_choose;
      const matchedSkills = Object.keys(allSkills).filter(skill => extractedSkills.includes(skill));
      const updatedClassSkills = Object.fromEntries(matchedSkills.map(skill => [skill, allSkills[skill]]));

      // Remove keys from updatedClassSkills that exist in newMergedSkills
      const filteredClassSkills = {};
      for (const skillKey in updatedClassSkills) {
        if (!(skillKey in newMergedSkills)) {
          filteredClassSkills[skillKey] = updatedClassSkills[skillKey];
        }
      }
      setClassSkills(filteredClassSkills);

    } else {
      setClassSkills({});
    }

    // Update the filteredSkills state
    setFilteredSkills(newFilteredSkills);

    const keysOfNewFilteredSkills = Object.keys(newFilteredSkills);

    setSelectedBaseSkill(null);
    setBaseSkillPresses(1);
    setSelectedSkills([]);
    setDisabledSkills([]);
    setDisabledBaseSkills([]);
    setSkillPresses(classData[selectedClass]?.Skills_to_choose || 0);

    setSelectedExpertiseSkills([]);
    setDisabledExpertiseSkills(keysOfNewFilteredSkills);
    setExpertiseSkillPresses(2);




    if (selectedClass !== "Ranger") {
      setClassProficiencies([]);
    }
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("prs")) {
      queryParams.delete("prs");
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }
    if (queryParams.has("bs")) {
      queryParams.delete("bs");
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }
    if (queryParams.has("es")) {
      queryParams.delete("es");
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }

  };


  const handleBaseSkillClick = (skill) => {
    if (disabledBaseSkills.includes(skill)) {
      return;
    }
    setSelectedBaseSkill((prevSelected) => {
      if (prevSelected === skill) {
        setSelectedSkills((prevSelectedSkills) =>
          prevSelectedSkills.filter((selectedSkill) => selectedSkill !== skill)
        );

        setDisabledSkills((prevDisabledSkills) =>
          prevDisabledSkills.filter((disabledSkill) => disabledSkill !== skill)
        );
        setDisabledExpertiseSkills((prevDisabledSkills) => {
          if (!prevDisabledSkills.includes(skill)) {
            return [...prevDisabledSkills, skill];
          } else {
            return prevDisabledSkills;
          }
        });

        setBaseSkillPresses(1);

        if (selectedExpertiseSkills.includes(skill)) {
          setExpertiseSkillPresses(expertiseSkillPresses + 1)
        }

        setSelectedExpertiseSkills((prevDisabledSkills) =>
          prevDisabledSkills.filter((disabledSkill) => disabledSkill !== skill)
        );

        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('es') && queryParams.get('es').includes(skill)) {
          const esValue = queryParams.get('es').split(',').filter(item => item !== skill).join(',');
          queryParams.set('es', esValue);
          const newSearch = queryParams.toString();
          const newUrl = location.pathname + (newSearch ? `?${newSearch}` : '');
          window.history.pushState(null, '', newUrl);
        }

        queryParams.set("bs", "")
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);
        return null;
      } else {

        setDisabledSkills((prevDisabledSkills) => {
          if (!prevDisabledSkills.includes(skill)) {
            return [...prevDisabledSkills, skill];
          }
          return prevDisabledSkills;
        });
        setDisabledSkills((prevDisabledSkills) =>
          prevDisabledSkills.filter((prevDisabledSkills) => prevDisabledSkills !== selectedBaseSkill)
        );


        setDisabledExpertiseSkills((prevDisabledSkills) => {
          // Check if the skill is already present in the array
          if (prevDisabledSkills.includes(skill)) {
            // If the skill is found, remove it from the array
            return prevDisabledSkills.filter((disabledSkill) => disabledSkill !== skill);
          } else {
            // If the skill is not found, add prevSelected to the array if it's not null and not already present
            if (prevSelected !== null && !prevDisabledSkills.includes(prevSelected)) {
              return [...prevDisabledSkills, prevSelected];
            } else {
              return prevDisabledSkills;
            }
          }
        });


        const queryParams2 = new URLSearchParams(window.location.search);
        if (queryParams2.has('es') && queryParams2.get('es').includes(prevSelected)) {
          const esValue = queryParams2.get('es').split(',').filter(item => item !== prevSelected).join(',');
          queryParams2.set('es', esValue);
          const newSearch = queryParams2.toString();
          const newUrl = location.pathname + (newSearch ? `?${newSearch}` : '');
          window.history.pushState(null, '', newUrl);
        }

        if (selectedExpertiseSkills.includes(prevSelected)) {
          setExpertiseSkillPresses(expertiseSkillPresses + 1)
        }

        setSelectedExpertiseSkills((prevDisabledSkills) =>
          prevDisabledSkills.filter((disabledSkill) => disabledSkill !== prevSelected)
        );

        setBaseSkillPresses(0)

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("bs", skill)
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return skill;
      }
    });

  };

  const handleSkillClick = (skill) => {
    if (disabledSkills.includes(skill)) {
      return;
    }

    setSelectedSkills((prevSelectedSkills) => {
      if (prevSelectedSkills.includes(skill)) {
        setDisabledBaseSkills((prevDisabledSkills) =>
          prevDisabledSkills.filter((disabledSkill) => disabledSkill !== skill)
        );

        setDisabledExpertiseSkills((prevDisabledSkills) => {
          if (!prevDisabledSkills.includes(skill)) {
            return [...prevDisabledSkills, skill];
          } else {
            return prevDisabledSkills;
          }
        });

        const updatedDisabledSkills = disabledSkills.concat(disabledBaseSkills).filter((disabledSkill) => disabledSkill !== skill);
        setSkillPresses(skillPresses + 1)

        const queryParams2 = new URLSearchParams(window.location.search);
        if (queryParams2.has('es') && queryParams2.get('es').includes(skill)) {
          const esValue = queryParams2.get('es').split(',').filter(item => item !== skill).join(',');
          queryParams2.set('es', esValue);
          const newSearch = queryParams2.toString();
          const newUrl = location.pathname + (newSearch ? `?${newSearch}` : '');
          window.history.pushState(null, '', newUrl);
        }

        if (selectedExpertiseSkills.includes(skill)) {
          setExpertiseSkillPresses(expertiseSkillPresses + 1)
        }

        setSelectedExpertiseSkills((prevDisabledSkills) =>
          prevDisabledSkills.filter((disabledSkill) => disabledSkill !== skill)
        );
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("prs", prevSelectedSkills.filter((selectedSkill) => selectedSkill !== skill))
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return prevSelectedSkills.filter((selectedSkill) => selectedSkill !== skill);
      } else {
        if (skillPresses === 0) {
          return prevSelectedSkills;
        }
        setDisabledBaseSkills((prevDisabledSkills) => {
          if (!prevDisabledSkills.includes(skill)) {
            return [...prevDisabledSkills, skill];
          }
          return prevDisabledSkills;
        });

        setDisabledExpertiseSkills((prevDisabledSkills) => {
          // Check if the skill is already present in the array
          if (prevDisabledSkills.includes(skill)) {
            // If the skill is found, remove it from the array
            return prevDisabledSkills.filter((disabledSkill) => disabledSkill !== skill);
          } else {
            // If the skill is not found, add prevSelected to the array if it's not null and not already present
            if (prevSelectedSkills !== null && !prevDisabledSkills.includes(prevSelectedSkills)) {
              return [...prevDisabledSkills, prevSelectedSkills];
            } else {
              return prevDisabledSkills;
            }
          }
        });


        const queryParams2 = new URLSearchParams(window.location.search);
        if (queryParams2.has('es') && queryParams2.get('es').includes(prevSelectedSkills)) {
          const esValue = queryParams2.get('es').split(',').filter(item => item !== prevSelectedSkills).join(',');
          queryParams2.set('es', esValue);
          const newSearch = queryParams2.toString();
          const newUrl = location.pathname + (newSearch ? `?${newSearch}` : '');
          window.history.pushState(null, '', newUrl);
        }

        if (selectedExpertiseSkills.includes(skill)) {
          setExpertiseSkillPresses(expertiseSkillPresses + 1)
        }

        const updatedDisabledSkills = disabledBaseSkills.includes(skill)
          ? disabledBaseSkills // If the skill is already present, return the current state
          : [...disabledBaseSkills, skill]; // If not present, add the new skill to the array
        setSkillPresses(skillPresses - 1)


        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("prs", [...prevSelectedSkills, skill])
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return [...prevSelectedSkills, skill];
      }
    });

  };

  const handleExpertiseSkillClick = (skill) => {
    if (disabledExpertiseSkills.includes(skill)) {
      return;
    }

    setSelectedExpertiseSkills((prevSelectedSkills) => {
      if (prevSelectedSkills.includes(skill)) {

        if (expertiseSkillPresses < 2) {
          setExpertiseSkillPresses(expertiseSkillPresses + 1)
        }

        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("es", prevSelectedSkills.filter((selectedSkill) => selectedSkill !== skill))
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return prevSelectedSkills.filter((selectedSkill) => selectedSkill !== skill);
      } else {
        if (expertiseSkillPresses === 0) {
          return prevSelectedSkills;
        }

        const updatedDisabledSkills = disabledBaseSkills.includes(skill)
          ? disabledBaseSkills // If the skill is already present, return the current state
          : [...disabledBaseSkills, skill]; // If not present, add the new skill to the array

        const updateExpSkills = selectedExpertiseSkills.includes(skill)
          ? selectedExpertiseSkills // If the skill is already present, return the current state
          : [...selectedExpertiseSkills, skill]; // If not present, add the new skill to the array
        setExpertiseSkillPresses(expertiseSkillPresses - 1)


        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set("es", [...prevSelectedSkills, skill])
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);

        return [...prevSelectedSkills, skill];
      }
    });

  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    let raceSkills; // Declare raceSkills variable before the if-else block

    if (queryParams.get("r") !== null) {
      const raceDataSkills = raceData[queryParams.get("r")].Skills;
      raceSkills = Array.isArray(raceDataSkills) ? raceDataSkills.join(', ') : null;
    } else {
      raceSkills = null;
    }

    let newMergedSkills;
    const containsName = backgroundList.includes(queryParams.get("b"));

    if (raceSkills !== 'Any' && raceSkills !== 'None' && queryParams.get("b") !== null && containsName) {
      newMergedSkills = {
        [backgroundData[queryParams.get("b")].Proficiencies_unlocked[0]]: [`Granted by ${queryParams.get("b")}`, allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[0]][1], allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[0]][0]],
        [backgroundData[queryParams.get("b")].Proficiencies_unlocked[1]]: [`Granted by ${queryParams.get("b")}`, allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[1]][1], allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[1]][0]],
      };

      if (raceSkills !== null) {
        const raceSkillsArray = raceSkills.split(',').map(skill => skill.trim());
        raceSkillsArray.forEach(skill => {
          newMergedSkills[skill] = [`Granted by ${queryParams.get("r")}`, allSkills[skill]?.[1], allSkills[skill]?.[0]];
        });
      }

      setMergedSkills(newMergedSkills);
    } else if (queryParams.get("b") != null && containsName) {
      newMergedSkills = {
        [backgroundData[queryParams.get("b")].Proficiencies_unlocked[0]]: [`Granted by ${queryParams.get("b")}`, allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[0]][1], allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[0]][0]],
        [backgroundData[queryParams.get("b")].Proficiencies_unlocked[1]]: [`Granted by ${queryParams.get("b")}`, allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[1]][1], allSkills[backgroundData[queryParams.get("b")].Proficiencies_unlocked[1]][0]],
      };

      setMergedSkills(newMergedSkills);
    }

    let newFilteredSkills = {};

    if (queryParams.get("r") != null && queryParams.get("b") != null && containsName) {
      // Inside the if statement, create the filtered skills
      newFilteredSkills = Object.keys(allSkills).reduce((result, skill) => {
        if (!(skill in newMergedSkills)) {
          result[skill] = allSkills[skill];
        }
        return result;
      }, {});
      setFilteredSkills(newFilteredSkills);
    }

    if (queryParams.get("c") in classData && classData[queryParams.get("c")].Proficiencies_to_choose && containsName) {
      const extractedSkills = classData[queryParams.get("c")].Proficiencies_to_choose;
      const matchedSkills = Object.keys(allSkills).filter(skill => extractedSkills.includes(skill));
      const updatedClassSkills = Object.fromEntries(matchedSkills.map(skill => [skill, allSkills[skill]]));

      // Remove keys from updatedClassSkills that exist in newMergedSkills
      const filteredClassSkills = {};
      for (const skillKey in updatedClassSkills) {
        if (!(skillKey in newMergedSkills)) {
          filteredClassSkills[skillKey] = updatedClassSkills[skillKey];
        }
      }
      setClassSkills(filteredClassSkills);
    } else {
      setClassSkills({});
    }

    let keysOfNewFilteredSkills = Object.keys(newFilteredSkills);


    if (isClassSelected && queryParams.has("c")) {
      if (queryParams.get("c") === "Human") {
        queryParams.set("bs", selectedBaseSkill);
        const baseUrl = window.location.href.split('?')[0];
        const newUrl = `${baseUrl}?${queryParams.toString()}`;
        window.history.pushState(null, '', newUrl);
      }

      queryParams.set("prs", selectedSkills);
      const baseUrl = window.location.href.split('?')[0];
      const newUrl = `${baseUrl}?${queryParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    }
    const queryParams4 = new URLSearchParams(window.location.search);
    if (queryParams4.has("bs") && queryParams4.has("prs") && queryParams4.has("es")) {
      const param1 = queryParams4.get('bs') || '';
      const param2 = queryParams4.get('prs') || '';
      const param3 = queryParams4.get('es') || '';

      // Convert the strings to arrays using the map function
      const initialDisabledSkills1 = param1.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use Number() if the skills are numbers
      const initialDisabledSkills2 = param2.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use skill as is if the skills are strings
      const initialDisabledSkills3 = param3.split(',').map(skill => skill.trim()).filter(skill => skill !== '');

      const filteredSkills = keysOfNewFilteredSkills.filter(
        (value) => !initialDisabledSkills1.includes(value) && !initialDisabledSkills2.includes(value)
      );

      setSelectedBaseSkill(param1);
      if (queryParams4.get("bs").length > 0) {
        setBaseSkillPresses(0);
      }
      setSelectedSkills(initialDisabledSkills2);
      setDisabledSkills(initialDisabledSkills1);
      setDisabledBaseSkills(initialDisabledSkills2);
      setSkillPresses(classData[queryParams4.get("c")].Skills_to_choose - initialDisabledSkills2.length);
      setExpertiseSkillPresses(2 - initialDisabledSkills3.length)
      setDisabledExpertiseSkills(filteredSkills)
      setSelectedExpertiseSkills(initialDisabledSkills3)
    }
    else if (queryParams4.has("bs") && queryParams4.has("prs")) {
      const param1 = queryParams4.get('bs') || '';
      const param2 = queryParams4.get('prs') || '';

      // Convert the strings to arrays using the map function
      const initialDisabledSkills1 = param1.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use Number() if the skills are numbers
      const initialDisabledSkills2 = param2.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use skill as is if the skills are strings

      setSelectedBaseSkill(param1);
      if (queryParams4.get("bs").length > 0) {
        setBaseSkillPresses(0);
      }
      setSelectedSkills(initialDisabledSkills2);
      setDisabledSkills(initialDisabledSkills1);
      setDisabledBaseSkills(initialDisabledSkills2);
      setSkillPresses(classData[queryParams4.get("c")].Skills_to_choose - initialDisabledSkills2.length);
    }
    else if (queryParams4.has("es") && queryParams4.has("prs")) {
      const param1 = queryParams4.get('es') || '';
      const param2 = queryParams4.get('prs') || '';

      // Convert the strings to arrays using the map function
      const initialDisabledSkills1 = param1.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use Number() if the skills are numbers
      const initialDisabledSkills2 = param2.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use skill as is if the skills are strings

      const filteredSkills = keysOfNewFilteredSkills.filter(
        (value) => !initialDisabledSkills2.includes(value)
      );

      setSelectedExpertiseSkills(initialDisabledSkills1);
      setExpertiseSkillPresses(2 - initialDisabledSkills1.length);
      setDisabledExpertiseSkills(filteredSkills);
      setSelectedSkills(initialDisabledSkills2);

      setSkillPresses(classData[queryParams4.get("c")].Skills_to_choose - initialDisabledSkills2.length);
      setDisabledBaseSkills(initialDisabledSkills2);
    }
    else if (queryParams4.has("es") && queryParams4.has("bs")) {
      const param1 = queryParams4.get('es') || '';
      const param2 = queryParams4.get('bs') || '';

      // Convert the strings to arrays using the map function
      const initialDisabledSkills1 = param1.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use Number() if the skills are numbers
      const initialDisabledSkills2 = param2.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use skill as is if the skills are strings

      const filteredSkills = keysOfNewFilteredSkills.filter(
        (value) => !initialDisabledSkills2.includes(value)
      );

      if (queryParams4.get("bs").length > 0) {
        setBaseSkillPresses(0);
      }
      setSelectedExpertiseSkills(initialDisabledSkills1);
      setExpertiseSkillPresses(2 - initialDisabledSkills1.length);
      setDisabledExpertiseSkills(filteredSkills);
      setDisabledSkills(initialDisabledSkills2);
      setSkillPresses(classData[queryParams4.get("c")].Skills_to_choose);
    }
    else if (queryParams4.has("bs")) {
      const queryParams2 = new URLSearchParams(window.location.search);
      const param1 = queryParams2.get('bs') || '';
      const initialDisabledSkills1 = param1.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use Number() if the skills are numbers
      const filteredSkills = keysOfNewFilteredSkills.filter(
        (value) => !initialDisabledSkills1.includes(value)
      );
      setSelectedBaseSkill(param1);
      if (queryParams2.get("bs").length > 0) {
        setBaseSkillPresses(0);
      }
      setDisabledSkills(initialDisabledSkills1);
      setDisabledExpertiseSkills(filteredSkills);
      setSkillPresses(classData[queryParams2.get("c")].Skills_to_choose);
    }
    else if (queryParams4.has("prs")) {
      const queryParams3 = new URLSearchParams(window.location.search);
      const param2 = queryParams3.get('prs') || '';
      const initialDisabledSkills2 = param2.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use skill as is if the skills are strings
      const filteredSkills = keysOfNewFilteredSkills.filter(
        (value) => !initialDisabledSkills2.includes(value)
      );
      setDisabledBaseSkills(initialDisabledSkills2);
      setSelectedSkills(initialDisabledSkills2);
      setDisabledExpertiseSkills(filteredSkills);
      setSkillPresses(classData[queryParams3.get("c")].Skills_to_choose - initialDisabledSkills2.length);
    }
    else if (queryParams4.has("es")) {
      const queryParams3 = new URLSearchParams(window.location.search);
      const param2 = queryParams3.get('es') || '';
      const initialDisabledSkills2 = param2.split(',').map(skill => skill.trim()).filter(skill => skill !== ''); // Use skill as is if the skills are strings

      setDisabledExpertiseSkills(keysOfNewFilteredSkills)
      setSkillPresses(classData[queryParams3.get("c")].Skills_to_choose);
      setExpertiseSkillPresses(2 - initialDisabledSkills2.length);
      setSelectedExpertiseSkills(initialDisabledSkills2);
    }
    else {
      if (queryParams4.has("c")) {
        setSkillPresses(classData[queryParams4.get("c")].Skills_to_choose);
      }
    }

  }, [location.search]);



  const frameStyle4 = {
    border: '2px solid white',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column', // To align the title and content vertically
    alignItems: 'center', // To center the title horizontally
    backdropFilter: 'blur(5px)', // Apply the blur effect to the background
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Add a translucent background color
    marginTop: '20px',
    marginRight: '120px',
    flex: 1,
  };

  const titleStyle = {
    marginBottom: '30px',
    padding: '5px',
    color: 'white',
    font: generalFont,
    fontSize: '24px',
  };

  const skillStyle = {
    marginBottom: '10px',
    padding: '5px',
    color: 'white',
    font: generalFont,
    fontSize: '22px',
    marginTop: '30px',
  };

  const rowStyle = {
    display: 'flex', // Use flex display to have both elements in the same row
    justifyContent: 'space-between', // To evenly distribute elements in the row
    marginBottom: '80px', // Optional spacing between rows
  };

  const rowStyle2 = {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '-80px',
    fontSize: '20px',
    font: generalFont,
  };

  const skillStyle2 = {
    marginBottom: '40px',
    padding: '5px',
    color: 'white',
    font: generalFont,
    fontSize: '18px',
    marginTop: '30px',
  };

  const columnContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: "100px",
    rowGap: "20px",
    marginBottom: '100px', // Adjust this value to reduce or increase the space between rows
  };

  const rowContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // Divide the container into two equal-width columns
    columnGap: '250px', // Adjust this value to change the space between columns
  };

  const skillStyle3 = {
    marginBottom: '-40px',
    padding: '5px',
    color: 'white',
    font: generalFont,
    fontSize: '18px',
    marginTop: '30px',
  };

  const buttonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    marginRight: '10px',
    borderRadius: '50%',
    backgroundColor: 'transparent',
    border: '2px solid white',
    cursor: 'pointer',
  };

  const AttributesFrame = (
    isClassSelected === true && (
      <div style={frameStyle4}>
        <div style={titleStyle}><span style={{ color: '#FFBF00' }}>Select Skills</span></div>
        <div style={{ ...skillStyle, marginBottom: "-10px", marginTop: "-35px", marginBottom: "-20px" }}>
          Proficiency Bonus from Class <span style={{ color: "red" }}>+{classData[selectedClass].Proficiency_bonus}</span>
        </div>
        <div style={skillStyle}><span style={{ color: '#FFBF00' }}>Inherited Skills</span></div>
        <>
          {isBackgroundSelected && isRaceSelected && (
            <div style={{ marginBottom: "60px", marginTop: "20px", font: generalFont, fontSize: "18px", color: "white" }}>
              {/* Render each skill and its corresponding text in a separate row */}
              <div>
                {/* JavaScript code inside the div */}
                {(() => {
                  const asdfzxcv = { ...mergedSkills }; // Create a copy of mergedSkills object

                  // Iterate through classProficiencies and update the allSkills object
                  classProficiencies.forEach((proficiency) => {
                    if (!asdfzxcv.hasOwnProperty(proficiency) && className === "Ranger") {
                      asdfzxcv[proficiency] = [`Granted by ${className}`, allSkills[proficiency][1], allSkills[proficiency][0]];
                    }
                  });

                  // Check if the selected race is "Rock Gnome" and if the "History" skill exists
                  const isRockGnome = selectedRaceData === "Rock Gnome";
                  const hasHistorySkill = asdfzxcv.hasOwnProperty("History");

                  // Generate the JSX using the updated allSkills object
                  return Object.entries(asdfzxcv).map(([skill, [text, abbreviation, name]], index) => {
                    // Calculate the base proficiency bonus
                    const baseProficiency = classData[selectedClass]?.Proficiency_bonus + abilityScoresData.find((item) => item.name === name)?.proficiencyScore[0];

                    // If both conditions are met, add a +2 Proficiency bonus to the "History" skill
                    const finalProficiency = isRockGnome && hasHistorySkill && skill === "History" ? baseProficiency + 2 : baseProficiency;

                    return (
                      <div key={index} style={rowContainerStyle}>
                        {/* First column with skill and proficiency information */}
                        <div>
                          <span style={{ color: "#D2B48C" }}>{skill}</span>
                          &nbsp;+{finalProficiency}({abbreviation})
                        </div>
                        {/* Second column with text */}
                        <div style={{ color: "#3ba9bc" }}>{text}</div>
                      </div>
                    );
                  });
                })()}
              </div>
            </div>
          )}

        </>
        {raceData[selectedRaceData].Skills.toString() === 'Any' && (
          <>
            <div style={{ ...skillStyle, marginTop: "-35px" }}><span style={{ color: '#FFBF00' }}>Base Skills</span> ({baseSkillPresses}/1)</div>
            <div style={columnContainerStyle}>
              {/* JavaScript code inside the div */}
              {Object.entries(filteredSkills)
                .filter(([skill]) => !classProficiencies.includes(skill)) // Filter out the unwanted keys
                .map(([skill, [ability, abbreviation]], index) => (
                  !disabledBaseSkills.includes(skill) && (
                    <div style={{ ...skillStyle3, display: 'flex', alignItems: 'center' }} key={index} onClick={() => handleBaseSkillClick(skill)}>
                      <div
                        style={{
                          ...buttonStyle,
                          marginRight: '10px',
                          backgroundColor: selectedBaseSkill === skill ? 'white' : 'transparent',
                        }}
                      />
                      <div style={{ cursor: 'pointer', color: selectedBaseSkill === skill ? '#D2B48C' : 'inherit' }}>
                        {skill}
                      </div>
                      <div style={{ font: generalFont, fontSize: "16px" }}>
                        &nbsp;+{classData[selectedClass]?.Proficiency_bonus + abilityScoresData.find(item => item.name === ability)?.proficiencyScore[0]}({abbreviation})
                      </div>
                    </div>
                  )
                ))}
            </div>
          </>
        )}
        {className === "Rogue" && (
          <>
            <div style={{ ...skillStyle, marginTop: "-35px" }}>
              <span style={{ color: '#FFBF00' }}>Expertise Skills</span> ({expertiseSkillPresses}/2)
            </div>
            <div style={columnContainerStyle}>
              {/* JavaScript code inside the div */}
              {Object.entries(allSkills)
                .map(([skill, [ability, abbreviation]], index) => {
                  // Check if the skill is not in disabledExpertiseSkills and if the conditions for "Rock Gnome" and "History" are met
                  const isDisabledSkill = disabledExpertiseSkills.includes(skill);
                  const isRockGnome = selectedRaceData === "Rock Gnome";
                  const hasHistorySkill = skill === "History" && mergedSkills.hasOwnProperty(skill);

                  // If the conditions are met, exclude it from the object
                  if (isDisabledSkill || (isRockGnome && hasHistorySkill)) {
                    return null;
                  }

                  return (
                    <div style={{ ...skillStyle3, display: 'flex', alignItems: 'center' }} key={index} onClick={() => handleExpertiseSkillClick(skill)}>
                      <div
                        style={{
                          ...buttonStyle,
                          marginRight: '10px',
                          backgroundColor: selectedExpertiseSkills.includes(skill) ? 'white' : 'transparent',
                        }}
                      />
                      <div style={{ cursor: 'pointer', color: selectedExpertiseSkills.includes(skill) ? '#D2B48C' : 'inherit' }}>
                        {skill}
                      </div>
                      <div style={{ font: generalFont, fontSize: "16px" }}>
                        &nbsp;+{classData[selectedClass]?.Proficiency_bonus + abilityScoresData.find(item => item.name === ability)?.proficiencyScore[0] + 2}({abbreviation})
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        {isClassSelected && (
          <>
            <div style={{ ...skillStyle, marginTop: "-35px", marginBottom: "-20px" }}><span style={{ color: '#FFBF00' }}>Skills with Proficiency</span> ({skillPresses}/{classData[selectedClass]?.Skills_to_choose})</div>
            <div style={columnContainerStyle}>
              {Object.entries(classSkills)
                .filter(([skill]) => !classProficiencies.includes(skill)) // Filter out the unwanted keys
                .map(([skill, [ability, abbreviation]], index) => (
                  !disabledSkills.includes(skill) && (
                    <div style={{ ...skillStyle3, display: 'flex', alignItems: 'center' }} key={index} onClick={() => handleSkillClick(skill)}>
                      <div
                        style={{
                          ...buttonStyle,
                          marginRight: '10px',
                          backgroundColor: selectedSkills.includes(skill) ? 'white' : 'transparent',
                        }}
                      />
                      <div style={{ cursor: 'pointer', color: selectedSkills.includes(skill) ? '#D2B48C' : 'inherit' }}>
                        {skill}
                      </div>
                      <div style={{ font: generalFont, fontSize: "16px" }}>
                        &nbsp;+{classData[selectedClass]?.Proficiency_bonus + abilityScoresData.find(item => item.name === ability)?.proficiencyScore[0]}({abbreviation})
                      </div>
                    </div>
                  )
                ))}
            </div>
          </>
        )}
      </div >
    )
  );

  /* ATTRIBUTES END */
  /* SKILLS AND ATTRIBUTES END */

  /* SUMMARY START */

  const containerStyle3 = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column', // Update to 'row' to place the frames side by side
    alignItems: 'center',
    justifyContent: 'center', // Update to center the frames horizontally
    padding: '20px',
    maxWidth: '100vw', // Set the maximum width to the viewport width
    paddingBottom: '70px',
  };

  const frameStyle5 = {
    border: '2px solid white',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column', // To align the title and content vertically
    alignItems: 'center', // To center the title horizontally
    backdropFilter: 'blur(5px)', // Apply the blur effect to the background
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Add a translucent background color
    marginTop: '20px',
    flex: 0.5, // Decrease the flex value to make the frame smaller
  };

  const columnContainerStyle2 = {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    marginBottom: '100px', // Adjust this value to reduce or increase the space between rows
  };

  const mergedKeys = [
    ...new Set(
      Object.keys(mergedSkills)
        .concat(selectedSkills)
        .concat(classProficiencies)
        .concat(selectedExpertiseSkills)
        .concat(selectedBaseSkill ? selectedBaseSkill : [])
    ),
  ].filter((element) => element !== '');

  if (skillPresses < classData[selectedClass]?.Skills_to_choose && selectedSkills.length == 1 && selectedSkills[0] == "") {
    setSkillPresses(classData[selectedClass]?.Skills_to_choose);
    setSelectedSkills([]);
    setClassProficiencies([]);
  }

  // Now `mergedKeys` will contain the elements without any empty strings.


  const SummaryFrame = (
    isClassSelected === true && (
      <div>
        <div style={{ ...frameStyle5, backgroundColor: 'rgba(0, 0, 0, 0.75)' }}>
          <div style={titleStyle}><span style={{ color: '#FFBF00', fontSize: "26px" }}>Character Summary</span></div>
          <div style={{ ...titleStyle, marginBottom: '-5px' }}><span style={{ fontSize: "22px" }}>{name}</span></div>
          <div style={{ ...titleStyle, marginBottom: '-5px' }}><span style={{ fontSize: "22px", color: '#abf8f5' }}>
            {selectedSecondaryButton ? selectedSecondaryButton : selectedButton}&nbsp;{className}
          </span></div>
          <div>
            <img src={raceImages[selectedButton.toLowerCase().trim(" ").replace("-", "")]} style={{ width: "125px", height: "125px" }} alt={raceImages[selectedButton.toLowerCase().trim(" ").replace("-", "")]} />
            <img src={classLogo[className.toLowerCase()]} alt={className} />
          </div>
          <div style={{ ...titleStyle, marginTop: "30px", color: '#FFBF00' }}><span style={{ fontSize: "22px" }}>
            Abilities
          </span></div>
          <div style={columnContainerStyle2}>
            {abilityScoresData.map(({ name, state: [abilityPoints, setAbilityPoints], withoutBonus: [abilityPointsWithoutBonus, setAbilityPointsWithoutBonus], proficiencyScore: [proficiency, setProficiency], numberPressed: [presses, setPresses] }) => (
              <div key={name} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                {/* Picture on the top */}
                <div style={{ marginBottom: '10px' }}>
                  <img src={abilityScoreNamesAndImages[name]} alt="Your Image" style={{ width: '100px', height: '100px', marginBottom: "-20px" }} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {classMainStat[selectedClass] === name && (
                    <span style={{ marginRight: '5px', fontSize: '24px', fontWeight: 'bold', font: generalFont, color: 'white' }}>
                      ★
                    </span>
                  )}
                  <p style={{ margin: '0', fontSize: '24px', font: generalFont, color: '#D2B48C' }}>
                    {name.slice(0, 3).toUpperCase()}
                  </p>
                </div>

                <div style={{ fontSize: '23px', color: 'white' }}>
                  {abilityPoints}
                </div>
              </div>
            ))}
          </div>
          <div style={{ ...titleStyle, marginTop: '-80px', marginBottom: '0px', color: '#FFBF00' }}>
            <span style={{ fontSize: "22px" }}>
              Proficiencies
            </span>
          </div>
          {Object.values(mergedKeys).map((skillValue, index) => {
            const isExpertiseSkill = selectedExpertiseSkills.includes(skillValue);

            const isGnomeExpertiseSkill = skillValue === "History" && selectedRaceData === "Rock Gnome";

            // Check if selectedClass is valid and has Proficiency_bonus property
            const classProficiencyBonus = classData[selectedClass]?.Proficiency_bonus || 0;

            // Find the ability score item based on the name, if it exists
            const abilityScoreItem = abilityScoresData.find(
              (item) => item.name === (allSkills[skillValue] && allSkills[skillValue][0])
            );
            // Get the proficiencyScore from the ability score item, or default to 0
            const abilityScoreProficiency = abilityScoreItem?.proficiencyScore?.[0] || 0;

            // Calculate proficiencyBonus by adding classProficiencyBonus and abilityScoreProficiency
            const proficiencyBonus = classProficiencyBonus + abilityScoreProficiency;

            let adjustedProficiencyBonus = isExpertiseSkill
              ? proficiencyBonus + 2
              : proficiencyBonus;

            if (isGnomeExpertiseSkill) {
              adjustedProficiencyBonus = proficiencyBonus + 2 // Adjust the bonus further as needed for Gnome-specific skills.
            }

            return (
              <div key={index} style={{ fontSize: '18px', color: '#D2B48C' }}>
                {skillValue}
                <span style={{ color: 'white' }}>
                  &nbsp;+{adjustedProficiencyBonus}({allSkills[skillValue]?.[1] || 'N/A'})
                </span>
                <div style={{ font: generalFont, fontSize: "16px" }}>
                  {/* Content for the inner div */}
                </div>
              </div>
            );



          })}

          <div style={{ ...titleStyle, marginTop: "20px" }}>
            {(() => {
              const searchParams = new URLSearchParams(window.location.search);
              const name_of_race = searchParams.get('r');
              const name_of_class = searchParams.get('c');
              const name_of_subclass = searchParams.get('sc');

              let cantrips_list_race = [];
              let actions_list_class = [];
              let actions_list_subclass = [];
              let cantrips_list_class = [];
              let spells_list_class = [];
              let race_actions_list = [];

              if (name_of_race === "High Elf" || name_of_race === "High Half-Elf") {
                if (searchParams.has('rc'))
                  cantrips_list_race.push(searchParams.get('rc'));
              }
              else if (name_of_race === "Asmodeus Tiefling") {
                cantrips_list_race.push("Produce Flame");
              }
              else if (name_of_race === "Mephistopheles Tiefling") {
                cantrips_list_race.push("Mage Hand");
              }
              else if (name_of_race === "Zariel Tiefling") {
                cantrips_list_race.push("Thaumaturgy");
              }
              else if (name_of_race === "Drow Half-Elf") {
                cantrips_list_race.push("Dancing Lights drow");
              }
              else if (name_of_race === "Githyanki") {
                cantrips_list_race.push("Mage Hand gith");
              }
              else if (name_of_race === "Forest Gnome") {
                cantrips_list_race.push("Speak with Animals");
              }
              else if (name_of_race === "Black Dragonborn" || name_of_race === "Copper Dragonborn") {
                race_actions_list.push("Acid Breath");
              }
              else if (name_of_race === "Blue Dragonborn" || name_of_race === "Bronze Dragonborn") {
                race_actions_list.push("Lightning Breath");
              }
              else if (name_of_race === "Brass Dragonborn" || name_of_race === "Gold Dragonborn" || name_of_race === "Red Dragonborn") {
                race_actions_list.push("Fire Breath");
              }
              else if (name_of_race === "Green Dragonborn") {
                race_actions_list.push("Poison Breath");
              }
              else if (name_of_race === "Silver Dragonborn" || name_of_race === "White Dragonborn") {
                race_actions_list.push("Frost Breath");
              }

              if (name_of_class === "Barbarian") {
                actions_list_class.push("Rage");
              }
              else if (name_of_class === "Bard") {
                actions_list_class.push("Combat_Inspiration");
              }
              else if (name_of_class === "Cleric" && searchParams.get("sc") === "Trickery Domain") {
                actions_list_subclass.push("Blessing of the Trickster");
              }
              else if (name_of_class === "Fighter") {
                actions_list_class.push("Second Wind");
              }
              else if (name_of_class === "Monk") {
                actions_list_class.push("Flurry of Blows");
              }
              else if (name_of_class === "Paladin") {
                actions_list_class.push("Lay on Hands");
                actions_list_class.push("Divine Sense");
                if (searchParams.get("sc") === "Oath of the Ancients") {
                  actions_list_subclass.push("Healing Radiance");
                }
                else if (searchParams.get("sc") === "Oath of Devotion") {
                  actions_list_subclass.push("Holy Rebuke");
                }
                else if (searchParams.get("sc") === "Oath of Vengeance") {
                  actions_list_subclass.push("Inquisitor Might");
                }
                else if (searchParams.get("sc") === "Oathbreaker") {
                  actions_list_subclass.push("Spiteful Suffering");
                }
              }
              else if (name_of_class === "Rogue") {
                actions_list_class.push("Sneak Attack (Melee)");
                actions_list_class.push("Sneak Attack (Range)");
              }
              else if (name_of_class === "Warlock") {
                if (searchParams.get("sc") === "Archfey") {
                  actions_list_subclass.push("Fey Presence");
                }
              }
              else if (name_of_class === "Wizard") {
                actions_list_class.push("Arcane Recovery");
              }

              if ((name_of_class === "Bard" || name_of_class === "Cleric" || name_of_class === "Druid" || name_of_class === "Sorcerer" || name_of_class === "Warlock" || name_of_class === "Wizard") && searchParams.has("cc")) {
                cantrips_list_class.push(...searchParams.get('cc').split(","));
              }
              if (name_of_class === "Cleric") {
                if (name_of_subclass === "Light Domain")
                  cantrips_list_class.push("Light");
                if (name_of_subclass === "Nature Domain" && searchParams.has('ccs')) {
                  cantrips_list_class.push(searchParams.get('ccs'));
                }
              }

              if ((name_of_class === "Bard" || name_of_class === "Sorcerer" || name_of_class === "Warlock" || name_of_class === "Wizard") && searchParams.has("cs")) {
                spells_list_class.push(...searchParams.get('cs').split(","));
              }

              if (name_of_class === "Sorcerer" && name_of_subclass === "Draconic Bloodline") {
                if (searchParams.get("sb") === "Red (Fire)") {
                  spells_list_class.push("Burning Hands");
                }
                else if (searchParams.get("sb") === "Black (Acid)") {
                  spells_list_class.push("Grease");
                }
                else if (searchParams.get("sb") === "Blue (Lightning)") {
                  spells_list_class.push("Witch Bolt");
                }
                else if (searchParams.get("sb") === "White (Cold)") {
                  spells_list_class.push("Armor of Agathys");
                }
                else if (searchParams.get("sb") === "Green (Poison)") {
                  spells_list_class.push("Ray of Sickness");
                }
                else if (searchParams.get("sb") === "Gold (Fire)") {
                  spells_list_class.push("Disguise Self");
                }
                else if (searchParams.get("sb") === "Silver (Cold)") {
                  spells_list_class.push("Feather Fall");
                }
                else if (searchParams.get("sb") === "Bronze (Lightning)") {
                  spells_list_class.push("Fog Cloud");
                }
                else if (searchParams.get("sb") === "Copper (Acid)") {
                  spells_list_class.push("Tasha's Hideous Laughter");
                }
                else if (searchParams.get("sb") === "Brass (Fire)") {
                  spells_list_class.push("Sleep");
                }
              }

              if (name_of_class === "Ranger") {
                if (searchParams.get("fe") === "Keeper of the Veil" || searchParams.get("fe") === "Mage Breaker" || searchParams.get("fe") === "Sanctified Stalker")
                  spells_list_class.push(...searchParams.get('fe').split(","));
                if (searchParams.get("ne") === "Beast Tamer")
                  spells_list_class.push(...searchParams.get('ne').split(","));
              }

              // Add a container div with a flex display to wrap both sets of images
              const combinedImagesArray = (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                  {/* Title for Race Actions */}
                  {race_actions_list.length > 0 ? (
                    <div style={{ fontSize: '22px', marginBottom: '20px', marginTop: "10px", color: '#FFBF00' }}>Race Actions</div>
                  ) : null}

                  {/* Race Actions */}
                  <div style={{ display: 'flex', marginLeft: "90px" }}>
                    {race_actions_list.length > 0 ? (
                      race_actions_list.map((cantrip, index) => (
                        <div key={index} className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                          <img
                            src={raceCantripsSpecific[cantrip][0]}
                            style={{ width: "50%", height: "auto", background: "black", padding: "5px", border: "3px solid gray" }}
                            alt="Cantrip Image"
                          />
                          <div className="tooltip-content">
                            <img src={raceCantripsSpecific[cantrip][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                          </div>
                        </div>
                      ))
                    ) : null}
                  </div>

                  {actions_list_class.length > 0 ? (
                    <div style={{ fontSize: '22px', marginBottom: '20px', marginTop: "30px", color: '#FFBF00' }}>Class Actions</div>
                  ) : null}

                  {/* Spells */}
                  <div style={{ display: 'flex', marginLeft: "90px" }}>
                    {actions_list_class.map((spell, index) => (
                      <div key={index} className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                        <img
                          src={classActions[spell][0]}
                          style={{
                            width: "50%",
                            height: "auto",
                            background: "black",
                            padding: "5px",
                            border: "3px solid gray",
                          }}
                          alt="spell Image"
                        />
                        <div className="tooltip-content">
                          <img src={classActions[spell][1]} style={{ width: "175%", height: "175%" }} alt="spell Tooltip" />
                        </div>
                      </div>
                    ))}
                  </div>

                  {actions_list_subclass.length > 0 ? (
                    <div style={{ fontSize: '22px', marginBottom: '20px', marginTop: "30px", color: '#FFBF00' }}>Subclass Actions</div>
                  ) : null}

                  {/* Spells */}
                  <div style={{ display: 'flex', marginLeft: "90px" }}>
                    {actions_list_subclass.map((spell, index) => (
                      <div key={index} className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                        <img
                          src={subclassActions[spell][0]}
                          style={{
                            width: "50%",
                            height: "auto",
                            background: "black",
                            padding: "5px",
                            border: "3px solid gray",
                          }}
                          alt="spell Image"
                        />
                        <div className="tooltip-content">
                          <img src={subclassActions[spell][1]} style={{ width: "175%", height: "175%" }} alt="spell Tooltip" />
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Title for Cantrips */}
                  {cantrips_list_race.length > 0 || cantrips_list_class.length > 0 ? (
                    <div style={{ fontSize: '22px', marginBottom: '20px', marginTop: "10px", color: '#FFBF00' }}>Cantrips</div>
                  ) : null}

                  {/* Cantrips */}
                  <div style={{ display: 'flex', marginLeft: "90px" }}>
                    {cantrips_list_race.length > 0 ? (
                      cantrips_list_race.map((cantrip, index) => (
                        <div key={index} className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                          <img
                            src={raceCantrips2[cantrip][0]}
                            style={{ width: "50%", height: "auto", background: "black", padding: "5px", border: "3px solid gray" }}
                            alt="Cantrip Image"
                          />
                          <div className="tooltip-content">
                            <img src={raceCantrips2[cantrip][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                          </div>
                        </div>
                      ))
                    ) : null}
                    {cantrips_list_class.map((cantrip, index) => (
                      <div key={index} className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                        <img
                          src={classCantrips[cantrip][0]}
                          style={{
                            width: "50%",
                            height: "auto",
                            background: "black",
                            padding: "5px",
                            border: "3px solid gray",
                          }}
                          alt="Cantrip Image"
                        />
                        <div className="tooltip-content">
                          <img src={classCantrips[cantrip][1]} style={{ width: "175%", height: "175%" }} alt="Cantrip Tooltip" />
                        </div>
                      </div>
                    ))}
                  </div>


                  {/* Title for Spells */}
                  {className !== "Ranger" && spells_list_class.length > 0 ? (
                    <div style={{ fontSize: '22px', marginBottom: '20px', marginTop: "30px", color: '#FFBF00' }}>Spells</div>
                  ) : null}

                  {/* Spells */}
                  {className !== "Ranger" && (
                    <div style={{ display: 'flex', marginLeft: "90px" }}>
                      {spells_list_class.map((spell, index) => (
                        <div key={index} className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                          <img
                            src={classSpells[spell][0]}
                            style={{
                              width: "50%",
                              height: "auto",
                              background: "black",
                              padding: "5px",
                              border: "3px solid gray",
                            }}
                            alt="spell Image"
                          />
                          <div className="tooltip-content">
                            <img src={classSpells[spell][1]} style={{ width: "175%", height: "175%" }} alt="spell Tooltip" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {className === "Ranger" && spells_list_class.length > 0 ? (
                    <div style={{ fontSize: '22px', marginBottom: '20px', marginTop: "30px", color: '#FFBF00' }}>Ranger Spells/Cantrips</div>
                  ) : null}
                  {className === "Ranger" && (
                    <div style={{ display: 'flex', marginLeft: "90px" }}>
                      {spells_list_class.map((spell, index) => (
                        <div key={index} className="cantrip-button-wrapper" style={{ position: 'relative' }}>
                          <img
                            src={rangerCantripsSpells[spell][0]}
                            style={{
                              width: "50%",
                              height: "auto",
                              background: "black",
                              padding: "5px",
                              border: "3px solid gray",
                            }}
                            alt="spell Image"
                          />
                          <div className="tooltip-content">
                            <img src={rangerCantripsSpells[spell][1]} style={{ width: "175%", height: "175%" }} alt="spell Tooltip" />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );

              return (
                <div>
                  {combinedImagesArray}
                </div>
              ); // Return the imagesArray here to render it in the JSX
            })()}
          </div>
        </div>
      </div>
    )
  );


  const [classesAllLevels, setClassesAllLevels] = useState({});
  const [level1Class, setLevel1Class] = useState(null);
  const [level2Class, setLevel2Class] = useState("-2");
  const [level3Class, setLevel3Class] = useState("-3");
  const [level4Class, setLevel4Class] = useState("-4");
  const [level5Class, setLevel5Class] = useState("-5");
  const [level6Class, setLevel6Class] = useState("-6");
  const [level7Class, setLevel7Class] = useState("-7");
  const [level8Class, setLevel8Class] = useState("-8");
  const [level9Class, setLevel9Class] = useState("-9");
  const [level10Class, setLevel10Class] = useState("-10");
  const [level11Class, setLevel11Class] = useState("-11");
  const [level12Class, setLevel12Class] = useState("-12");

  const getClassKey = (name_of_class, level) => {
    const defaultValue = 'lvl1'; // Default variable name if level2Class is not in the dictionary
    const classKey = classesAllLevels[name_of_class]; // Get the associated value from the dictionary

    let classesAllLevelsChanged = { ...classesAllLevels };

    if (level === 2) {
      if (level3Class in classesAllLevelsChanged) {
        const level3Value = classesAllLevelsChanged[level3Class];
        if (level3Value > 1) {
          classesAllLevelsChanged[level3Class] = level3Value - 1;
        } else {
          delete classesAllLevelsChanged[level3Class];
        }
      }

      if (level4Class in classesAllLevelsChanged) {
        const level4Value = classesAllLevelsChanged[level4Class];
        if (level4Value > 1) {
          classesAllLevelsChanged[level4Class] = level4Value - 1;
        } else {
          delete classesAllLevelsChanged[level4Class];
        }
      }

      if (level5Class in classesAllLevelsChanged) {
        const level5Value = classesAllLevelsChanged[level5Class];
        if (level5Value > 1) {
          classesAllLevelsChanged[level5Class] = level5Value - 1;
        } else {
          delete classesAllLevelsChanged[level5Class];
        }
      }

      if (level6Class in classesAllLevelsChanged) {
        const level6Value = classesAllLevelsChanged[level6Class];
        if (level6Value > 1) {
          classesAllLevelsChanged[level6Class] = level6Value - 1;
        } else {
          delete classesAllLevelsChanged[level6Class];
        }
      }

      if (level7Class in classesAllLevelsChanged) {
        const level7Value = classesAllLevelsChanged[level7Class];
        if (level7Value > 1) {
          classesAllLevelsChanged[level7Class] = level7Value - 1;
        } else {
          delete classesAllLevelsChanged[level7Class];
        }
      }

      if (level8Class in classesAllLevelsChanged) {
        const level8Value = classesAllLevelsChanged[level8Class];
        if (level8Value > 1) {
          classesAllLevelsChanged[level8Class] = level8Value - 1;
        } else {
          delete classesAllLevelsChanged[level8Class];
        }
      }

      if (level9Class in classesAllLevelsChanged) {
        const level9Value = classesAllLevelsChanged[level9Class];
        if (level9Value > 1) {
          classesAllLevelsChanged[level9Class] = level9Value - 1;
        } else {
          delete classesAllLevelsChanged[level9Class];
        }
      }

      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }

      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 3) {

      if (level4Class in classesAllLevelsChanged) {
        const level4Value = classesAllLevelsChanged[level4Class];
        if (level4Value > 1) {
          classesAllLevelsChanged[level4Class] = level4Value - 1;
        } else {
          delete classesAllLevelsChanged[level4Class];
        }
      }

      if (level5Class in classesAllLevelsChanged) {
        const level5Value = classesAllLevelsChanged[level5Class];
        if (level5Value > 1) {
          classesAllLevelsChanged[level5Class] = level5Value - 1;
        } else {
          delete classesAllLevelsChanged[level5Class];
        }
      }

      if (level6Class in classesAllLevelsChanged) {
        const level6Value = classesAllLevelsChanged[level6Class];
        if (level6Value > 1) {
          classesAllLevelsChanged[level6Class] = level6Value - 1;
        } else {
          delete classesAllLevelsChanged[level6Class];
        }
      }

      if (level7Class in classesAllLevelsChanged) {
        const level7Value = classesAllLevelsChanged[level7Class];
        if (level7Value > 1) {
          classesAllLevelsChanged[level7Class] = level7Value - 1;
        } else {
          delete classesAllLevelsChanged[level7Class];
        }
      }

      if (level8Class in classesAllLevelsChanged) {
        const level8Value = classesAllLevelsChanged[level8Class];
        if (level8Value > 1) {
          classesAllLevelsChanged[level8Class] = level8Value - 1;
        } else {
          delete classesAllLevelsChanged[level8Class];
        }
      }

      if (level9Class in classesAllLevelsChanged) {
        const level9Value = classesAllLevelsChanged[level9Class];
        if (level9Value > 1) {
          classesAllLevelsChanged[level9Class] = level9Value - 1;
        } else {
          delete classesAllLevelsChanged[level9Class];
        }
      }

      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }

      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 4) {

      if (level5Class in classesAllLevelsChanged) {
        const level5Value = classesAllLevelsChanged[level5Class];
        if (level5Value > 1) {
          classesAllLevelsChanged[level5Class] = level5Value - 1;
        } else {
          delete classesAllLevelsChanged[level5Class];
        }
      }

      if (level6Class in classesAllLevelsChanged) {
        const level6Value = classesAllLevelsChanged[level6Class];
        if (level6Value > 1) {
          classesAllLevelsChanged[level6Class] = level6Value - 1;
        } else {
          delete classesAllLevelsChanged[level6Class];
        }
      }

      if (level7Class in classesAllLevelsChanged) {
        const level7Value = classesAllLevelsChanged[level7Class];
        if (level7Value > 1) {
          classesAllLevelsChanged[level7Class] = level7Value - 1;
        } else {
          delete classesAllLevelsChanged[level7Class];
        }
      }

      if (level8Class in classesAllLevelsChanged) {
        const level8Value = classesAllLevelsChanged[level8Class];
        if (level8Value > 1) {
          classesAllLevelsChanged[level8Class] = level8Value - 1;
        } else {
          delete classesAllLevelsChanged[level8Class];
        }
      }

      if (level9Class in classesAllLevelsChanged) {
        const level9Value = classesAllLevelsChanged[level9Class];
        if (level9Value > 1) {
          classesAllLevelsChanged[level9Class] = level9Value - 1;
        } else {
          delete classesAllLevelsChanged[level9Class];
        }
      }

      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 5) {
      if (level6Class in classesAllLevelsChanged) {
        const level6Value = classesAllLevelsChanged[level6Class];
        if (level6Value > 1) {
          classesAllLevelsChanged[level6Class] = level6Value - 1;
        } else {
          delete classesAllLevelsChanged[level6Class];
        }
      }

      if (level7Class in classesAllLevelsChanged) {
        const level7Value = classesAllLevelsChanged[level7Class];
        if (level7Value > 1) {
          classesAllLevelsChanged[level7Class] = level7Value - 1;
        } else {
          delete classesAllLevelsChanged[level7Class];
        }
      }

      if (level8Class in classesAllLevelsChanged) {
        const level8Value = classesAllLevelsChanged[level8Class];
        if (level8Value > 1) {
          classesAllLevelsChanged[level8Class] = level8Value - 1;
        } else {
          delete classesAllLevelsChanged[level8Class];
        }
      }

      if (level9Class in classesAllLevelsChanged) {
        const level9Value = classesAllLevelsChanged[level9Class];
        if (level9Value > 1) {
          classesAllLevelsChanged[level9Class] = level9Value - 1;
        } else {
          delete classesAllLevelsChanged[level9Class];
        }
      }

      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 6) {
      if (level7Class in classesAllLevelsChanged) {
        const level7Value = classesAllLevelsChanged[level7Class];
        if (level7Value > 1) {
          classesAllLevelsChanged[level7Class] = level7Value - 1;
        } else {
          delete classesAllLevelsChanged[level7Class];
        }
      }

      if (level8Class in classesAllLevelsChanged) {
        const level8Value = classesAllLevelsChanged[level8Class];
        if (level8Value > 1) {
          classesAllLevelsChanged[level8Class] = level8Value - 1;
        } else {
          delete classesAllLevelsChanged[level8Class];
        }
      }

      if (level9Class in classesAllLevelsChanged) {
        const level9Value = classesAllLevelsChanged[level9Class];
        if (level9Value > 1) {
          classesAllLevelsChanged[level9Class] = level9Value - 1;
        } else {
          delete classesAllLevelsChanged[level9Class];
        }
      }

      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 7) {
      if (level8Class in classesAllLevelsChanged) {
        const level8Value = classesAllLevelsChanged[level8Class];
        if (level8Value > 1) {
          classesAllLevelsChanged[level8Class] = level8Value - 1;
        } else {
          delete classesAllLevelsChanged[level8Class];
        }
      }

      if (level9Class in classesAllLevelsChanged) {
        const level9Value = classesAllLevelsChanged[level9Class];
        if (level9Value > 1) {
          classesAllLevelsChanged[level9Class] = level9Value - 1;
        } else {
          delete classesAllLevelsChanged[level9Class];
        }
      }

      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 8) {
      if (level9Class in classesAllLevelsChanged) {
        const level9Value = classesAllLevelsChanged[level9Class];
        if (level9Value > 1) {
          classesAllLevelsChanged[level9Class] = level9Value - 1;
        } else {
          delete classesAllLevelsChanged[level9Class];
        }
      }

      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 9) {
      if (level10Class in classesAllLevelsChanged) {
        const level10Value = classesAllLevelsChanged[level10Class];
        if (level10Value > 1) {
          classesAllLevelsChanged[level10Class] = level10Value - 1;
        } else {
          delete classesAllLevelsChanged[level10Class];
        }
      }

      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 10) {
      if (level11Class in classesAllLevelsChanged) {
        const level11Value = classesAllLevelsChanged[level11Class];
        if (level11Value > 1) {
          classesAllLevelsChanged[level11Class] = level11Value - 1;
        } else {
          delete classesAllLevelsChanged[level11Class];
        }
      }

      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    else if (level === 11) {
      if (level12Class in classesAllLevelsChanged) {
        const level12Value = classesAllLevelsChanged[level12Class];
        if (level12Value > 1) {
          classesAllLevelsChanged[level12Class] = level12Value - 1;
        } else {
          delete classesAllLevelsChanged[level12Class];
        }
      }
      
      const classKey2 = classesAllLevelsChanged[name_of_class];
      return `lvl${classKey2}`;
    }

    if (classKey) {
      return `lvl${classKey}`;
    }

    return defaultValue;
  };

  const handleLevel2 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-2") {
        // If event.target.value is -2, subtract -1 from level2Class if it exists, or delete it if it's already 0
        if (level2Class in updatedState) {
          setLevel2Class(event.target.value)
          const currentParams = new URLSearchParams(window.location.search);
          currentParams.delete('lvl2');
          const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level2Value = updatedState[level2Class];
          if (level2Value > 1) {
            updatedState[level2Class] = level2Value - 1;
          } else {
            delete updatedState[level2Class];
          }
        }
        if (level3Class in updatedState) {
          setLevel3Class("-3");
          const currentParams3 = new URLSearchParams(window.location.search);
          currentParams3.delete('lvl3');
          const newUrl = `${window.location.pathname}?${currentParams3.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level3Value = updatedState[level3Class];
          if (level3Value > 1) {
            updatedState[level3Class] = level3Value - 1;
          } else {
            delete updatedState[level3Class];
          }
        }
        if (level4Class in updatedState) {
          setLevel4Class("-4");
          const currentParams4 = new URLSearchParams(window.location.search);
          currentParams4.delete('lvl4');
          const newUrl = `${window.location.pathname}?${currentParams4.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level4Value = updatedState[level4Class];
          if (level4Value > 1) {
            updatedState[level4Class] = level4Value - 1;
          } else {
            delete updatedState[level4Class];
          }
        }
        if (level5Class in updatedState) {
          setLevel5Class("-5");
          const currentParams5 = new URLSearchParams(window.location.search);
          currentParams5.delete('lvl5');
          const newUrl = `${window.location.pathname}?${currentParams5.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level5Value = updatedState[level5Class];
          if (level5Value > 1) {
            updatedState[level5Class] = level5Value - 1;
          } else {
            delete updatedState[level5Class];
          }
        }
        if (level6Class in updatedState) {
          setLevel6Class("-6");
          const currentParams6 = new URLSearchParams(window.location.search);
          currentParams6.delete('lvl6');
          const newUrl = `${window.location.pathname}?${currentParams6.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level6Value = updatedState[level6Class];
          if (level6Value > 1) {
            updatedState[level6Class] = level6Value - 1;
          } else {
            delete updatedState[level6Class];
          }
        }
        if (level7Class in updatedState) {
          setLevel7Class("-7");
          const currentParams7 = new URLSearchParams(window.location.search);
          currentParams7.delete('lvl7');
          const newUrl = `${window.location.pathname}?${currentParams7.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level7Value = updatedState[level7Class];
          if (level7Value > 1) {
            updatedState[level7Class] = level7Value - 1;
          } else {
            delete updatedState[level7Class];
          }
        }
        if (level8Class in updatedState) {
          setLevel8Class("-8");
          const currentParams8 = new URLSearchParams(window.location.search);
          currentParams8.delete('lvl8');
          const newUrl = `${window.location.pathname}?${currentParams8.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel2Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl2', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level2Class
        if (level2Class in updatedState) {
          const level2Value = updatedState[level2Class];
          if (level2Value > 1) {
            updatedState[level2Class] = level2Value - 1;
          } else {
            delete updatedState[level2Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel3 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-3") {
        // If event.target.value is -3, subtract -1 from level3Class if it exists, or delete it if it's already 0
        if (level3Class in updatedState) {
          setLevel3Class(event.target.value)
          const currentParams = new URLSearchParams(window.location.search);
          currentParams.delete('lvl3');
          const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level3Value = updatedState[level3Class];
          if (level3Value > 1) {
            updatedState[level3Class] = level3Value - 1;
          } else {
            delete updatedState[level3Class];
          }
        }
        if (level4Class in updatedState) {
          setLevel4Class("-4");
          const currentParams4 = new URLSearchParams(window.location.search);
          currentParams4.delete('lvl4');
          const newUrl = `${window.location.pathname}?${currentParams4.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level4Value = updatedState[level4Class];
          if (level4Value > 1) {
            updatedState[level4Class] = level4Value - 1;
          } else {
            delete updatedState[level4Class];
          }
        }
        if (level5Class in updatedState) {
          setLevel5Class("-5");
          const currentParams5 = new URLSearchParams(window.location.search);
          currentParams5.delete('lvl5');
          const newUrl = `${window.location.pathname}?${currentParams5.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level5Value = updatedState[level5Class];
          if (level5Value > 1) {
            updatedState[level5Class] = level5Value - 1;
          } else {
            delete updatedState[level5Class];
          }
        }
        if (level6Class in updatedState) {
          setLevel6Class("-6");
          const currentParams6 = new URLSearchParams(window.location.search);
          currentParams6.delete('lvl6');
          const newUrl = `${window.location.pathname}?${currentParams6.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level6Value = updatedState[level6Class];
          if (level6Value > 1) {
            updatedState[level6Class] = level6Value - 1;
          } else {
            delete updatedState[level6Class];
          }
        }
        if (level7Class in updatedState) {
          setLevel7Class("-7");
          const currentParams7 = new URLSearchParams(window.location.search);
          currentParams7.delete('lvl7');
          const newUrl = `${window.location.pathname}?${currentParams7.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level7Value = updatedState[level7Class];
          if (level7Value > 1) {
            updatedState[level7Class] = level7Value - 1;
          } else {
            delete updatedState[level7Class];
          }
        }
        if (level8Class in updatedState) {
          setLevel8Class("-8");
          const currentParams8 = new URLSearchParams(window.location.search);
          currentParams8.delete('lvl8');
          const newUrl = `${window.location.pathname}?${currentParams8.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel3Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl3', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level3Class in updatedState) {
          const level3Value = updatedState[level3Class];
          if (level3Value > 1) {
            updatedState[level3Class] = level3Value - 1;
          } else {
            delete updatedState[level3Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel4 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-4") {
        // If event.target.value is -4, subtract -1 from level4Class if it exists, or delete it if it's already 0
        if (level4Class in updatedState) {
          setLevel4Class(event.target.value)
          const currentParams = new URLSearchParams(window.location.search);
          currentParams.delete('lvl4');
          const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level4Value = updatedState[level4Class];
          if (level4Value > 1) {
            updatedState[level4Class] = level4Value - 1;
          } else {
            delete updatedState[level4Class];
          }
        }
        if (level5Class in updatedState) {
          setLevel5Class("-5");
          const currentParams5 = new URLSearchParams(window.location.search);
          currentParams5.delete('lvl5');
          const newUrl = `${window.location.pathname}?${currentParams5.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level5Value = updatedState[level5Class];
          if (level5Value > 1) {
            updatedState[level5Class] = level5Value - 1;
          } else {
            delete updatedState[level5Class];
          }
        }
        if (level6Class in updatedState) {
          setLevel6Class("-6");
          const currentParams6 = new URLSearchParams(window.location.search);
          currentParams6.delete('lvl6');
          const newUrl = `${window.location.pathname}?${currentParams6.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level6Value = updatedState[level6Class];
          if (level6Value > 1) {
            updatedState[level6Class] = level6Value - 1;
          } else {
            delete updatedState[level6Class];
          }
        }
        if (level7Class in updatedState) {
          setLevel7Class("-7");
          const currentParams7 = new URLSearchParams(window.location.search);
          currentParams7.delete('lvl7');
          const newUrl = `${window.location.pathname}?${currentParams7.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level7Value = updatedState[level7Class];
          if (level7Value > 1) {
            updatedState[level7Class] = level7Value - 1;
          } else {
            delete updatedState[level7Class];
          }
        }
        if (level8Class in updatedState) {
          setLevel8Class("-8");
          const currentParams8 = new URLSearchParams(window.location.search);
          currentParams8.delete('lvl8');
          const newUrl = `${window.location.pathname}?${currentParams8.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel4Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl4', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level4Class in updatedState) {
          const level4Value = updatedState[level4Class];
          if (level4Value > 1) {
            updatedState[level4Class] = level4Value - 1;
          } else {
            delete updatedState[level4Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel5 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-5") {
        // If event.target.value is -5, subtract -1 from level5Class if it exists, or delete it if it's already 0
        if (level5Class in updatedState) {
          setLevel5Class(event.target.value)
          const currentParams = new URLSearchParams(window.location.search);
          currentParams.delete('lvl5');
          const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level5Value = updatedState[level5Class];
          if (level5Value > 1) {
            updatedState[level5Class] = level5Value - 1;
          } else {
            delete updatedState[level5Class];
          }
        }
        if (level6Class in updatedState) {
          setLevel6Class("-6");
          const currentParams6 = new URLSearchParams(window.location.search);
          currentParams6.delete('lvl6');
          const newUrl = `${window.location.pathname}?${currentParams6.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level6Value = updatedState[level6Class];
          if (level6Value > 1) {
            updatedState[level6Class] = level6Value - 1;
          } else {
            delete updatedState[level6Class];
          }
        }
        if (level7Class in updatedState) {
          setLevel7Class("-7");
          const currentParams7 = new URLSearchParams(window.location.search);
          currentParams7.delete('lvl7');
          const newUrl = `${window.location.pathname}?${currentParams7.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level7Value = updatedState[level7Class];
          if (level7Value > 1) {
            updatedState[level7Class] = level7Value - 1;
          } else {
            delete updatedState[level7Class];
          }
        }
        if (level8Class in updatedState) {
          setLevel8Class("-8");
          const currentParams8 = new URLSearchParams(window.location.search);
          currentParams8.delete('lvl8');
          const newUrl = `${window.location.pathname}?${currentParams8.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel5Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl5', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level5Class in updatedState) {
          const level5Value = updatedState[level5Class];
          if (level5Value > 1) {
            updatedState[level5Class] = level5Value - 1;
          } else {
            delete updatedState[level5Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel6 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-6") {
        // If event.target.value is -6, subtract -1 from level6Class if it exists, or delete it if it's already 0
        if (level6Class in updatedState) {
          setLevel6Class("-6");
          const currentParams6 = new URLSearchParams(window.location.search);
          currentParams6.delete('lvl6');
          const newUrl = `${window.location.pathname}?${currentParams6.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level6Value = updatedState[level6Class];
          if (level6Value > 1) {
            updatedState[level6Class] = level6Value - 1;
          } else {
            delete updatedState[level6Class];
          }
        }
        if (level7Class in updatedState) {
          setLevel7Class("-7");
          const currentParams7 = new URLSearchParams(window.location.search);
          currentParams7.delete('lvl7');
          const newUrl = `${window.location.pathname}?${currentParams7.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level7Value = updatedState[level7Class];
          if (level7Value > 1) {
            updatedState[level7Class] = level7Value - 1;
          } else {
            delete updatedState[level7Class];
          }
        }
        if (level8Class in updatedState) {
          setLevel8Class("-8");
          const currentParams8 = new URLSearchParams(window.location.search);
          currentParams8.delete('lvl8');
          const newUrl = `${window.location.pathname}?${currentParams8.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel6Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl6', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level6Class in updatedState) {
          const level6Value = updatedState[level6Class];
          if (level6Value > 1) {
            updatedState[level6Class] = level6Value - 1;
          } else {
            delete updatedState[level6Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel7 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-7") {
        // If event.target.value is -7, subtract -1 from level7Class if it exists, or delete it if it's already 0
        if (level7Class in updatedState) {
          setLevel7Class("-7");
          const currentParams7 = new URLSearchParams(window.location.search);
          currentParams7.delete('lvl7');
          const newUrl = `${window.location.pathname}?${currentParams7.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level7Value = updatedState[level7Class];
          if (level7Value > 1) {
            updatedState[level7Class] = level7Value - 1;
          } else {
            delete updatedState[level7Class];
          }
        }
        if (level8Class in updatedState) {
          setLevel8Class("-8");
          const currentParams8 = new URLSearchParams(window.location.search);
          currentParams8.delete('lvl8');
          const newUrl = `${window.location.pathname}?${currentParams8.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel7Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl7', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level7Class in updatedState) {
          const level7Value = updatedState[level7Class];
          if (level7Value > 1) {
            updatedState[level7Class] = level7Value - 1;
          } else {
            delete updatedState[level7Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel8 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-8") {
        // If event.target.value is -8, subtract -1 from level8Class if it exists, or delete it if it's already 0
        if (level8Class in updatedState) {
          setLevel8Class("-8");
          const currentParams8 = new URLSearchParams(window.location.search);
          currentParams8.delete('lvl8');
          const newUrl = `${window.location.pathname}?${currentParams8.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel8Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl8', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level8Class in updatedState) {
          const level8Value = updatedState[level8Class];
          if (level8Value > 1) {
            updatedState[level8Class] = level8Value - 1;
          } else {
            delete updatedState[level8Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel9 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-9") {
        // If event.target.value is -9, subtract -1 from level9Class if it exists, or delete it if it's already 0
        if (level9Class in updatedState) {
          setLevel9Class("-9");
          const currentParams9 = new URLSearchParams(window.location.search);
          currentParams9.delete('lvl9');
          const newUrl = `${window.location.pathname}?${currentParams9.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel9Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl9', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level9Class in updatedState) {
          const level9Value = updatedState[level9Class];
          if (level9Value > 1) {
            updatedState[level9Class] = level9Value - 1;
          } else {
            delete updatedState[level9Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel10 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-10") {
        // If event.target.value is -10, subtract -1 from level10Class if it exists, or delete it if it's already 0
        if (level10Class in updatedState) {
          setLevel10Class("-10");
          const currentParams10 = new URLSearchParams(window.location.search);
          currentParams10.delete('lvl10');
          const newUrl = `${window.location.pathname}?${currentParams10.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel10Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl10', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level10Class in updatedState) {
          const level10Value = updatedState[level10Class];
          if (level10Value > 1) {
            updatedState[level10Class] = level10Value - 1;
          } else {
            delete updatedState[level10Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel11 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-11") {
        // If event.target.value is -11, subtract -1 from level11Class if it exists, or delete it if it's already 0
        if (level11Class in updatedState) {
          setLevel11Class("-11");
          const currentParams11 = new URLSearchParams(window.location.search);
          currentParams11.delete('lvl11');
          const newUrl = `${window.location.pathname}?${currentParams11.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel11Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl11', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level11Class in updatedState) {
          const level11Value = updatedState[level11Class];
          if (level11Value > 1) {
            updatedState[level11Class] = level11Value - 1;
          } else {
            delete updatedState[level11Class];
          }
        }
      }

      return updatedState;
    });
  }

  const handleLevel12 = (event) => {

    setClassesAllLevels(prevState => {
      const updatedState = { ...prevState };

      if (event.target.value === "-12") {
        // If event.target.value is -12, subtract -1 from level12Class if it exists, or delete it if it's already 0
        if (level12Class in updatedState) {
          setLevel12Class("-12");
          const currentParams12 = new URLSearchParams(window.location.search);
          currentParams12.delete('lvl12');
          const newUrl = `${window.location.pathname}?${currentParams12.toString()}`;
          window.history.pushState({}, '', newUrl);

          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      } else {
        const currentValue = updatedState[event.target.value] || 0;
        setLevel12Class(event.target.value)
        const currentParams = new URLSearchParams(window.location.search);
        currentParams.set('lvl12', event.target.value);
        const newUrl = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.pushState({}, '', newUrl);

        if (currentValue > 0) {
          updatedState[event.target.value] = currentValue + 1;
        } else {
          updatedState[event.target.value] = 1;
        }

        // Check and handle decrement logic for level3Class
        if (level12Class in updatedState) {
          const level12Value = updatedState[level12Class];
          if (level12Value > 1) {
            updatedState[level12Class] = level12Value - 1;
          } else {
            delete updatedState[level12Class];
          }
        }
      }

      return updatedState;
    });
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const lvl1 = queryParams.has('c');
    const lvl2 = queryParams.has('lvl2');
    const lvl3 = queryParams.has('lvl3');
    const lvl4 = queryParams.has('lvl4');
    const lvl5 = queryParams.has('lvl5');
    const lvl6 = queryParams.has('lvl6');
    const lvl7 = queryParams.has('lvl7');
    const lvl8 = queryParams.has('lvl8');
    const lvl9 = queryParams.has('lvl9');
    const lvl10 = queryParams.has('lvl10');
    const lvl11 = queryParams.has('lvl11');
    const lvl12 = queryParams.has('lvl12');

    if (lvl1) {
      setLevel1Class(queryParams.get("c"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("c")]: (prevState[queryParams.get("c")] || 0) + 1
      }));
    }
    if (lvl2) {
      setLevel2Class(queryParams.get("lvl2"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl2")]: (prevState[queryParams.get("lvl2")] || 0) + 1
      }));
    }
    if (lvl3) {
      setLevel3Class(queryParams.get("lvl3"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl3")]: (prevState[queryParams.get("lvl3")] || 0) + 1
      }));
    }
    if (lvl4) {
      setLevel4Class(queryParams.get("lvl4"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl4")]: (prevState[queryParams.get("lvl4")] || 0) + 1
      }));
    }
    if (lvl5) {
      setLevel5Class(queryParams.get("lvl5"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl5")]: (prevState[queryParams.get("lvl5")] || 0) + 1
      }));
    }
    if (lvl6) {
      setLevel6Class(queryParams.get("lvl6"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl6")]: (prevState[queryParams.get("lvl6")] || 0) + 1
      }));
    }
    if (lvl7) {
      setLevel7Class(queryParams.get("lvl7"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl7")]: (prevState[queryParams.get("lvl7")] || 0) + 1
      }));
    }
    if (lvl8) {
      setLevel8Class(queryParams.get("lvl8"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl8")]: (prevState[queryParams.get("lvl8")] || 0) + 1
      }));
    }
    if (lvl9) {
      setLevel9Class(queryParams.get("lvl9"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl9")]: (prevState[queryParams.get("lvl9")] || 0) + 1
      }));
    }
    if (lvl10) {
      setLevel10Class(queryParams.get("lvl10"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl10")]: (prevState[queryParams.get("lvl10")] || 0) + 1
      }));
    }
    if (lvl11) {
      setLevel11Class(queryParams.get("lvl11"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl11")]: (prevState[queryParams.get("lvl11")] || 0) + 1
      }));
    }
    if (lvl12) {
      setLevel12Class(queryParams.get("lvl12"));
      setClassesAllLevels(prevState => ({
        ...prevState,
        [queryParams.get("lvl12")]: (prevState[queryParams.get("lvl12")] || 0) + 1
      }));
    }

  }, [location.search]);

  const sumOfValues = Object.values(classesAllLevels).reduce((acc, val) => acc + val, 0);
  if (sumOfValues > 12) {
    setClassesAllLevels(prevState => {
      const updatedState = {};
      for (const key in prevState) {
        updatedState[key] = Math.ceil(prevState[key] / 2);
      }
      return updatedState;
    });
  }

  const frameStyle6 = {
    border: '2px solid white',
    padding: '10px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    marginTop: '20px',
  };


  const columnStyle = {
    padding: '15px',
    borderRight: '1px solid white', // Add a right border to separate the columns
  };

  const spacerStyle = {
    flex: 1, // Takes up remaining space to push the columns to the left
  };


  const totalSum = Object.values(classesAllLevels).reduce((acc, value) => acc + value, 0);
  const proficiencyPlus = { 1: "+2", 2: "+2", 3: "+2", 4: "+2", 5: "+3", 6: "+3", 7: "+3", 8: "+3", 9: "+4", 10: "+4", 11: "+4", 12: "+4" };
  const spellsKnownLevel = { 1: 1, 2: 1, 3: 2, 4: 2, 5: 3, 6: 3, 7: 4, 8: 4, 9: 5, 10: 5, 11: 6, 12: 6};
  const spellSlots = { 1: "1st Level: 2", 2: "1st Level: 3", 3: "1st Level: 4, 2nd Level: 2", 4: "1st Level: 4, 2nd Level: 3", 5: "1st Level: 4, 2nd Level: 3, 3rd Level: 2", 6: "1st Level: 4, 2nd Level: 3, 3rd Level: 3", 7: "1st Level: 4, 2nd Level: 3, 3rd Level: 3, 4th Level: 1", 8: "1st Level: 4, 2nd Level: 3, 3rd Level: 3, 4th Level: 2", 9: "1st Level: 4, 2nd Level: 3, 3rd Level: 3, 4th Level: 3, 5th Level: 1", 10: "1st Level: 4, 2nd Level: 3, 3rd Level: 3, 4th Level: 3, 5th Level: 2", 11: "1st Level: 4, 2nd Level: 3, 3rd Level: 3, 4th Level: 3, 5th Level: 2, 6th Level: 1", 12: "1st Level: 4, 2nd Level: 3, 3rd Level: 3, 4th Level: 3, 5th Level: 2, 6th Level: 1" };
  const constitutionProficiencyScore = abilityScoresData.find((ability) => ability.name === "Constitution")?.proficiencyScore[0] || 0;

  const totalHP =
    (classData[level1Class]?.HP + constitutionProficiencyScore || 0) +
    (classData[level2Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level3Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level4Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level5Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level6Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level7Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level8Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level9Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level10Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level11Class]?.Levels?.HP + constitutionProficiencyScore || 0) +
    (classData[level12Class]?.Levels?.HP + constitutionProficiencyScore || 0);


  const MulticlassFrame = isClassSelected === true && (
    <div style={{ ...frameStyle6, backgroundColor: 'rgba(0, 0, 0, 0.75)', marginBottom: "80px", marginTop: "120px" }}>
      {/* Title */}
      <div style={titleStyle}>
        <span style={{ color: '#FFBF00', fontSize: '26px' }}>Multiclassing/Future Levels (updates in progress)</span>
      </div>

      <div style={{ display: 'flex' }}>
        <div style={{ ...frameStyle6, backgroundColor: 'rgba(0, 0, 0, 0.75)', marginBottom: '50px', border: '1px solid white', flex: 1 }}>
          <table style={{ color: 'white', fontSize: '19px', borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th>Class</th>
                <th>Levels</th>
              </tr>
            </thead>
            <tbody>
              {/* Create table rows for each key-value pair */}
              {Object.entries(classesAllLevels).map(([key, value]) => (
                <tr key={key}>
                  <td style={{ border: '1px solid white', padding: '8px', textAlign: 'center' }}>{key}</td>
                  <td style={{ border: '1px solid white', padding: '8px', textAlign: 'center' }}>{value}</td>
                </tr>
              ))}
              {/* Add a final row for the total */}
              <tr>
                <td style={{ border: '1px solid white', padding: '8px', textAlign: 'center', color: "#FFBF00", fontSize: "20px" }}>Total</td>
                <td style={{ border: '1px solid white', padding: '8px', textAlign: 'center' }}>{totalSum}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style={{ ...frameStyle6, backgroundColor: 'rgba(0, 0, 0, 0.75)', marginBottom: '50px', border: '1px solid white', flex: 1, marginLeft: "50px", whiteSpace: 'normal', maxWidth: '800px', overflow: "hidden", wordWrap: "break-word" }}>
          <div style={{ marginLeft: "20px", marginRight: "20px", font: generalFont, fontSize: "16px", color: "#d2b48c" }}>
            Total Hit Points:<span style={{ color: "red" }}>&nbsp;{totalHP}</span>
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px", font: generalFont, fontSize: "16px", color: "#d2b48c", marginTop: "10px" }}>Final Proficiency Bonus:<span style={{ color: "red" }}>&nbsp;{proficiencyPlus[totalSum]}</span></div>
          <div style={{ marginLeft: "20px", marginRight: "20px", font: generalFont, fontSize: "16px", color: "#d2b48c", maxWidth: '800px', marginTop: "10px" }}>
            Total Number of Cantrips:
            <span style={{ color: "white" }}>
              &nbsp;
              <span style={{ color: "red" }}>
                {Object.entries(classesAllLevels).reduce((totalCantrips, [key, value]) => {
                  const cantripsKnown = classData[key]?.Levels?.[`lvl${value}`]?.["Cantrips Known"] || 0;
                  return totalCantrips + cantripsKnown;
                }, 0)}
              </span>
              {Object.entries(classesAllLevels)
                .map(([key, value]) => {
                  const cantripsKnown = classData[key]?.Levels?.[`lvl${value}`]?.["Cantrips Known"] || 0;
                  const className = classData[key]?.Name || "Unknown Class";
                  return cantripsKnown > 0 ? `${cantripsKnown} ${className} Cantrips` : null;
                })
                .filter(Boolean)
                .join(", ").length > 0
                ? ` (${Object.entries(classesAllLevels)
                  .map(([key, value]) => {
                    const cantripsKnown = classData[key]?.Levels?.[`lvl${value}`]?.["Cantrips Known"] || 0;
                    const className = classData[key]?.Name || "Unknown Class";
                    return cantripsKnown > 0 ? `${cantripsKnown} ${className} Cantrips` : null;
                  })
                  .filter(Boolean)
                  .join(", ")})`
                : ""
              }
            </span>
          </div>
          <div style={{ marginLeft: "20px", marginRight: "20px", font: generalFont, fontSize: "16px", color: "#d2b48c", maxWidth: '800px', marginTop: "10px" }}>
            Number of Spells Known*:
            <span style={{ color: "white" }}>
              &nbsp;
              <span style={{ color: "red" }}>
                {Object.entries(classesAllLevels).reduce((totalSpells, [key, value]) => {
                  const spellsKnown = classData[key]?.Levels?.[`lvl${value}`]?.["Spells Known"] || 0;
                  return totalSpells + spellsKnown;
                }, 0)}
              </span>
              {Object.entries(classesAllLevels)
                .map(([key, value]) => {
                  const spellsKnown = classData[key]?.Levels?.[`lvl${value}`]?.["Spells Known"] || 0;
                  const className = classData[key]?.Name || "Unknown Class";
                  const spellLevel = spellsKnownLevel[classesAllLevels[key]];
                  return spellsKnown > 0 ? `${spellsKnown} ${className} Spells (up to lvl ${spellLevel})` : null;
                })
                .filter(Boolean)
                .join(", ").length > 0
                ? ` (${Object.entries(classesAllLevels)
                  .map(([key, value]) => {
                    const spellsKnown = classData[key]?.Levels?.[`lvl${value}`]?.["Spells Known"] || 0;
                    const className = classData[key]?.Name || "Unknown Class";
                    const spellLevel = spellsKnownLevel[classesAllLevels[key]];
                    return spellsKnown > 0 ? `${spellsKnown} ${className} Spells (up to lvl ${spellLevel})` : null;
                  })
                  .filter(Boolean)
                  .join(", ")})`
                : ""
              }
            </span>
          </div>

          <div style={{ marginLeft: "20px", marginRight: "20px", font: generalFont, fontSize: "16px", color: "#d2b48c", marginTop: "10px" }}>
            Spell Slots:&nbsp;
            <span style={{ color: "red" }}>{spellSlots[
              Object.entries(classesAllLevels).reduce((totalSlots, [key, value]) => {
                const isSingleKey = Object.keys(classesAllLevels).length === 1;
                const isRanger = key === "Ranger";
                const isPaladin = key === "Paladin";

                // Check if both "Paladin" and "Ranger" are in the dictionary
                const hasBothClasses = "Paladin" in classesAllLevels && "Ranger" in classesAllLevels;

                if (["Bard", "Cleric", "Druid", "Sorcerer", "Wizard"].includes(key)) {
                  return totalSlots + value;
                } else if (isRanger && isSingleKey) {
                  return totalSlots + (value > 1 ? Math.ceil(value / 2) : Math.floor(value / 2));
                } else if (isPaladin && isSingleKey) {
                  return totalSlots + (value > 1 ? Math.ceil(value / 2) : Math.floor(value / 2));
                } else if (isRanger && hasBothClasses) {
                  return totalSlots + Math.floor((classesAllLevels["Ranger"] + classesAllLevels["Paladin"]) / 2);
                } else if (isRanger && !hasBothClasses) {
                  return totalSlots + Math.floor(value / 2);
                } else if (isPaladin && !hasBothClasses) {
                  return totalSlots + Math.floor(value / 2);
                } else {
                  return totalSlots;
                }
              }, 0)
            ]}</span>
          </div>
          <div style={{ font: generalFont, color: "white", fontSize: "14px", marginTop: "50px" }}>*Eldritch Knight/Arcane Trickster not included</div>

        </div>
      </div>


      {/* LVL 1 */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        {/* Column 1 */}
        <div style={columnStyle}>
          <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 1</span>
        </div>

        {/* Column 2 */}
        <div style={columnStyle}>
          {selectedClass === -1 ? (
            <p style={{ margin: 0 }}>Select Class</p>
          ) : (
            <p style={{ font: generalFont, fontSize: "21px", lineHeight: "16px", color: '#FFBF00', margin: "15px" }}>
              {selectedClass}
            </p>
          )}
        </div>


        {/* Column 3 */}
        <div style={{ padding: "15px" }}>
          <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
            {Object.keys(classData[selectedClass]?.Levels?.lvl1).map(key => (
              <div key={key}>
                <span style={{ color: '#D2B48C' }}>{key}: </span>
                {classData[selectedClass]?.Levels?.lvl1[key]}
              </div>
            ))}
          </div>
        </div>
        <div style={spacerStyle}></div>
      </div>

      {/* LVL 2 */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
        {/* Column 1 */}
        <div style={columnStyle}>
          <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 2</span>
        </div>

        {/* Column 2 */}
        <div style={columnStyle}>
          <select
            style={dropdownStyle}
            value={level2Class}
            onChange={handleLevel2}
          >
            <option value="-2">Select Class</option>
            {classList.map((className, index) => (
              <option key={index} value={className}>
                {className}
              </option>
            ))}
          </select>
        </div>

        {/* Column 3 */}
        <div style={{ padding: "15px" }}>
          {/* Use div instead of span */}
          <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
            {level1Class !== level2Class && classesAllLevels[level2Class] >= 1 && (
              <div>
                <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                {classData[level2Class]?.Levels?.['Multiclassing Proficiencies']}
              </div>
            )}
            {Object.keys(classData[level2Class]?.Levels?.[getClassKey(level2Class, 2)] ?? {}).map(key => (
              <div key={key}>
                <span style={{ color: '#D2B48C' }}>{key}: </span>
                {classData[level2Class]?.Levels?.[getClassKey(level2Class, 2)]?.[key]}
              </div>
            ))}
            {/* Check if level2Class exists in classesAllLevels and the value is 1 */}
          </div>
        </div>
        {/* Spacer to push the columns to the left */}
        <div style={spacerStyle}></div>
      </div>

      {/* LVL 3 */}
      {level2Class !== "-2" && classList.includes(level2Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 3</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level3Class}
              onChange={handleLevel3}
            >
              <option value="-3">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level3Class && level2Class !== level3Class && classesAllLevels[level3Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level3Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level3Class]?.Levels?.[getClassKey(level3Class, 3)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level3Class]?.Levels?.[getClassKey(level3Class, 3)]?.[key]}
                </div>
              ))}
              {/* Check if level3Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 4 */}
      {level3Class !== "-3" && classList.includes(level3Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 4</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level4Class}
              onChange={handleLevel4}
            >
              <option value="-4">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>


          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level4Class && level2Class !== level4Class && level3Class !== level4Class && classesAllLevels[level4Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level4Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level4Class]?.Levels?.[getClassKey(level4Class, 4)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level4Class]?.Levels?.[getClassKey(level4Class, 4)]?.[key]}
                </div>
              ))}
              {/* Check if level4Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 5 */}
      {level4Class !== "-4" && classList.includes(level4Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 5</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level5Class}
              onChange={handleLevel5}
            >
              <option value="-5">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level5Class && level2Class !== level5Class && level3Class !== level5Class && level4Class !== level5Class && classesAllLevels[level5Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level5Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level5Class]?.Levels?.[getClassKey(level5Class, 5)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level5Class]?.Levels?.[getClassKey(level5Class, 5)]?.[key]}
                </div>
              ))}
              {/* Check if level5Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 6 */}
      {level5Class !== "-5" && classList.includes(level5Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 6</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level6Class}
              onChange={handleLevel6}
            >
              <option value="-6">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level6Class && level2Class !== level6Class && level3Class !== level6Class && level4Class !== level6Class && level5Class !== level6Class && classesAllLevels[level6Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level6Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level6Class]?.Levels?.[getClassKey(level6Class, 6)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level6Class]?.Levels?.[getClassKey(level6Class, 6)]?.[key]}
                </div>
              ))}
              {/* Check if level6Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 7 */}
      {level6Class !== "-6" && classList.includes(level6Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 7</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level7Class}
              onChange={handleLevel7}
            >
              <option value="-7">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level7Class && level2Class !== level7Class && level3Class !== level7Class && level4Class !== level7Class && level5Class !== level7Class && level6Class !== level7Class && classesAllLevels[level7Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level7Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level7Class]?.Levels?.[getClassKey(level7Class, 7)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level7Class]?.Levels?.[getClassKey(level7Class, 7)]?.[key]}
                </div>
              ))}
              {/* Check if level7Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 8 */}
      {level7Class !== "-7" && classList.includes(level7Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 8</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level8Class}
              onChange={handleLevel8}
            >
              <option value="-8">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level8Class && level2Class !== level8Class && level3Class !== level8Class && level4Class !== level8Class && level5Class !== level8Class && level6Class !== level8Class && level7Class !== level8Class && classesAllLevels[level8Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level8Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level8Class]?.Levels?.[getClassKey(level8Class, 8)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level8Class]?.Levels?.[getClassKey(level8Class, 8)]?.[key]}
                </div>
              ))}
              {/* Check if level8Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 9 */}
      {level8Class !== "-8" && classList.includes(level8Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 9</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level9Class}
              onChange={handleLevel9}
            >
              <option value="-9">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level9Class && level2Class !== level9Class && level3Class !== level9Class && level4Class !== level9Class && level5Class !== level9Class && level6Class !== level9Class && level7Class !== level9Class && level8Class !== level9Class && classesAllLevels[level9Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level9Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level9Class]?.Levels?.[getClassKey(level9Class, 9)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level9Class]?.Levels?.[getClassKey(level9Class, 9)]?.[key]}
                </div>
              ))}
              {/* Check if level9Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 10 */}
      {level9Class !== "-9" && classList.includes(level9Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 10</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level10Class}
              onChange={handleLevel10}
            >
              <option value="-10">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level10Class && level2Class !== level10Class && level3Class !== level10Class && level4Class !== level10Class && level5Class !== level10Class && level6Class !== level10Class && level7Class !== level10Class && level8Class !== level10Class && level9Class !== level10Class && classesAllLevels[level10Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level10Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level10Class]?.Levels?.[getClassKey(level10Class, 10)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level10Class]?.Levels?.[getClassKey(level10Class, 10)]?.[key]}
                </div>
              ))}
              {/* Check if level10Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 11 */}
      {level10Class !== "-10" && classList.includes(level10Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 11</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level11Class}
              onChange={handleLevel11}
            >
              <option value="-11">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level11Class && level2Class !== level11Class && level3Class !== level11Class && level4Class !== level11Class && level5Class !== level11Class && level6Class !== level11Class && level7Class !== level11Class && level8Class !== level11Class && level9Class !== level11Class && level10Class !== level11Class && classesAllLevels[level11Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level11Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level11Class]?.Levels?.[getClassKey(level11Class, 11)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level11Class]?.Levels?.[getClassKey(level11Class, 11)]?.[key]}
                </div>
              ))}
              {/* Check if level11Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

      {/* LVL 12 */}
      {level11Class !== "-11" && classList.includes(level11Class) && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: "20px" }}>
          {/* Column 1 */}
          <div style={columnStyle}>
            <span style={{ color: '#FFFFFF', fontSize: '18px', textAlign: 'left' }}>Level 12</span>
          </div>

          {/* Column 2 */}
          <div style={columnStyle}>
            <select
              style={dropdownStyle}
              value={level12Class}
              onChange={handleLevel12}
            >
              <option value="-12">Select Class</option>
              {classList.map((className, index) => (
                <option key={index} value={className}>
                  {className}
                </option>
              ))}
            </select>
          </div>

          {/* Column 3 */}
          <div style={{ padding: "15px" }}>
            {/* Use div instead of span */}
            <div style={{ color: '#FFFFFF', fontSize: '18px' }}>
              {(level1Class !== level12Class && level2Class !== level12Class && level3Class !== level12Class && level4Class !== level12Class && level5Class !== level12Class && level6Class !== level12Class && level7Class !== level12Class && level8Class !== level12Class && level9Class !== level12Class && level10Class !== level12Class && level11Class !== level12Class && classesAllLevels[level12Class] >= 1) && (
                <div>
                  <span style={{ color: '#D2B48C' }}>Multiclassing Proficiencies: </span>
                  {classData[level12Class]?.Levels?.['Multiclassing Proficiencies']}
                </div>
              )}
              {Object.keys(classData[level12Class]?.Levels?.[getClassKey(level12Class, 12)] ?? {}).map(key => (
                <div key={key}>
                  <span style={{ color: '#D2B48C' }}>{key}: </span>
                  {classData[level12Class]?.Levels?.[getClassKey(level12Class, 12)]?.[key]}
                </div>
              ))}
              {/* Check if level12Class exists in classesAllLevels and the value is 1 */}
            </div>
          </div>
          {/* Spacer to push the columns to the left */}
          <div style={spacerStyle}></div>
        </div>
      )}

    </div>
  );

  /* SUMMARY END */

  return (
    <div style={myStyle}>
      <div style={containerStyle}>
        {/* Frame start */}
        <div style={frameStyle}>
          {/* Left side */}
          <div>
            <RaceButtonSection
              openRaceModal={openRaceModal}
              raceButtonStyle={raceButtonStyle}
              selectedImage={selectedImage}
              selectedSecondaryButton={selectedSecondaryButton}
              selectedButton={selectedButton}
            />
            {/* Input for the user's name */}
          </div>
          {/* Left side end */}
          {/* Right side */}
          {rightSideContent}
          {/* Right side end */}
        </div>
        {/* Frame end */}
        {NameTextAndFrame}
        {BackgroundFrame}
        {ClassesFrame}
      </div>
      <div style={containerStyle2}>
        {SkillsFrame}
        {AttributesFrame}
      </div>
      <div style={containerStyle3}>
        {SummaryFrame}
        {MulticlassFrame}
      </div>
    </div>
  );
}

export default Home;
