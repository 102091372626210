import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/home_comp';
import FooterComponent from './components/FooterComponent';
import HeaderComponent from './components/HeaderComponent';

function App() {
  return (
    <Router>
      <div>
        <HeaderComponent />
        <Routes>
          {/*<Route path="/*" element={<Navigate to="/" />} />*/}
          <Route exact path="/*" element={<Home />} />
        </Routes>
        <FooterComponent />
      </div>
    </Router>
  );
}

export default App;
