import React, { useState, useRef, useEffect } from 'react';
import ClipboardJS from 'clipboard';

const ResetConfirmationPopup = ({ onConfirm, onCancel }) => {
  return (
    <div style={popupStyle}>
      <p>Are you sure you want to reset?</p>
      <button style={popupButtonStyle} onClick={onConfirm}>Yes</button>
      <button style={popupButtonStyle} onClick={onCancel}>No</button>
    </div>
  );
};

const HeaderComponent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const copyButtonRef = useRef(null);

  useEffect(() => {
    if (copyButtonRef.current) {
      const clipboard = new ClipboardJS(copyButtonRef.current, {
        text: () => window.location.href,
      });

      clipboard.on('success', () => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000); // Show the message for 2 seconds
      });

      clipboard.on('error', (e) => {
        console.error('Failed to copy URL to clipboard:', e);
      });

      return () => {
        clipboard.destroy();
      };
    }
  }, []);

  const handleReset = () => {
    setShowPopup(true);
  };

  const handleResetConfirmation = (confirmed) => {
    if (confirmed) {
      window.location.href = '/';
    }
    setShowPopup(false);
  };

  return (
    <header className='header' style={headerStyle}>
      {/* Reset button */}
      <button className='reset-button' style={resetButtonStyle} onClick={handleReset}>
        Reset
      </button>

      {/* Copy button */}
      <button
        ref={copyButtonRef}
        className='copy-button' // Add a class name to target the copy button
        style={headerTextStyle}
      >
        Share Character
      </button>

      <a href="https://bg3.wiki" target="_blank">
        <button
          className='wiki-button' // Add a class name to target the copy button
          style={headerTextStyle2}
        >
          Community Wiki
        </button>
      </a>

      {/* Show copy message */}
      {showCopyMessage && (
        <div style={copyMessageStyle}>
          Character link copied to clipboard
        </div>
      )}

      {showPopup && (
        <ResetConfirmationPopup
          onConfirm={() => handleResetConfirmation(true)}
          onCancel={() => handleResetConfirmation(false)}
        />
      )}
    </header>
  );
};

// ... Other styles (headerStyle, resetButtonStyle, headerTextStyle, popupStyle, popupButtonStyle)

const copyMessageStyle = {
  position: 'absolute',
  top: '150%',
  right: '120px',
  transform: 'translateY(-50%)',
  backgroundColor: '#4CAF50', // Green background color
  color: '#fff', // White text color
  border: 'none',
  borderRadius: '5px',
  padding: '10px 20px',
  fontSize: '16px',
  fontWeight: 'bold',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
};

const headerStyle = {
  position: 'fixed', // Set the position to fixed
  top: 0, // Stick the header to the top of the viewport
  left: 0, // Align the header to the left of the viewport
  right: 0, // Align the header to the right of the viewport
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '10px',
  zIndex: 1, // Set the z-index to make sure it appears above other content
};

const resetButtonStyle = {
  backgroundColor: '#8B0000', // Set the background color to transparent
  color: '#fff', // White text color
  border: 'none',
  borderRadius: '5px',
  padding: '10px 20px',
  fontSize: '18px',
  fontWeight: 'bold',
  marginRight: '75px', // Move the button to the right
  marginBottom: '0px', // Move the button down a bit
  cursor: 'pointer',
  // Add any other medieval/fancy styles you desire
};

const headerTextStyle = {
  cursor: 'pointer',
  color: '#896d4a', /* Set the text color to a goldy brown */
  fontSize: '20px', /* Set the font size */
  fontFamily: 'Arial, sans-serif', /* Set the font family */
  position: 'absolute', /* Set the text position to absolute */
  right: '190px', /* Align the text to the right */
  backgroundColor: '#ea8909',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  padding: '10px 20px',
  fontSize: '18px',
  fontWeight: 'bold',
};

const headerTextStyle2 = {
  cursor: 'pointer',
  color: '#fff',
  fontSize: '18px',
  fontFamily: 'Arial, sans-serif',
  position: 'absolute',
  right: '390px',
  backgroundColor: '#53320c',
  border: 'none',
  borderRadius: '5px',
  padding: '8px 12px',
  fontWeight: 'bold',
  top: '10px',
  height: '40px', // Set a fixed height for the button
  whiteSpace: 'normal', // Allow text to wrap
  lineHeight: '20px' // Adjust line height for two rows of text
};

const popupStyle = {
  position: 'fixed',
  top: '100%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  zIndex: 2, // Set the z-index to make sure it appears above other content
};

const popupButtonStyle = {
  backgroundColor: '#8B0000',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  padding: '10px 20px',
  fontSize: '18px',
  fontWeight: 'bold',
  margin: '5px',
  cursor: 'pointer',
};

export default HeaderComponent;
